// SearchContext.js
import React, { createContext, useContext, useState } from 'react';

export const SearchContext = createContext();

 export const useSearch = () => {
  return useContext(SearchContext);
};

 export const SearchProvider = ({ children }) => {
  const [search, setSearch] = useState({keyword : ''});
  
  return (
    <SearchContext.Provider value={{ search, setSearch}}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = () => useContext(SearchContext);
