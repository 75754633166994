import React from "react";
import { Result, Button } from "antd";
import { Link, useNavigate,useLocation } from "react-router-dom";
import { ReactComponent as KeyIcon } from "../images/email.svg";
import { ArrowLeftOutlined } from "@ant-design/icons";

const ForgotPasswordSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const values = location.state?.data;
  return (
    <div className="authpage-wrapper">
      <Result
        style={{
          width: 380,
          paddingInline: 0,
          fontSize: 14,
        }}
        icon={<KeyIcon width={66} height={66} />}
        status="success"
        title="Vérifiez votre messagerie"
        subTitle={`Nous avons envoyé un lien de réinitialisation du mot de passe à${values?.email}`
       // <p style={{ color: "#475467", fontSize: 16 }}>
          //   Nous avons envoyé un lien de réinitialisation du mot de passe à{" "}
          //   {values.email}
          // </p>
        }
        extra={[
          <p style={{ fontSize: 14 }}>
            Vous n'avez pas reçu l'e-mail ?{" "}
            <Link to="/reset-password" key="resend-link">
              <strong>Cliquez pour renvoyer</strong>
            </Link>
          </p>,
          <br />,
          <Button
            block
            icon={<ArrowLeftOutlined />}
            htmlType="button"
            type="link"
            onClick={() => navigate("/login")}
            className="text-mute"
          >
            Retour
          </Button>,
        ]}
      />
    </div>
  );
};

export default ForgotPasswordSuccess;
