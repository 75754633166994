import React, { useContext, useEffect, useState } from "react";
import { Avatar, Button, Col, Divider, Form, Input, Row, Select, Typography, Upload, Alert, Space, } from "antd";
import { MailOutlined } from "@ant-design/icons";
import cl from "country-codes-list";
import axios from "axios";
import { useAuthContext } from "../../../context/AuthContext";
import { ReactComponent as MapPinIcon } from "../../../images/map-pin.svg";
import { ReactComponent as CloudUploadIcon } from "../../../images/upload-cloud.svg";
import { useCompanyContext } from "../../../context/CompanyContext";
import { useNotificationContext } from "../../../context/NotificationContext";
import { debounceFunction } from "../../../functions";
import AutoComplete from "../../Components/AutoComplete";

const InformationsLegales = (props) => {
  const [form] = Form.useForm();
  const { companyData, setCompanyData, google } = useCompanyContext();
  const notification = useNotificationContext();
  const { user, accessToken, updateUser } = useAuthContext();
  const list = cl.all();

  const [loading, setLoading] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [siret, setSiret] = useState({});
  const [logo, setLogo] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);
  const [error, setError] = useState(null);


  useEffect(() => {
    form.setFieldsValue({
      company: {},
      person1: { email: user?.email, firstName: user?.firstName, lastName: user?.firstName, countryCode: "33", phone: user?.phone },
      person2: { countryCode: "33" },
    });
  }, [user])

  useEffect(() => {
    const fields = form.getFieldsValue(true)
    if (Object.hasOwnProperty.call(siret, "company")) {
      const { name, ape, lastName, firstname, address, postalCode, city } = siret.company
      form.setFieldsValue({
        company: {
          companyName: name ?? fields.company.companyName,
          codeAPE: ape,
          address: address,
          city,
          postalCode
        },
        // person1: { email: user?.email, countryCode: "33" },
        person2: { firstName: firstname, lastName: lastName },
      })
    }

  }, [siret])

  const verifySiret = values => {
    const isCompany = Object.hasOwnProperty.call(values, "company")
    if (isCompany) {
      const isSiretNumber = Object.hasOwnProperty.call(values.company, "siretNumber")
      if (isSiretNumber && values.company.siretNumber.length > 3) {
        debounceFunction(values.company.siretNumber, user, setSiret, setVerifying)
      }
    }
  };

  const handleSubmit = async values => {
    try {
      setLoading(true);
      const payload = new FormData();
      payload.append("logo", logo);

      for (const key in values) {
        // console.log("key", key);

        for (const subKey in values[key]) {
          // console.log("subKey", subKey);
          const value = values[key][subKey];
          if (subKey !== "logo" && value) {
            payload.append(`${key}[${subKey}]`, value);
          }
        }
      }
      payload.append(`userId`, user.id);

      const config = {
        method: "POST",
        url: "/profile/company",
        data: payload,
        headers: {
          "x-access-token": user.accessToken,
        },
      };
      const response = await axios(config);

      if (response.data.success) {
        const comp = response.data.company;
        props.setMandatPath(response.data.mandatPath)
        // setFormData((prev) => ({ ...prev, company: comp }));
        setCompanyData({ ...comp });
        props.setSignatureData({
          signature: comp.signature,
        });
        props.setCurrentStep(1);
      } else {
        form.scrollToField("siretNumber", "smooth")
        notification.setError({ message: "Unsuccessful", description: response.data.message })
        form.setFields([
          {
            name: 'siretNumber',
            errors: [response.data.message],
          },
        ]);
      }

    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const input = document.getElementById("autocomplete");
    if (input, google) {
      try {
        const options = {
          fields: ["address_components", "formatted_address", "geometry", "name"]
        };
        const autocomplete = new google.maps.places.Autocomplete(input, options);
        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          const { formatted_address, address_components, geometry } = place
          const postals = address_components.filter(c => c.types[0] === "postal_code")
          const locality = address_components.filter(c => c.types[0] === "locality")
          form.setFieldsValue({
            company: { address: formatted_address, city: locality[0].long_name, postalCode: postals[0].long_name },
          });
        })
      } catch (e) {
        console.log(e.message);
      }
    }
  }, [google]);

  return (
    <div>
      <Form
        id="form"
        name="step1Form"
        form={form}
        labelAlign="left"
        colon={false}
        size="large"
        requiredMark={false}
        labelCol={{ md: 6, xs: 12 }}
        onFinish={handleSubmit}
        encType="multipart/form-data"
        scrollToFirstError={"smooth"}
        onValuesChange={verifySiret}
      >
        {error && (
          <Form.Item>
            <Alert type="error" message={error} showIcon />
          </Form.Item>
        )}
        <Row>
          <Col>
            <Typography.Title level={4} className="heading">
              Informations de l’entreprise
            </Typography.Title>
            <Typography.Paragraph>
              Renseignez les informations de votre entreprise.
            </Typography.Paragraph>
          </Col>
          <Col style={{ marginLeft: "auto" }}>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Continuer
            </Button>
          </Col>
        </Row>
        <Divider />
        <Form.Item
          name={["company", "logo"]}
          label={
            <div>
              <div>Logo </div>
              <small className="text-mute">
                Déposez le logo de votre entreprise
              </small>
            </div>
          }
        >
          <Upload
            maxCount={1}
            className="custom-upload"
            // className="image-upload-grid"
            name="logo"
            listType="picture-card"
            onChange={({ file: originFileObj, fileList: newList }) => {
              setLogoPreview(newList[0])
              setLogo(originFileObj)
            }}
            onRemove={(file) => {
              setLogo(null)
            }}
            beforeUpload={(originFileObj) => {
              setLogo(originFileObj)
              return false;
            }}
            fileList={logoPreview ? [logoPreview] : []}
          >
            <div>
              <Avatar
                style={{
                  backgroundColor: "#F2F4F7",
                  border: "6px solid #F9FAFB",
                  alignItems: "center",
                  display: "flex",
                  margin: "0 auto 10px",
                }}
                size={50}
              >
                <CloudUploadIcon
                  width={17}
                  height={15}
                  style={{ display: "block" }}
                />
              </Avatar>
              <span style={{ lineHeight: 1.5 }}>
                <u className="text-primary">Sélectionnez</u> ou déposer un
                fichier
                <br />
                SVG, PNG, JPG or GIF (max. 800x400px)
              </span>
            </div>
          </Upload>
        </Form.Item>

        <Form.Item label="Type de structure*">
          <Row gutter={24} style={{ alignItems: "center" }}>
            <Col style={{ flex: 1 }}>
              <Form.Item
                name={["company", "companyType"]}
                rules={[
                  {
                    required: true,
                    message: "Veuillez entrer le type de structure",
                  },
                ]}
                noStyle
              >
                <Select
                  autoClearSearchValue
                  placeholder="Type de structure"
                  options={[
                    { value: "Entreprise", label: "Entreprise" },
                    { value: "Association", label: "Association" },
                    { value: " Administration", label: " Administration" },
                  ]}
                ></Select>
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          label="Nom de l’entreprise*"
          name={["company", "companyName"]}
          rules={[
            {
              required: true,
              message: "Veuillez entrer le nom de la structure",
            },
          ]}
        >
          <Input
            placeholder="Nom de l’entreprise"
            onKeyDown={handleKeyDown}
          />
        </Form.Item>
        <Form.Item
          label="Numéro de Siret*"
          name={["company", "siretNumber"]}
          hasFeedback
          validateStatus={verifying ? "validating" : (siret.verified ? "success" : siret.verified === undefined ? "" : "error")}
          help={verifying ? "Verifying..." : (siret.verified ? "" : siret.message)}
          rules={[
            {
              required: true,
              message: "Veuillez entrer le numéro de Siret",
            },
          ]}
        >
          <Input
            placeholder="898 106 489 00013"
            maxLength={14}
            onKeyDown={handleKeyDown}
          />
        </Form.Item>
        <Form.Item label="Code APE" name={["company", "codeAPE"]}>
          <Input
            placeholder="8299Z"
            onKeyDown={handleKeyDown}
          />
        </Form.Item>

        <Form.Item label="Description" name={["company", "description"]}>
          <Input.TextArea
            rows={6}
            placeholder="Description des activités de la structure"
            onKeyDown={handleKeyDown}
          />
        </Form.Item>
        <Form.Item name={["company", "address"]} label="Adresse">
          <Input
            id="autocomplete"
            prefix={<MapPinIcon width={20} height={20} />}
            placeholder="Adresse postale"
            onKeyDown={handleKeyDown}
          />
        </Form.Item>
        <Form.Item hidden name={["company", "city"]} label="City">
          <Input
            prefix={<MapPinIcon width={20} height={20} />}
            placeholder="Adresse postale"
            onKeyDown={handleKeyDown}
          />
        </Form.Item>
        <Form.Item hidden name={["company", "postalCode"]} label="Postal Code">
          <Input
            prefix={<MapPinIcon width={20} height={20} />}
            placeholder="Adresse postale"
            onKeyDown={handleKeyDown}
          />
        </Form.Item>
        <Form.Item label="Personne à contacter*">
          <Row gutter={24} style={{ alignItems: "center" }}>
            <Col xs={24} md={12}>
              <Form.Item
                name={["person1", "firstName"]}
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir votre prénom",
                  },
                ]}
                noStyle
              >
                <Input
                  placeholder="Prénom"
                  onKeyDown={handleKeyDown}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={["person1", "lastName"]}
                noStyle
                rules={[
                  {
                    required: true,
                    message: "Veuillez entrer votre nom de famille",
                  },
                ]}
              >
                <Input
                  placeholder="Nom"
                  onKeyDown={handleKeyDown}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item label="Numéro de téléphone*">
          <Space.Compact>
            <Form.Item
              name={["person1", "countryCode"]}
              rules={[
                {
                  required: true,
                  message: "Add Country Code",
                },
              ]}
              noStyle
            >
              <Select
                style={{ width: 120 }}
                showSearch
                autoClearSearchValue
              >
                {list.map((country, i) => (
                  <Select.Option
                    key={i}
                    value={"+" + country.countryCallingCode}
                  >
                    {"+" + country.countryCallingCode}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name={["person1", "phone"]}
              noStyle
              rules={[
                {
                  required: true,
                  message: "Veuillez entrer un numéro de téléphone",
                },
              ]}
            >
              <Input
                style={{ width: "calc(100% - 120px)" }}
                placeholder="Numéro de téléphone"
                onKeyDown={handleKeyDown}
              />
            </Form.Item>
          </Space.Compact>
        </Form.Item>
        <Form.Item
          name={["person1", "email"]}
          label="Email*"
          rules={[
            {
              required: true,
              message: "Veuillez entrer votre e-mail",
            },
            {
              type: "email",
              message: "Veuillez entrer une adresse email valide",
            },
          ]}

        >
          <Input
            prefix={<MailOutlined />}
            placeholder="Email"
            type="email"
            onKeyDown={handleKeyDown}
          // readOnly
          />
        </Form.Item>
        <Form.Item name={["company", "iban"]} label="IBAN">
          <Input
            placeholder="IBAN"
            onKeyDown={handleKeyDown}
          />
        </Form.Item>
        <Form.Item name={["company", "bic"]} label="BIC">
          <Input
            placeholder="BIC"
            onKeyDown={handleKeyDown}
          />
        </Form.Item>
        <Divider />
        <Typography.Title level={4} className="heading">
          Informations du dirigeant
        </Typography.Title>
        <Typography.Paragraph>
          Renseignez les informations du dirigeant de l’entreprise.
        </Typography.Paragraph>
        <Divider />
        <Form.Item label="Nom*">
          <Row gutter={24} style={{ alignItems: "center" }}>
            <Col style={{ flex: 1 }}>
              <Form.Item
                name={["person2", "firstName"]}
                noStyle
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir votre prénom",
                  },
                ]}
              >
                <Input
                  placeholder="Prénom"
                  onKeyDown={handleKeyDown}
                />
              </Form.Item>
            </Col>
            <Col style={{ flex: 1 }}>
              <Form.Item
                name={["person2", "lastName"]}
                noStyle
                rules={[
                  {
                    required: true,
                    message: "Veuillez entrer votre nom de famille",
                  },
                ]}
              >
                <Input
                  placeholder="Nom"
                  onKeyDown={handleKeyDown}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item name={["person2", "email"]} label="Email professionnel" rules={[
          {
            type: "email",
            message: "Veuillez entrer une adresse email valide",
          },
        ]} >
          <Input
            prefix={<MailOutlined />}
            placeholder="Adresse email"
            type="email"
            onKeyDown={handleKeyDown}
          />
        </Form.Item>
        <Form.Item label="Numéro de téléphone">
          <Space.Compact>
            <Form.Item name={["person2", "countryCode"]} noStyle>
              <Select
                style={{ width: 120 }}
                showSearch
                autoClearSearchValue
              >
                {list.map((country, i) => (
                  <Select.Option
                    key={i}
                    value={"+" + country.countryCallingCode}
                  >
                    {"+" + country.countryCallingCode}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name={["person2", "phone"]} noStyle>
              <Input
                style={{ width: "calc(100% - 120px)" }}
                placeholder="Numéro de téléphone"
                onKeyDown={handleKeyDown}
              />
            </Form.Item>
          </Space.Compact>
        </Form.Item>
        <Row>
          <Col style={{ marginLeft: "auto" }}>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Continuer
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default InformationsLegales;
