import React, { useState, useContext } from "react";
import { Avatar, Button, Card, Col, Divider, Form, Input, Result, Row, Select, Typography, Upload, theme, Alert } from "antd";
import cl from "country-codes-list";
import { DownOutlined, MailOutlined } from "@ant-design/icons";
import { ReactComponent as CloudUploadIcon } from "../../../images/upload-cloud.svg";
import { t } from "i18next";
import { validatePassword } from "../../../functions";
import { AuthContext } from "../../../context/AuthContext";

const SettingsPage = () => {
  const list = cl.all();
  const { token } = theme.useToken();

  const MAX_BIO_CHAR = 400;
  const [activeTabKey1, setActiveTabKey1] = useState("tab1");
  const tabList = [
    // {
    //   key: "tab1",
    //   tab: t("USER_SETTING"),
    // },
    // {
    //   key: "tab2",
    //   tab: t("COMPANY_SETTING"),
    // },
    {
      key: "tab1",
      tab: t("CHANGE_PASSWORD"),
    },
  ];
  const contentList = {
    // tab1: (
    //   <Step1Form
    //     MAX_BIO_CHAR={MAX_BIO_CHAR}
    //     list={list}
    //     token={token}
    //     onFinish={() => {}}
    //   />
    // ),
    // tab2: <Step2Form list={list} token={token} onFinish={() => {}} />,
    tab1: <Step3Form token={token} onFinish={() => { }} />,
  };
  const onTab1Change = (key) => {
    setActiveTabKey1(key);
  };
  return (
    <>
      <Card
        bordered={false}
        className="card-nobg card-nopadding"
        title={
          <Typography.Title style={{ margin: 0, color: "#101828" }} level={2}>
            {t("SETTINGS")}
          </Typography.Title>
        }
        tabList={tabList}
        activeTabKey={activeTabKey1}
        onTabChange={onTab1Change}
      >
        <div style={{ paddingTop: 30 }}>{contentList[activeTabKey1]}</div>
      </Card>
    </>
  );
};

export default SettingsPage;

export const Step2Form = ({ onFinish, list, token }) => {
  const [form] = Form.useForm();
  return (
    <Form
      name="step2Form"
      form={form}
      labelAlign="left"
      colon={false}
      size="large"
      requiredMark={false}
      labelCol={{
        md: 6,
        xs: 12,
      }}
      onFinish={() => onFinish(2)}
    >
      <Row>
        <Col>
          <Typography.Title level={4} className="heading">
            {t("COMPANY_INFO")}
          </Typography.Title>
          <Typography.Paragraph>
            {t("UPDATE_COMPANY_INFO")}
          </Typography.Paragraph>
        </Col>
        <Col
          style={{
            marginLeft: "auto",
          }}
        >
          <Button size="large" htmlType="button">
            {t("CANCEL")}
          </Button>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            style={{ marginLeft: 16 }}
          >
            {t("SUBMIT")}
          </Button>
        </Col>
      </Row>
      <Divider />
      <Form.Item
        label={
          <>
            <div>
              <div>{t("LOGO_COMPANY")}</div>
              <small className="text-mute">{t("UPLOAD_COMPANY_PHOTO")}</small>
            </div>
          </>
        }
      >
        <Form.Item name={"logo"}>
          <Upload className="image-upload-grid image-upload-grid-large max-width">
            <div>
              <Avatar
                style={{
                  backgroundColor: "#F2F4F7",
                  border: "6px solid #F9FAFB",
                  alignItems: "center",
                  display: "flex",
                  margin: "auto",
                }}
                size={50}
              >
                <CloudUploadIcon
                  width={17}
                  height={15}
                  style={{ display: "block" }}
                />
              </Avatar>
              <u className="text-primary">{t("SELECT")}</u> {t("FROM_DEVICE")}
              <br />
              <small>{t("IMAGE_TYPES")}</small>
            </div>
          </Upload>
        </Form.Item>
      </Form.Item>
      <Form.Item
        label={t("COMPANY_NAME")}
        name={"company_name"}
        rules={[
          {
            required: true,
            message: t("ERROR_COMPANY_NAME"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("DESCRIPTION")}
        name={"description"}
        rules={[
          {
            required: true,
            message: t("ERROR_DESCRIPTION"),
          },
        ]}
      >
        <Input.TextArea rows={6} />
      </Form.Item>
      <Form.Item
        label={t("COMPANY_ADDRESS")}
        name={"company_address"}
        rules={[
          {
            required: true,
            message: t("ERROR_COMPANY_ADDRESS"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label={t("PHONE_NUMBER")}>
        <Input.Group compact>
          <Form.Item name={"countryCode"} noStyle>
            <Select style={{ width: 120 }} showSearch autoClearSearchValue>
              {list.map((country) => (
                <Select.Option value={"+" + country.countryCallingCode}>
                  {"+" + country.countryCallingCode}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={"phone"}
            noStyle
            rules={[{ required: true, message: t("ERROR_PHONE_NUMBER") }]}
          >
            <Input
              style={{ width: "calc(100% - 120px)" }}
              placeholder={t("ENTER_PHONE_NUMBER")}
            />
          </Form.Item>
        </Input.Group>
      </Form.Item>
      <Form.Item name={"contactEmail"} label={t("EMAIL_ADDRESS")}>
        <Input prefix={<MailOutlined />} placeholder={t("CONTACT_EMAIL")} />
      </Form.Item>

      <Divider />
      <Typography.Title level={4} className="heading">
        Information du dirigeant
      </Typography.Title>
      <Typography.Paragraph>
        Renseignez les informations du dirigeant de l’entreprise.
      </Typography.Paragraph>
      <Divider />
      <Form.Item label="Nom">
        <Row gutter={24}>
          <Col xs={24} sm={12}>
            <Form.Item name={"first_name"} noStyle>
              <Input placeholder="Prenom" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item name={"last_name"} noStyle>
              <Input placeholder="Nom" />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item
        name="email"
        label="Email professionnel"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input prefix={<MailOutlined />} placeholder="Votre email Adresse" />
      </Form.Item>
      <Form.Item label="Numéro de téléphone">
        <Input.Group compact>
          <Form.Item name={"countryCallingCode"} noStyle>
            <Select style={{ width: 120 }} showSearch autoClearSearchValue>
              {list.map((country) => (
                <Select.Option value={"+" + country.countryCallingCode}>
                  {"+" + country.countryCallingCode}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={"ownerPhoneNumber"}
            noStyle
            rules={[{ required: true, message: "Please enter a phone number" }]}
          >
            <Input
              style={{ width: "calc(100% - 120px)" }}
              placeholder="Enter phone number"
            />
          </Form.Item>
        </Input.Group>
      </Form.Item>
    </Form>
  );
};
export const Step1Form = ({ onFinish, list, MAX_BIO_CHAR, token }) => {
  const [form] = Form.useForm();
  return (
    <Form
      name="step1Form"
      form={form}
      labelAlign="left"
      colon={false}
      size="large"
      requiredMark={false}
      labelCol={{
        md: 6,
        xs: 12,
      }}
      wrapperCol={{
        md: 12,
        xs: 12,
      }}
      initialValues={{
        about_me: "",
      }}
      onFinish={() => onFinish(1)}
    // onFinishFailed={() => setCurrentStep(1)}
    >
      <Row>
        <Col>
          <Typography.Title level={4} className="heading">
            {t("USER_INFO")}
          </Typography.Title>
          <Typography.Paragraph>{t("UPDATE_YOUR_INFO")}</Typography.Paragraph>
        </Col>
        <Col
          style={{
            marginLeft: "auto",
          }}
        >
          <Button size="large" htmlType="button">
            {t("CANCEL")}
          </Button>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            style={{ marginLeft: 16 }}
          >
            {t("SUBMIT")}
          </Button>
        </Col>
      </Row>
      <Divider />
      <Form.Item label="Nom">
        <Row gutter={24}>
          <Col xs={24} sm={12}>
            <Form.Item name={"first_name"}>
              <Input placeholder={t("FIRST_NAME")} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item name={"last_name"}>
              <Input placeholder={t("LAST_NAME")} />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      <Divider />
      <Form.Item
        name="email"
        label={t("EMAIL_ADDRESS")}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input
          prefix={<MailOutlined />}
          placeholder={t("YOUR_EMAIL_ADDRESS")}
        />
      </Form.Item>
      <Divider />
      <Form.Item
        label={
          <>
            <div>
              <div>{t("YOUR_PHOTO")} </div>
              <small className="text-mute">{t("UPLOAD_YOUR_PHOTO")}</small>
            </div>
          </>
        }
      >
        <Row gutter={20}>
          <Col>
            <Avatar size={64}>{t("PIC")}</Avatar>
          </Col>
          <Col style={{ flex: 1 }}>
            <Form.Item name={"photo"}>
              <Upload className="image-upload-grid image-upload-grid-large">
                <div>
                  <Avatar
                    style={{
                      backgroundColor: "#F2F4F7",
                      border: "6px solid #F9FAFB",
                      alignItems: "center",
                      display: "flex",
                      margin: "auto",
                    }}
                    size={50}
                  >
                    <CloudUploadIcon
                      width={17}
                      height={15}
                      style={{ display: "block" }}
                    />
                  </Avatar>
                  <u className="text-primary">{t("SELECT")}</u>
                  {t("FROM_DEVICE")}
                  <br />
                  <small>{t("IMAGE_TYPES")}</small>
                </div>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      <Divider />
      <Form.Item
        name="role"
        label={t("ROLE_IN_COMPANY")}
        rules={[
          {
            required: true,
            message: t("ERROR_ROLE_IN_COMPANY"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Divider />
      <Row style={{ alignItems: "center", marginBottom: 15 }}>
        <Col style={{ flex: 1 }}>
          <Typography.Paragraph style={{ margin: 0, fontWeight: 500 }}>
            {t("UPDATE_E_SIGN")}
          </Typography.Paragraph>
        </Col>
        <Col xs={8} style={{ textAlign: "right" }}>
          <Button
            style={{
              backgroundColor: `rgba(${token.colorPrimaryRGB}, 0.09)`,
              color: token.colorPrimary,
              border: 0,
              boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.05)`,
              height: 44,
            }}
            size="middle"
          >
            {t("NEW_E_SIGN")}
          </Button>
        </Col>
      </Row>
      <Form.Item
        name={"logo"}
        label={
          <div style={{ marginTop: 30 }}>
            <Upload>
              <Button icon={<DownOutlined />}>{t("E_SIGN")}</Button>
            </Upload>
          </div>
        }
      >
        <Result
          title={null}
          subTitle={null}
          style={{ flex: 1, paddingTop: 0, marginTop: 0 }}
          icon={null}
          className="no-margin"
        >
          <div style={{ textAlign: "center" }}>
            <img width={260} src="sign.png" alt="" />
          </div>
          <Typography.Paragraph>
            Digitally signed by FARIS SIDIK (KB3492)
          </Typography.Paragraph>
          <Typography.Paragraph
            style={{ color: token.colorIcon, fontSize: 12 }}
          >
            Signed at: Jun 19, 2020
          </Typography.Paragraph>
        </Result>
      </Form.Item>
      <Divider />
    </Form>
  );
};

export const Step3Form = ({ list, MAX_BIO_CHAR, token }) => {
  const { user } = useContext(AuthContext);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleUpdatePassword = async (values) => {
    try {
      setLoading(true);
      const userId = user.id;
      const { currentPassword, newPassword, confirmPassword } = values;
      if (newPassword !== confirmPassword) {
        setAlert({ type: "error", message: "Passwords do not match." });
        return;
      }
      const response = await fetch("/profile/update-password", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": user.accessToken,
        },
        body: JSON.stringify({ currentPassword, newPassword, userId }),
      });

      const data = await response.json();

      if (data.success) {
        setAlert({ type: "success", message: data.message });
        form.resetFields();
      } else {
        setAlert({ type: "error", message: data.message });
      }

      setTimeout(() => {
        setAlert(null);
      }, 5000);
      //
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // const validateNewPassword = (_, value, callback) => {
  //   const confirm = form.getFieldValue("confirmPassword");

  //   if (!value?.trim()) {
  //     callback("Please enter your new password");
  //   } else if (value.length < 8) {
  //     callback("Password should be at least 8 characters");
  //   } else if (!value.match(/.*[A-Z].*/)) {
  //     callback("Password should include at least one uppercase letter");
  //   } else if (!value.match(/.*[a-z].*/)) {
  //     callback("Password should include at least one lowercase letter");
  //   } else if (!value.match(/.*\d.*/)) {
  //     callback("Password should include at least one digit");
  //   } else if (!value.match(/.*[!@#$%^&*()].*/)) {
  //     callback("Password should include at least one special character");
  //     // } else if (value !== confirm) {
  //     //   callback();
  //   } else {
  //     callback();
  //   }
  // };

  const reset = () => {
    form.resetFields();
  };

  return (
    <Form
      name="step3Form"
      form={form}
      labelAlign="left"
      colon={false}
      size="large"
      requiredMark={false}
      labelCol={{
        md: 6,
        xs: 12,
      }}
      wrapperCol={{
        md: 12,
        xs: 12,
      }}
      onFinish={(values) => {
        handleUpdatePassword(values);
      }}
    >
      <Row>
        <Col>
          <Typography.Title level={4} className="heading">
            Changer le mot de passe
          </Typography.Title>
        </Col>
        <Col
          style={{
            marginLeft: "auto",
          }}
        >
          {/* <Button size="large" htmlType="button" onClick={reset}>
              Annuler
            </Button> */}
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            style={{ marginLeft: 16 }}
            loading={loading}
          >
            Enregistrer
          </Button>
        </Col>
      </Row>
      {alert && (
        <Alert message={alert.message} type={alert.type} showIcon closable />
      )}
      <Divider />
      <Form.Item
        label={
          <div
            style={{
              whiteSpace: "normal",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography.Paragraph style={{ fontWeight: 500, margin: 0 }}>
              Changer le mot de passe
            </Typography.Paragraph>
            <Typography.Paragraph style={{ color: "#999", margin: 0 }}>
              Minimum de 8 caractères composés de chiffres, lettres minuscules,
              lettres majuscules et sans espace.
            </Typography.Paragraph>
          </div>
        }
      >
        <label style={{ marginBottom: 10, display: "block" }}>
          Mot de passe actuel
        </label>
        <Form.Item
          name="currentPassword"
          rules={[
            {
              required: true,
              // message: "Please enter your current password",
            },
          ]}
        >
          <Input.Password placeholder="Mot de passe actuel" />
        </Form.Item>
        <Divider />
        <label style={{ marginBottom: 10, display: "block" }}>
          Nouveau mot de passe
        </label>
        <Form.Item
          name="newPassword"
          rules={[
            {
              validator: validatePassword,
            },
          ]}
        >
          <Input.Password
            name="newPassword"
            placeholder="Entrez le nouveau mot de passe"
          />
        </Form.Item>
        <label style={{ marginBottom: 10, display: "block" }}>
          Confirmez le nouveau mot de passe
        </label>
        <Form.Item
          validateFirst
          name="confirmPassword"

          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("Les mots de passe ne correspondent pas");
              },
            }),
          ]}
        >
          <Input.Password
            name="confirmPassword"
            placeholder="Confirmez le nouveau mot de passe"
          />
        </Form.Item>
      </Form.Item>
    </Form>
  );
};