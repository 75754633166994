import React, { useContext, useEffect, useState } from "react";
import { MainLayout } from "./Layout";
import { Avatar, Button, Card, Col, Empty, Form, Input, Row, Typography, theme } from "antd";
import { ReactComponent as TrendIcon } from "../images/trend.svg";
import { CalendarOutlined, EditOutlined, EuroCircleOutlined, EyeOutlined, FilterOutlined } from "@ant-design/icons";
import { DataTable } from "./Components/DataTable";
import { DrawTag } from "./Components/DrawTag";
import { numberFormatter } from "../App";
import { Heading } from "./Components/Heading";
import { StatisticsCard } from "./Components/StatisticsCard";
import { useParams } from "react-router-dom";
import { AuthContext, useAuthContext } from "../context/AuthContext";
import { ProjectContext, useProjectContext } from "../context/ProjectContext";
import { useActionContext } from "../context/ActionContext";
import EmptyState from "./Components/EmptyState";
import { createProjectTitle, formatDate, normalize, saveInvestment } from "../functions";
import CsrAction from "./Components/CsrAction";
import piece_de_monnaie_png from "../images/piece-de-monnaie.svg";
import eye from "../images/logo.png";

const InvestmentsDetail = () => {
  const { token } = theme.useToken();

  const actions = useActionContext()
  const { projects, invoicesTotal, investTotal } = useProjectContext()
  const { user } = useAuthContext()

  const params = useParams()
  const [form] = Form.useForm();

  const [association, setAssociation] = useState({})
  const [investments, setInvestments] = useState([])
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const statusList = [
    {
      value: 1,
      label: "En cours de vérification",
      color: token.colorSuccess,
    },
    {
      value: 0,
      label: "En cours",
      color: token.colorPrimary,
    },
    {
      value: -1,
      label: "Terminé",
      color: token.colorText,
    },
  ]
  useEffect(() => {
    if (actions.associations.length) {
      // const [invstId, assoId] = params.id.split("-")
      const assoId = params.id
      const filtered = actions.associations.filter(asso => asso.id.toString() === assoId)
      setAssociation(filtered[0])
      // const filteredInvest = filtered[0].investments.filter(invs => invs.id.toString() === invstId)
      setInvestments([...filtered[0].investments])
    }
  }, [actions.associations])


  return (
    <MainLayout>
      <CsrAction
        open={open} setOpen={setOpen} defaultVal={normalize(association.name) + `-${association.key}`} available={invoicesTotal - investTotal}
      />
      <Row style={{ marginBottom: 24 }}>
        <Col style={{ flex: 1 }}>
          <Typography.Title style={{ margin: 0, color: "#101828" }} level={2}>
            Mes actions RSE
          </Typography.Title>
          <Typography.Title
            level={5}
            style={{ color: "#475467", margin: 0, fontWeight: 400 }}
          >
            Suivez vos actions RSE.
          </Typography.Title>
        </Col>
        <Col>
          <Button size="large" style={{ marginLeft: 16 }} type="primary" onClick={() => setOpen(true)}>
            Nouvel investissement RSE
          </Button>
        </Col>
      </Row>
      <Row style={{ marginBottom: 24 }}>
        <Col style={{ flex: 1 }}></Col>
        <Col>
          <Button size="large" icon={<CalendarOutlined />}>
            Date départ - Date fin
          </Button>
          <Button
            size="large"
            style={{ marginLeft: 16 }}
            icon={<FilterOutlined />}
          >
            Filters
          </Button>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col xs={24} md={8}>
          <StatisticsCard
            label={"Montant DRCP"}
            value={invoicesTotal}
            description={<>&nbsp;</>}
          />
        </Col>
        <Col xs={24} md={8}>
          <StatisticsCard
            label={"Montant investi"}
            value={investTotal}
            description={<>&nbsp;</>}
          />
        </Col>
        <Col xs={24} md={8}>
          <StatisticsCard
            label={"Montant en banque*"}
            value={invoicesTotal - investTotal}
            description={
              "*montant viré sur compte bancaire au 1er juin si non investi."
            }
          />
        </Col>
      </Row>
      <Row gutter={20}>
        <Col xs={24} style={{ marginTop: 30 }}>
          <Typography.Paragraph style={{ fontWeight: 600 }}>
            Ajouter une nouvelle association
          </Typography.Paragraph>
          <Form form={form} size="large" onFinish={values => actions.handleCreateAssociation(values, form, setLoading)}>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item name="name" rules={[{ required: true, message: 'Please input your association name!' }]}>
                  <Input name="name" placeholder="Nom de l’association" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item name="siret" rules={[{ required: true, message: 'Please input your siret number!' }]}>
                  <Input name="siret" placeholder="Numéro de Siret" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item name="phone" rules={[{ required: true, message: 'Please input your téléphone!' }]}>
                  <Input name="phone" placeholder="Numéro de téléphone" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item name="mail" rules={[{ required: true, message: 'Please input your mail Adresse!' }]}>
                  <Input name="mail" placeholder="Adresse mail" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Button
                    type="link"
                    htmlType="submit"
                    style={{ backgroundColor: "#F3ECF4", height: 44 }}
                    size="middle"
                    loading={loading}
                  >
                    Ajouter une nouvelle association
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Card title={<span><Avatar size={40} src={association.logo} /><span style={{ paddingLeft: 10 }}>{association.name}</span></span>} bordered={false} bodyStyle={{ padding: 20 }}>
            <DataTable
              alterBg
              noFilters
              empty={<EmptyState btnText="Démarrer une action RSE" message="Pas encore d’actions RSE ?" onClick={() => setOpen(true)} />}
              dataSource={investments}
              columns={[
                {
                  title: "Semestres des actions",
                  key: "projectId",
                  dataIndex: "projectId",
                  render: (projectId) => {
                    const filtered = projects.filter(p => p.id.toString() === projectId.toString())
                    const p = filtered.length ? filtered[0] : {}
                    return (
                      <Card.Meta
                        className="table-meta-cell"
                        title={<span>{p?.title}</span>}
                        description={`Du ${formatDate(p.startDate)} au ${formatDate(p.endDate)}`}
                      />
                    );
                  },
                },
                {
                  key: "createdAt",
                  dataIndex: "createdAt",
                  title: "Dates des actions",
                  render: (date) => {
                    return <span>{formatDate(date)}</span>;
                  },
                },
                {
                  key: "status",
                  dataIndex: "status",
                  title: "Status",
                  width: 114,
                  render: (status) => {
                    const list = {
                      "En cours": "success",
                      "Terminé": "black",
                    };
                    const filtered = statusList.filter(s => s.value === status)
                    return <DrawTag dots color={list[filtered[0].label]} value={filtered[0].label} />;
                  },
                },
                {
                  key: "amount",
                  dataIndex: "amount",
                  title: "Montants investiss",
                  align: "right",
                  render: (value, obj) => {
                    return numberFormatter(value) + " €"
                  }
                },
                {
                  key: "icon",
                  dataIndex: "icon",
                  title: "",
                  align: "right",
                  render: (value, obj) => {
                    return <img className="invst__icon" src={obj.status === 0 ? piece_de_monnaie_png : eye} alt="piece_de_monnaie" onClick={() => {
                      if (obj.status === 0) {
                        setOpen(true)
                      }
                    }} />
                  }
                },
              ]}
            />
          </Card>
        </Col>
      </Row>
    </MainLayout>
  );
};

export { InvestmentsDetail };
