import React, { useState, useEffect } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
  theme,
} from "antd";
import { t } from "i18next";
import {
  CheckOutlined,
  DownloadOutlined,
  FilterOutlined,
  MailOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { DataTable } from "../Components/DataTable";
import moment from "moment";
import { useEnterprisesContext } from "../../../context/admin/EnterprisesContext";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../../context/AuthContext";
import { useDocumentContext } from "../../../context/admin/DocumentContext";
import { useActionContext } from "../../../context/ActionContext";
import { searchInDataTable, setErpRead } from "../../../functions";
import { handleDownload } from '../../../functions';

const Enterprises = () => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const { accessToken } = useAuthContext()
  const { enterprises, selectEnterprise, setEnterprises } = useEnterprisesContext();
  const { associations } = useActionContext();
  const [filteredenterprises, setFilteredEnterprises] = useState(enterprises);
  const [search, setSearch] = useState({
    title: "",
    date: [],
  });

  useEffect(() => {
    filterDataSource();
  }, [search]);

  const handleChange = (name, value) => {
    setSearch({ ...search, [name]: value });
    if (name === "date" && value === null) {
      setSearch({ ...search, date: [] })
    }
  };
  const filterDataSource = () => {
    const { title, date } = search;
    let filteredData = [...enterprises];

    if (title !== "") {
      filteredData = filteredData?.filter((item) =>
        item.companyName.toLowerCase().includes(title.toLowerCase())
      );
    }
    if (date.length !== 0) {
      if (date[0] !== "" && date[1] !== "") {
        const start = new Date(date[0]);
        const end = new Date(date[1]);
        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);
        filteredData = filteredData?.filter((item) => {
          const check = new Date(item.createdAt);
          check.setHours(0, 0, 0, 0);
          return check >= start && check <= end;
        });
      }
    }
    setFilteredEnterprises(filteredData);
  };
  const handleReset = () => {
    setSearch({
      title: "",
      date: [],
    });
  };
  useEffect(() => {
    setFilteredEnterprises(enterprises);
  }, [enterprises]);

  return (
    <>
      <Row style={{ marginBottom: 24 }}>
        <Col style={{ flex: 1 }}>
          <Typography.Title style={{ margin: 0, color: "#101828" }} level={2}>
            {t("BUSINESSES")}
          </Typography.Title>
          <Typography.Title
            level={5}
            style={{ color: "#475467", margin: 0, fontWeight: 400 }}
          >
            {/* Nous somme le jeudi 27 juillet 2023. */}
          </Typography.Title>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col xs={24}>
          <Form layout="vertical" form={form} name="searchForm" size="large">
            <Row gutter={12}>
              <Col xs={12} md={6}>
                <Form.Item name="title" label="Rechercher par nom">
                  <Input
                    prefix={<SearchOutlined size={15} />}
                    name="title"
                    placeholder="Rechercher"
                    onChange={({ target }) =>
                      handleChange("title", target.value)
                    }
                  />
                </Form.Item>
              </Col>
              {/* <Col xs={12} md={4}>
                <Form.Item name="company" label="Entreprises">
                  <Select>
                    {[
                      {
                        value: "Paid",
                        label: "Effectué",
                        color: token.colorSuccess,
                      },
                      {
                        value: "In Progress",
                        label: "En cours",
                        color: token.colorText,
                      },
                    ].map((row) => (
                      <Select.Option key={row.value} value={row.value}>
                        <span
                          className="small-dot"
                          style={{ backgroundColor: row.color }}
                        />
                        {row.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col> */}
              <Col
                xs={12}
                md={6}
                style={{ marginLeft: "auto", textAlign: "right" }}
              >
                <Form.Item label={<>&nbsp;</>} name={"date"}>
                  <DatePicker.RangePicker
                    name="date"
                    size="large"
                    onChange={(value) => handleChange("date", value)}
                    allowClear={() => handleChange("date", null)}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label="&nbsp;">
                  <Button
                    icon={<FilterOutlined />}
                    htmlType="reset"
                    onClick={handleReset}
                  >
                    Réinitialiser les filtres
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          {/* <Card bodyStyle={{ padding: 0 }}> */}
          <DataTable
            alterBg
            name={"companyName"}
            dataSource={filteredenterprises}
            columns={[
              {
                title: "Entités",
                key: "companyName",
                dataIndex: "companyName",
                render: (value, row) => {
                  return (
                    <Link
                      to={`${row.id}`}
                      onClick={async () => {
                        selectEnterprise(row)
                        if (!row.read) {
                          const company = await setErpRead(row.id, accessToken)
                          if (Object.hasOwnProperty.call(company, "id")) {
                            setEnterprises(erps => {
                              let old = [...erps]
                              const i = old.findIndex(e => e.id.toString() === company.id.toString())
                              old.splice(i, 1, company)
                              return [...old]
                            })
                          }
                        }
                      }}
                    >
                      {value}
                    </Link>
                  );
                },
              },
              {
                title: "Dates d’inscription",
                key: "createdAt",
                dataIndex: "createdAt",
                render: (value) => {
                  return value ? moment(value).format("DD/MM/YYYY") : null;
                },
              },
              // {
              //   title: "Mandat signé",
              //   key: "validate",
              //   dataIndex: "validate",
              //   render: (validate) => {
              //     return validate ? "Oui" : "Non";
              //   },
              // },
              {
                title: "Facture envoyée",
                key: "invoices",
                dataIndex: "invoices",
                render: (invoices) => {
                  return invoices ? "Oui" : "Non";
                },
              },
              {
                title: "À relancer",
                key: "return",
                dataIndex: "return",
                render: (value, row) => {
                  const timeDiff =
                    new Date().getTime() - new Date(row.createdAt).getTime();
                  const daysDiff = Math.abs(
                    Math.floor(timeDiff / (1000 * 3600 * 24))
                  );
                  let isOui = daysDiff <= 7 && row.invoices?.length < 1;
                  return isOui ? "Oui" : "Non";
                },
              },
              {
                key: "invoices",
                dataIndex: "invoices",
                title: "",
                align: "right",
                render: (value, row) => {
                  const fa = associations.filter(a => a.userId?.toString() === row.userId?.toString() && a.status === 0)
                  const filtered = value.filter(v => v.status === 0)
                  const ids = filtered.map(f => f.id)
                  return <Space style={{ gap: 12 }}>
                    {
                      filtered.length ? <Badge
                        count={filtered.length}
                        color="#FEF3F2"
                        style={{
                          color: "#B42318",
                          fontSize: 10,
                          fontWeight: 700,
                        }}
                      >
                        <Button
                          style={{ color: token.colorPrimary }}
                          icon={<DownloadOutlined />}
                          onClick={() => handleDownload(ids, accessToken)}
                        />
                      </Badge> : null
                    }

                    <Badge
                      count={fa.length}
                      color="#FEF3F2"
                      style={{
                        color: "#B42318",
                        fontSize: 10,
                        fontWeight: 700,
                      }}
                    >
                      <Link to={`${row.id}`} state={{ tab: "3", userId: row.userId }}>
                        <Button
                          style={{ color: token.colorPrimary }}
                          icon={<CheckOutlined />}
                        />
                      </Link>
                    </Badge>
                    {/* <Badge
                      count={2}
                      color="#FEF3F2"
                      style={{
                        color: "#B42318",
                        fontSize: 10,
                        fontWeight: 700,
                      }}
                    >
                      <Button
                        style={{ color: token.colorPrimary }}
                        icon={<MailOutlined />}
                      />
                    </Badge> */}
                  </Space>
                },
              },
            ]}
          />
          {/* </Card> */}
        </Col>
      </Row>
    </>
  );
};

export default Enterprises;
