import React, { useContext, useEffect, useState } from "react";
import { MainLayout } from "./Layout";
import { Avatar, Button, Card, Col, DatePicker, Empty, Form, Input, Modal, Row, Select, Typography, theme } from "antd";
import { ReactComponent as CompanyLogo } from "../images/companyLogo.svg";
import { DataTable } from "./Components/DataTable";
import { DrawTag } from "./Components/DrawTag";
import { numberFormatter } from "../App";
import { StatisticsCard } from "./Components/StatisticsCard";
import { AuthContext } from "../context/AuthContext";
import { getAssociations, normalize, saveInvestment } from "../functions";
import { ProjectContext, useProjectContext } from "../context/ProjectContext";
import EmptyState from "./Components/EmptyState";
import { Link } from "react-router-dom";
import { FileImageFilled } from "@ant-design/icons";
import { useActionContext } from "../context/ActionContext";
import CsrAction from "./Components/CsrAction";

const Investments = () => {
  const { token } = theme.useToken();
  const { user } = useContext(AuthContext);
  const [form] = Form.useForm();

  const actions = useActionContext()
  const { projects, invoicesTotal, investTotal } = useProjectContext()
  const [open, openModal] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [investments, setInvestments] = useState([]);
  const [associations, setAssociations] = useState([]);
  useEffect(() => {
    // if (associations.length !== actions.associations.length) {
    //   setAssociations([...actions.associations.filter(a => a.userId.toString() === user?.id?.toString)]);
    // }
    setAssociations([...actions.associationsToShow]);
  }, [actions.associationsToShow])

  // useEffect(() => {
  //   if (projects.length) {
  //     if (associations.length) {
  //       let list = []
  //       for (const p of projects) {
  //         const s = p.semester
  //         for (const i of p.investments) {
  //           const asso = associations.filter(as => as.id === i.associationId)
  //           i.logo = asso[0]?.logo
  //           i.name = asso[0]?.name
  //           i.semester = s
  //         }
  //         list = [...list, ...p.investments]
  //       }
  //       setInvestments([...list])
  //     }
  //   }
  // }, [projects, associations])

  const list = { "En cours": "success", "Terminé": "black", };
  const statusList = [
    { value: 0, label: "En cours", color: token.colorSuccess, },
    { value: 1, label: "Terminé", color: token.colorPrimary, },
  ]

  return (
    <MainLayout>
      <CsrAction
        open={open} setOpen={openModal} available={invoicesTotal - investTotal}
      />
      <Row style={{ marginBottom: 24 }}>
        <Col style={{ flex: 1 }}>
          <Typography.Title style={{ margin: 0, color: "#101828" }} level={2}>
            Mes actions RSE
          </Typography.Title>
          <Typography.Title
            level={5}
            style={{ color: "#475467", margin: 0, fontWeight: 400 }}
          >
            Suivez vos actions RSE.
          </Typography.Title>
        </Col>
        <Col>
          <Button
            size="large"
            style={{ marginLeft: 16 }}
            type="primary"
            onClick={() => openModal(true)}
          >
            Nouvel investissement RSE
          </Button>
        </Col>
      </Row>
      {/*  Filter */}
      {/* <Row style={{ marginBottom: 24 }}>
        <Col style={{ flex: 1 }}></Col>
        <Col>
          <Form.Item noStyle>
            <DatePicker.RangePicker size="large" />
          </Form.Item>
          <Button
            size="large"
            style={{ marginLeft: 16 }}
            icon={<FilterOutlined />}
          >
            Filters
          </Button>
        </Col>
      </Row> */}
      <Row gutter={20}>
        <Col xs={24} md={8}>
          <StatisticsCard
            label={"Montant DRCP"}
            value={invoicesTotal}
            description={<>&nbsp;</>}
          />
        </Col>
        <Col xs={24} md={8}>
          <StatisticsCard
            label={"Montant investi"}
            value={investTotal}
            description={<>&nbsp;</>}
          />
        </Col>
        <Col xs={24} md={8}>
          <StatisticsCard
            label={"Montant en banque*"}
            value={invoicesTotal - investTotal}
            description={
              "*montant viré sur compte bancaire au 1er juin si non investi."
            }
          />
        </Col>
      </Row>
      <Row gutter={20}>
        <Col xs={24} style={{ marginTop: 30 }}>
          <Typography.Paragraph style={{ fontWeight: 600 }}>
            Ajouter une nouvelle association
          </Typography.Paragraph>
          <Form form={form} size="large" onFinish={values => actions.handleCreateAssociation(values, form, setLoading)}>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item name="name" rules={[{ required: true, message: 'Please input your association name!' }]}>
                  <Input name="name" placeholder="Nom de l’association" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item name="siret" rules={[{ required: true, message: 'Please input your siret number!' }]}>
                  <Input name="siret" placeholder="Numéro de Siret" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item name="phone" rules={[{ required: true, message: 'Please input your téléphone!' }]}>
                  <Input name="phone" placeholder="Numéro de téléphone" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item name="mail" rules={[{ required: true, message: 'Please input your mail Adresse!' }]}>
                  <Input name="mail" placeholder="Adresse mail" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <Button
                    type="link"
                    htmlType="submit"
                    style={{ backgroundColor: "#F3ECF4", height: 44 }}
                    size="middle"
                    loading={loading}
                  >
                    Ajouter une nouvelle association
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Card bordered={false} bodyStyle={{ padding: 20, marginBottom: 40 }}>
            <DataTable
              name={"name"}
              alterBg
              statusList={statusList}
              empty={
                <EmptyState btnText="Démarrer une action RSE" message="Pas encore d’actions RSE ?" onClick={() => openModal(true)} />
              }
              dataSource={associations.filter(asso => asso.investments.length)}
              columns={[
                {
                  title: "Causes",
                  key: "name",
                  dataIndex: "name",
                  render: (value, row) => {
                    return (
                      <Card.Meta
                        className="table-meta-cell"
                        avatar={<Avatar size={40} src={row.logo} alt={value} />}
                        title={<Link to={`/investments/${row.id}`}>{value}</Link>}
                        description={row.url}
                      />
                    );
                  },
                },
                {
                  key: "status",
                  dataIndex: "status",
                  title: "Statuts",
                  // width: 114,
                  render: (status) => {

                    const filtered = statusList.filter(s => s.value === status)
                    return <DrawTag dots color={list[filtered[0]?.label]} value={filtered[0]?.label} />;
                  },
                },
                {
                  key: "amount",
                  dataIndex: "amount",
                  title: "Montants investis",
                  align: "right",
                  render: (value, row) => {
                    let amount = 0
                    for (const invest of row.investments) {
                      amount += invest.amount
                    }
                    return numberFormatter(amount) + " €"
                  },
                },
              ]}
            />
          </Card>

          {/* New investments list */}
          <Typography.Paragraph style={{ fontWeight: 600 }}>
            Nouvelles associations créées
          </Typography.Paragraph>
          <Card bordered={false} bodyStyle={{ padding: 0 }}>
            <DataTable
              noFilters={true}
              alterBg
              dataSource={actions.newAssociations}
              columns={[
                {
                  title: "Causes",
                  key: "name",
                  dataIndex: "name",
                  render: (value, row) => {
                    return (
                      <Card.Meta
                        className="table-meta-cell"
                        avatar={<Avatar size={40} src={row.logo} alt={value} />}
                        title={value}
                        description={row.url}
                      />
                    );
                  },
                },
                {
                  key: "status",
                  dataIndex: "status",
                  title: "Statuts",
                  // width: 114,
                  render: (status) => {

                    const filtered = statusList.filter(s => s.value === status)
                    return <DrawTag dots color={list[filtered[0]?.label]} value={filtered[0]?.label} />;
                  },
                },
                {
                  key: "investments",
                  dataIndex: "investments",
                  title: "Montants investis",
                  align: "right",
                  render: (investments, row) => {
                    let amount = 0
                    for (const invest of investments) {
                      amount += invest.amount
                    }
                    return numberFormatter(amount) + " €"
                  },
                },
              ]}
            />
          </Card>
        </Col>
      </Row>
    </MainLayout>
  );
};

export { Investments };
