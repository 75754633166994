import React, { useState } from "react";
import { Form, Input, Button, Typography, Alert } from "antd";
import { ReactComponent as KeyIcon } from "../images/key.svg";
import "../css/style.css";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
// import ForgotPasswordSuccess from "./ForgotPasswordSuccess";
import axios from "axios";
const { Title } = Typography;

const ForgotPasswordPage = () => {
  // const [step, setStep] = useState(1);
  // const [emailForm, setEmailForm] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useNavigate();

  //   const history = useHistory();

  const handleEmailSubmit = async (values) => {
    try {
      const response = await axios.post("/auth/forgot-password", {
        email: values.email,
      });
      if (response.data.success) {
        location("/forgot-password/success", {
          state: { data: values },
        });
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const renderStep1 = () => {
  return (
    <div className="authpage-wrapper">
      <Form
        name="forgotPassword"
        onFinish={handleEmailSubmit}
        layout="vertical"
        requiredMark={false}
        size="large"
      >
        {error && (
          <Form.Item>
            <Alert type="error" message={error} showIcon />
          </Form.Item>
        )}
        <div className="text-center">
          <KeyIcon width={66} height={66} />
          <Title level={2}>Mot de passe oublié ?</Title>
          <Typography.Paragraph>
            Pas de soucis, nous vous enverrons des instructions de
            réinitialisation.
          </Typography.Paragraph>
        </div>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Veuillez entrer une adresse email valide",
            },
            {
              type: "email",
              message: "Veuillez entrer une adresse email valide",
            },
          ]}
        >
          <Input placeholder="Votre email" />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Réinitialiser le mot de passe
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            block
            icon={<ArrowLeftOutlined />}
            htmlType="button"
            type="link"
            className="text-mute"
            onClick={() => navigate("/login")}
          >
            Retour
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ForgotPasswordPage;
