import React, { useEffect, useState } from 'react'
import AdminLayout from '../../AdminLayout'
import { Alert, Button, Col, Divider, Form, Input, Row, Select, Typography } from 'antd'
import { useAuthContext } from '../../../../context/AuthContext'
import { useAdminContext } from '../../../../context/admin/AdminContext'
import { useParams } from 'react-router-dom'
import { validatePassword } from '../../../../functions'

const AddUser = () => {
    const { id } = useParams()
    const { addStaff } = useAuthContext()
    const { users, setUsers, userToEdit, selectUser } = useAdminContext()

    const [form] = Form.useForm();

    const [alert, setAlert] = useState({ active: false, type: "", message: "" })
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (userToEdit) {
            form.setFieldsValue(userToEdit)
        }
    }, [userToEdit])

    useEffect(() => {
        return () => {
            selectUser(null)
        }
    }, [])

    const toggleAlert = ({ active = true, type = '', message = "" }) => setAlert({ active, type, message })

    const addUser = async values => {
        let success = false
        let message = ""
        try {
            setLoading(true)
            const added = await addStaff({
                "username": values.username,
                "email": values.email,
                "password": values.password,
                "isAdmin": true
            })
            success = added.success
            message = added.message
            const saved = [...users]
            saved.unshift(added.user)
            setUsers([...saved])
        } catch (e) {
            console.log(e.message);
            message = e.message
        } finally {
            setLoading(false)
            form.resetFields()
        }
        toggleAlert({ type: success ? "success" : "error", message })
    }

    // const validatePassword = (_, value, callback) => {
    //     let message = undefined
    //     if (!value || value?.trim() === "") {
    //         message = "Requis!"
    //     } else if (!value?.match(/.*[A-Z].*/)) {
    //         message = "Password should include at least one uppercase letter"
    //     } else if (!value?.match(/.*[a-z].*/)) {
    //         message = "Password should include at least one lowercase letter"
    //     } else if (!value?.match(/.*\d.*/)) {
    //         message = "Password should include at least one digit"
    //     } else if (!value?.match(/.*[!@#$%^&*()].*/)) {
    //         message = "Password should include at least one special character"
    //     } else if (value?.length < 8) {
    //         message = "Password should be at least 8 characters"
    //     }
    //     callback(message)
    // };

    return (
        <>
            <Row style={{ marginBottom: 24 }}>
                <Col style={{ flex: 1 }}>
                    <Typography.Title style={{ margin: 0, color: "#101828" }} level={2}>
                        Ajouter un nouvel utilisateur
                    </Typography.Title>
                    <Typography.Title
                        level={5}
                        style={{ color: "#475467", margin: 0, fontWeight: 400 }}
                    >
                        {" "}
                    </Typography.Title>
                </Col>
            </Row>
            {
                alert.active ? <Alert style={{ marginBottom: 20 }} showIcon type={alert.type} message={alert.message} /> : null
            }
            <Form
                // style={{ width: 800, margin: '0 auto' }}
                form={form}
                colon={false}
                labelCol={{
                    md: 6,
                    xs: 12,
                }}
                labelAlign="left"
                onFinish={addUser}
            >
                <Row gutter={16}>
                    {/* <Col span={14}>
                        <Form.Item name={'firstName'} label="First Name" rules={[
                            { required: true, message: "Requis!" },
                        ]}>
                            <Input size="large" placeholder='John' />
                        </Form.Item>
                    </Col>
                    <Col span={14}>
                        <Form.Item name={'lastName'} label="Last Name" rules={[
                            { required: true, message: "Requis!" },
                        ]}>
                            <Input size="large" placeholder='Doe' />
                        </Form.Item>
                    </Col> */}
                    <Col span={14}>
                        <Form.Item name={'username'} label="User name" rules={[
                            { required: true, message: "Requis!" },
                        ]}>
                            <Input size="large" placeholder='johndoe' />
                        </Form.Item>
                    </Col>
                    <Col span={14}>
                        <Form.Item name={'email'} label="Email" rules={[
                            { required: true, message: "Requis!" },
                            {
                                type: "email",
                                message: "Veuillez entrer une adresse email valide",
                            },
                        ]}>
                            <Input size="large" placeholder='something@domain.com' />
                        </Form.Item>
                    </Col>
                    <Col span={14}>
                        <Form.Item name={'password'} label="Password" rules={[
                            { required: true },
                            { validator: validatePassword }
                        ]}>
                            <Input.Password size="large" />
                        </Form.Item>
                    </Col>
                    <Col span={14}>
                        <Form.Item name={'confirm'} label="Confirm password" rules={[
                            { required: true, message: "Requis!" },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue("password") === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject("Passwords do not match");
                                },
                            })
                        ]}>
                            <Input.Password size="large" />
                        </Form.Item>
                    </Col>
                    {/* <Col
                        // offset={6}
                        span={14}>
                        <Form.Item name={'role'} label="Role">
                            <Select
                                size='large'
                                allowClear
                                defaultValue={"admin"}
                                options={[
                                    { value: 'admin', label: 'Admin' },
                                    { value: 'user', label: 'User' }
                                ]}
                            />
                        </Form.Item>
                    </Col> */}
                    <Col span={14}>
                        <Form.Item label={' '}>
                            <Button size='large' loading={loading} style={{ width: "fit-content" }} type='primary' htmlType='submit'>
                                Add user
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default AddUser
