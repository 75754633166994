import { ConfigProvider, Table } from "antd";
import React, { useState, useEffect } from "react";
import { Button, Col, Form, Input, Row, Select, theme, DatePicker } from "antd";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import { themeToken } from "../../../App";

const DataTable = ({
  columns,
  dataSource,
  alterBg,
  empty,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
 
 
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <ConfigProvider
        renderEmpty={() => (empty ? empty : undefined)}
        theme={{
          token: {
            colorPrimary: themeToken.colorPrimary,
            colorPrimaryBg: themeToken.tableSelectedRowBg,
            borderRadius: 6,
          },
        }}
      >
        <Table
          pagination={{
            hideOnSinglePage: true,
          }}
          rowClassName={(record, index) =>
            alterBg
              ? index % 2 === 0
                ? "table-row-light"
                : "table-row-dark"
              : ""
          }
          // rowSelection={rowSelection}
          dataSource={dataSource}
          columns={columns}
        />
      </ConfigProvider>
    </>
  );
};

export { DataTable };
