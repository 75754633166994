import React, { useCallback, useEffect, useState } from "react";
import { DataTable } from '../Components/DataTable';
import { Button, Card, theme, Modal, Form, Typography, Upload, Skeleton, message, Image, Divider } from 'antd';
import { ReactComponent as AddButtonIcon } from "../../../images/add-btn.svg";
import { t } from "i18next";
import axios from "axios";
import { useAuthContext } from "../../../context/AuthContext";
import { useNotificationContext } from "../../../context/NotificationContext";
import { useDocumentContext } from "../../../context/admin/DocumentContext";
import PDFViewerComp from "../Components/PDFViewerComp";

const Documents = props => {
    const { token } = theme.useToken()
    const { documents, loading, uploadDoc } = useDocumentContext()

    const [open, setOpen] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [index, setIndex] = useState(null);
    const [doc, setDoc] = useState(null);
    const [docView, setDocView] = useState(false);
    const [savedDoc, setSavedDoc] = useState(null);

    useEffect(() => {
        if (Object.keys(documents).length && props.enterprise) {
            const list = documents[props.enterprise.userId]
            setDataSource([...list])
        }
    }, [documents, props.enterprise])

    const upload = async () => {
        if (doc) {
            const formData = new FormData()
            formData.append("document", doc)
            formData.append("docId", savedDoc?.id)
            formData.append("name", dataSource[index]?.name)
            formData.append("key", dataSource[index]?.key)
            formData.append("userId", props.enterprise.userId)
            const response = await uploadDoc(formData, props.enterprise.userId, index, savedDoc?.id)
            if (response) {
                setOpen(false)
                setIndex(null)
                setDoc(null)
                setDocView(false)
            }
        } else {
            message.error(`Veuillez soumettre le document`);
        }
    }
    const getBase64 = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            let a = { ...savedDoc, file: reader.result, fileName: file.name }
            setSavedDoc(a)
        };
        reader.onerror = (error) => console.log(error);
    }

    return (
        <>
            <div>
                {
                    <DataTable
                        statusList={[
                            {
                                value: "Paid",
                                label: "Remboursé",
                                color: token.colorSuccess,
                            },
                            {
                                value: "In Progress",
                                label: "En cours de traitement",
                                color: token.colorWarning,
                            },
                        ]}
                        dataSource={dataSource}
                        columns={[
                            {
                                title: "Projet",
                                key: "name",
                                dataIndex: "name",
                                render: (value, row) => {
                                    return (
                                        <Card.Meta
                                            className="table-meta-cell-2"
                                            title={<span>{value}</span>}
                                        />
                                    );
                                },
                            },
                            {
                                key: "file",
                                dataIndex: "file",
                                title: "",
                                width: 100,
                                render: (file, row, i) => {
                                    return (
                                        <Button
                                            type="link"
                                            onClick={() => {
                                                setOpen(true)
                                                setIndex(i)
                                                if (file) {
                                                    setSavedDoc({ ...row })
                                                    setDoc({ ...row })
                                                    setDocView(true)
                                                }
                                            }}
                                        >
                                            {file ? "Voir" : "Ajouter"}
                                        </Button>
                                    );
                                },
                            },
                        ]}
                    />
                }
            </div>
            <Modal
                className="uploadImage"
                width={680}
                open={open}
                onCancel={() => {
                    setOpen(false)
                    setDoc(null)
                    setSavedDoc(null)
                    setDocView(false)
                }}
                okText={t("ADD")}
                // footer={!docView}
                cancelText={doc ? "Supprimer" : "Annuler"}
                cancelButtonProps={{
                    block: true, size: "large", onClick: () => {

                        if (!doc) {
                            setOpen(false)
                        }
                        // setSavedDoc(null)
                        setDoc(null)
                        setDocView(false)
                    }
                }}
                okButtonProps={{ block: true, size: "large", loading: loading === "U", disabled: loading === "U" || docView, onClick: upload }}
            >
                <>
                    <Typography.Title
                        level={4}
                        style={{ margin: 0, fontSize: 18 }}
                    >
                        {
                            savedDoc?.file ? dataSource[index]?.name : `Ajouter ${dataSource[index]?.name}`
                        }
                    </Typography.Title>
                    {
                        doc ? <>
                            {
                                (() => {
                                    const splits = doc.fileName ? doc.fileName.split(".") : doc.type.split("/")
                                    const extension = splits[splits.length - 1]
                                    if (extension === "pdf") {
                                        return <PDFViewerComp file={savedDoc?.file} width={"100%"} height={372} />
                                    }
                                    return <Image src={savedDoc?.file} alt={doc.fileName ?? doc.name} />
                                })()
                            }
                        </> : <Upload
                            className="image-upload-grid image-upload-grid-large border-dashed"
                            onRemove={() => {
                                setDoc(null)
                            }}
                            beforeUpload={file => {
                                getBase64(file)
                                setDoc(file)
                                return Upload.LIST_IGNORE
                            }}
                            fileList={doc ? [doc] : []}
                        >
                            <div>
                                <AddButtonIcon
                                    width={34}
                                    height={34}
                                    style={{ display: "block", margin: "0 auto 10px" }}
                                />
                                Déposez ou téléchargez un document
                            </div>
                        </Upload>
                    }
                </>
            </Modal>
        </>
    )
}

export default Documents
