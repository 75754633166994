import React from 'react'
import { Button, Empty } from 'antd';
import empty from "../../../images/empty-invoices.png";
import "../../../css/style.css";

const EmptyState = ({ btnText, message, onClick, disabled }) => {
    return (
        <div className='empty__state__table'>
            <Empty
                image={empty}
                // image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{
                    height: 80,
                }}
                description={
                    <span style={{ color: "#3A1F45", fontSize: "14px", lineHeight: "20px" }}>
                        {message}
                    </span>
                }
            >
                <Button type="link" style={{ backgroundColor: "#F3ECF4" }} disabled={disabled} size="large" onClick={onClick}>{btnText}</Button>
            </Empty>
        </div>
    )
}

export default EmptyState
