import React, { Suspense, useEffect } from "react";
import AppRouter from "./AppRouter";
import { AuthProvider } from "./context/AuthContext";
import { ConfigProvider } from "antd";
import "./App.css";
import { ProjectProvider } from "./context/ProjectContext";
import { ActionProvider } from "./context/ActionContext";
import { CompanyProvider, useCompanyContext } from "./context/CompanyContext";
import { NotificationProvider } from "./context/NotificationContext";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./lang/en.json"; // Import translation files for different languages
import translationFR from "./lang/fr.json"; // Import translation files for different languages
import { I18nextProvider } from "react-i18next";
import dayjs from 'dayjs';
import "dayjs/locale/fr";
import updateLocale from 'dayjs/plugin/updateLocale';
import locale from "antd/es/locale/fr_FR";

dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
  weekStart: 1
})
// export function AddLibrary(urlOfTheLibrary) { 
//   const script = document.createElement('script'); 
//   script.src = urlOfTheLibrary; 
//   script.async = true; 
//   document.body.appendChild(script); 
// } 

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationEN }, // Add resources for different languages
    fr: { translation: translationFR }, // Add resources for different languages
    // Add more languages here...
  },
  lng: "en", // Set the default language
  fallbackLng: "en", // Set the fallback language
  interpolation: {
    escapeValue: false, // Disable escaping HTML characters in translations
  },
});

export const numberFormatter = (number, format) => {
  const formatter = new Intl.NumberFormat("fr-FR", {
    minimumFractionDigits: format ? "" : 2,
  });
  if (number === 0) {
    return 0;
  } else {
    return formatter.format(number);
  }
};

export const themeToken = {
  colorBgLayout: "#FCFCFD",
  colorPrimary: "#763984",
  colorPrimaryRGB: "118, 57, 132",
  colorLink: "#763984",
  fontFamily: "Inter, sans-serif",
  colorText: "#344054",
  controlHeightLG: 44,
  colorPrimaryBg: "#f2f3f4",
  tableSelectedRowBg: "#f3ecf4",
  colorError: "#D90000",
};

function App() {

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ConfigProvider
        locale={locale}
        theme={{
          token: {
            colorBgLayout: "#FCFCFD",
            colorPrimary: "#763984",
            colorPrimaryRGB: "118, 57, 132",
            colorLink: "#763984",
            fontFamily: "Inter, sans-serif",
            colorText: "#344054",
            controlHeightLG: 44,
          },
        }}
      >
        {/* <I18nextProvider i18n={i18n}> */}
        <NotificationProvider>
          <AuthProvider>
            <ProjectProvider>
              <CompanyProvider>
                <ActionProvider>
                  <div>
                    <AppRouter />
                  </div>
                </ActionProvider>
              </CompanyProvider>
            </ProjectProvider>
          </AuthProvider>
        </NotificationProvider>
        {/* </I18nextProvider> */}
      </ConfigProvider>
    </Suspense>
  );
}

export default App;
