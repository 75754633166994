import React, { useState } from "react";
import { Form, Input, Button, Result } from "antd";
import "../css/style.css";
import { ReactComponent as KeyIcon } from "../images/email.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
const VerifyCode = () => {
  const [verificationCode, setVerificationCode] = useState("");
  const location = useLocation();
  const values = location.state?.data;
  const navigate = useNavigate();
  const handleVerificationSubmit = (values) => {
    // Verify the code and proceed with password reset logic
  };

  const renderStep = () => {
    return (
      <Form onFinish={handleVerificationSubmit}>
        <div className="authpage-wrapper">
          <Result
            style={{
              width: 380,
              paddingInline: 0,
              fontSize: 14,
            }}
            icon={<KeyIcon width={66} height={66} />}
            status="success"
            title="Vérifiez votre messagerie"
            subTitle={`Nous avons envoyé un lien de vérification à ${values?.email}`}
            extra={[
              <Form.Item className="verify-code-fields">
                <Form.Item name="digit1" noStyle>
                  <Input
                    size="large"
                    style={{ width: "20%" }}
                    maxLength={1}
                    onChange={(e) => setVerificationCode(e.target.value)}
                  />
                </Form.Item>
                <Form.Item name="digit2" noStyle>
                  <Input
                    size="large"
                    style={{ width: "20%" }}
                    maxLength={1}
                    onChange={(e) => setVerificationCode(e.target.value)}
                  />
                </Form.Item>
                <Form.Item name="digit3" noStyle>
                  <Input
                    size="large"
                    style={{ width: "20%" }}
                    maxLength={1}
                    onChange={(e) => setVerificationCode(e.target.value)}
                  />
                </Form.Item>
                <Form.Item name="digit4" noStyle>
                  <Input
                    size="large"
                    style={{ width: "20%" }}
                    maxLength={1}
                    onChange={(e) => setVerificationCode(e.target.value)}
                  />
                </Form.Item>
              </Form.Item>,
              <Button
                size="large"
                type="primary"
                onClick={() =>
                  navigate("/email-varified", { state: { data: values } })
                }
                block
              >
                Valider
              </Button>,
              <br />,
              <br />,
              <Button
                block
                icon={<ArrowLeftOutlined />}
                htmlType="button"
                type="link"
                onClick={() => navigate("/login")}
              >
                Retour
              </Button>,
            ]}
          />
        </div>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Verify
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return <div className="authpage-wrapper">{renderStep()}</div>;
};

export default VerifyCode;
