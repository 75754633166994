import React from 'react'
import Header from '../../Header'
import { DataTable } from "../../../Components/DataTable";
import { useAdminContext } from '../../../../context/admin/AdminContext'
import { Avatar, Button, Card, Col, Divider, Row, Typography } from 'antd';
import EmptyState from '../../../Components/EmptyState';
import { DrawTag } from '../../../Components/DrawTag';
import { Link } from 'react-router-dom';

const Users = () => {
    const { users, selectUser } = useAdminContext()
    return (
        <>
            <Row style={{ marginBottom: 24 }}>
                <Col style={{ flex: 1 }}>
                    <Typography.Title style={{ margin: 0, color: "#101828" }} level={2}>
                        Utilisateurs
                    </Typography.Title>
                    <Typography.Title
                        level={5}
                        style={{ color: "#475467", margin: 0, fontWeight: 400 }}
                    >
                        {" "}
                    </Typography.Title>
                </Col>
            </Row>
            <Card bordered={false} bodyStyle={{ padding: 20 }}>
                <DataTable
                    name={"username"}
                    dataSource={users}
                    columns={[
                        {
                            title: "Name",
                            key: "username",
                            dataIndex: "username",
                            render: (value, row) => {
                                return (
                                    <Card.Meta
                                        className="table-meta-cell"
                                        avatar={<Avatar size={40} src={row.avatarUrl} alt={value} />}
                                        title={value}
                                        description={row.role}
                                    />
                                );
                            },
                        },
                        {
                            key: "email",
                            dataIndex: "email",
                            title: "Email",
                            render: (value, obj) => value,
                        },
                        {
                            key: "active",
                            dataIndex: "active",
                            title: "Status",
                            render: (active) => {
                                return <DrawTag dots color={null} value={active ? "Active" : "Locked"} />;
                            },
                        },
                        {
                            key: "id",
                            dataIndex: "id",
                            title: "",
                            align: "right",
                            render: (id, row) => {
                                return (
                                    <Link to={`${id}`}>
                                        <Button
                                            type="link"
                                            onClick={() =>
                                                selectUser(row)
                                            }
                                        >
                                            Plus d'options
                                        </Button>
                                    </Link>
                                );
                            },
                        },
                    ]}
                    empty={
                        <EmptyState btnText="Ajouter un nouvel utilisateur" message="Pas encore d'utilisateur ?" onClick={() => { }} />
                    }
                />
            </Card>

        </>
    )
}

export default Users
