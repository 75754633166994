import React, { useState, useEffect, useContext } from "react";
import { Avatar, Button, Card, Col, Divider, Form, Input, Result, Row, Select, Table, Typography, Upload, theme, Alert, message, Space, Image, } from "antd";
import cl from "country-codes-list";
import { ArrowLeftOutlined, DownOutlined, MailOutlined, PlusOutlined } from "@ant-design/icons";
import { ReactComponent as Check } from "../images/checkmark.svg";
import { ReactComponent as CloudUploadIcon } from "../images/upload-cloud.svg";
import { useNavigate } from "react-router-dom";
import { MainLayout } from "./Layout";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import { useCompanyContext } from "../context/CompanyContext";
import { validatePassword } from "../functions";
import { useNotificationContext } from "../context/NotificationContext";
import Loader from "./Components/Loader";
import { ReactComponent as MapPinIcon } from "../images/map-pin.svg";
import { debounceFunction } from "../functions";

const ProfilePage = () => {
  const { setCompanyData } = useCompanyContext();
  const { user } = useContext(AuthContext);

  const [data, setData] = useState({});

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const list = cl.all();
  const { token } = theme.useToken();
  const navigation = useNavigate();


  const onSelectChange = (newSelectedRowKeys, b, c) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const MAX_BIO_CHAR = 400;
  const [activeTabKey1, setActiveTabKey1] = useState("tab1");
  const tabList = [
    {
      key: "tab1",
      tab: "Réglages utilisateur",
    },
    {
      key: "tab2",
      tab: "Réglages entreprise",
    },
    {
      key: "tab3",
      tab: "Mot de passe",
    },
  ];

  const contentList = {
    tab1: (
      <Step1Form
        MAX_BIO_CHAR={MAX_BIO_CHAR}
        list={list}
        token={token}
        data={data}
      />
    ),
    tab2: <Step2Form list={list} token={token} data={data} />,
    tab3: <Step3Form list={list} token={token} />,
  };
  const onTab1Change = (key) => {
    setActiveTabKey1(key);
  };

  //#region onbording data

  useEffect(() => {
    if (Object.keys(data).length === 0 && user) {
      (async () => {
        const config = {
          headers: {
            "x-access-token": user.accessToken,
          },
        };

        try {
          const response = await axios.get(
            `/profile/company`,
            // `/profile/company?userId=${user.id}`,
            config
          );
          const { company, person1, person2 } =
            response.data;
          setData({ ...response.data });
          if (company?.id) {
            setCompanyData(company);
          }
        } catch (error) {
          console.error(error.message);
        }
      })();
    }
  }, [user]);

  // //#endregion onbording data

  return (
    <MainLayout>
      <Card
        bordered={false}
        className="card-nobg card-nopadding"
        title={
          <Typography.Title style={{ margin: 0, color: "#101828" }} level={2}>
            Paramètres
          </Typography.Title>
        }
        // extra={
        //   <Input
        //     size="large"
        //     prefix={<SearchOutlined />}
        //     placeholder="Search"
        //   />
        // }
        tabList={tabList}
        activeTabKey={activeTabKey1}
        onTabChange={onTab1Change}
      >
        <div style={{ paddingTop: 30 }}>
          {Object.keys(data).length ? contentList[activeTabKey1] : null}
        </div>

        {currentStep === 3 ? (
          <Result
            title="All Done, Awesome"
            subTitle={
              <>
                <p>You are already to proceed using SIENCE</p>
                <Button>
                  <ArrowLeftOutlined /> Go Back
                </Button>
                <Button
                  type="primary"
                  style={{ marginLeft: 24 }}
                  onClick={() => navigation("/dashboard")}
                >
                  Go to dashboard
                </Button>
              </>
            }
            icon={<Check width={100} />}
          />
        ) : null}
        {currentStep === 2 ? (
          <Form
            name="step3Form"
            form={form}
            labelAlign="left"
            colon={false}
            size="large"
            requiredMark={false}
            labelCol={{
              md: 6,
              xs: 12,
            }}
            onFinish={() => setCurrentStep(3)}
            onFinishFailed={() => setCurrentStep(3)}
          >
            <Row>
              <Col>
                <Typography.Title level={4} className="heading">
                  Team Management
                </Typography.Title>
                <Typography.Paragraph>
                  Add your Team members
                </Typography.Paragraph>
              </Col>
              <Col
                style={{
                  marginLeft: "auto",
                }}
              >
                <Button
                  size="large"
                  htmlType="button"
                  onClick={() => setCurrentStep(3)}
                >
                  I’ll do later
                </Button>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: 15 }}
                >
                  Continue
                </Button>
              </Col>
            </Row>
            <Divider />
            <Form.Item
              label={
                <div>
                  <div>Invite team members </div>
                  <small className="text-mute" style={{ whiteSpace: "wrap" }}>
                    Get your projects up and running faster by inviting your
                    team to collaborate.
                  </small>
                </div>
              }
            >
              <Form.List name="emails">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <Row gutter={20}>
                        <Col md={16}>
                          <Form.Item name={[field.name, "email"]}>
                            <Input prefix={<MailOutlined />} />
                          </Form.Item>
                        </Col>
                        <Col md={8}>
                          <Form.Item name={[field.name, "type"]}>
                            <Select>
                              <Select.Option value="readonly">
                                Read only
                              </Select.Option>
                              <Select.Option value="editable">
                                Editable
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    ))}
                    <Button
                      type="link"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Add another
                    </Button>
                    <Button
                      type="primary"
                      style={{ float: "right" }}
                      onClick={() => add()}
                      icon={<MailOutlined />}
                    >
                      Send invites
                    </Button>
                  </>
                )}
              </Form.List>
            </Form.Item>
            <Divider />
            <Form.Item
              name={"logo"}
              label={
                <>
                  <div>
                    <div>Team members </div>
                    <small className="text-mute" style={{ whiteSpace: "wrap" }}>
                      Manage your existing team and change roles/permissions.
                    </small>
                  </div>
                </>
              }
            >
              <Table
                rowSelection={rowSelection}
                dataSource={[
                  {
                    key: "1",
                    id: "1",
                    first_name: "Olivia",
                    last_name: "Rhye",
                    email: "olivia@company.cm",
                    role: "Admin",
                    avatar: "https://xsgames.co/randomusers/avatar.php?g=pixel",
                  },
                  {
                    key: "2",
                    id: "2",
                    first_name: "Phoenix",
                    last_name: "Baker",
                    role: "Read-only",
                    email: "phoenix@company.cm",
                    avatar: "https://xsgames.co/randomusers/avatar.php?g=pixel",
                  },
                ]}
                columns={[
                  {
                    title: "Name",
                    key: "first_name",
                    dataIndex: "first_name",
                    render: (value, row) => {
                      return (
                        <Card.Meta
                          className="table-meta-cell"
                          avatar={<Avatar size={40} src={row.avatar} />}
                          title={<span>{value + " " + row.last_name}</span>}
                          description={row.email}
                        />
                      );
                    },
                  },
                  {
                    key: "role",
                    dataIndex: "role",
                    title: "Role",
                  },
                  {
                    key: "id",
                    dataIndex: "id",
                    title: "",
                    align: "right",
                    render: (value) => {
                      return (
                        <>
                          <Button
                            style={{
                              color: "#444",
                            }}
                            size="small"
                            type="link"
                          >
                            Delete
                          </Button>
                          <Button size="small" type="link">
                            Edit
                          </Button>
                        </>
                      );
                    },
                  },
                ]}
              />
            </Form.Item>
          </Form>
        ) : null}
      </Card>
    </MainLayout>
  );
};

export default ProfilePage;

export const Step1Form = ({ data, list, MAX_BIO_CHAR, token }) => {
  const { setError } = useNotificationContext();
  const { setCompanyData, companyData } = useCompanyContext();
  const { user, accessToken, updateUser } = useContext(AuthContext);
  const [form] = Form.useForm();
  const [alert, setAlert] = useState({ active: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);
  const [signatureData] = useState({ signature: companyData?.signature });
  const [profilePicData, setProfilePicData] = useState({ profilePic: null });
  const [iframeVisible, setIframeVisible] = useState(false);
  const [sdkError, setSdkError] = useState(null);

  // useEffect(() => { if (companyData && !iframeVisible) handleButtonClick() }, [companyData])
  useEffect(() => {
    form.setFieldsValue(data.person1);
  }, [data.person1]);
  const initializeYouSign = () => {
    try {
      const yousign = new window.Yousign({
        signatureLink: signatureData.signature,
        iframeContainerId: 'iframe-container',
        isSandbox: true, // Set this to true if it's a sandbox environment
      });
      return yousign
    } catch (error) {
      console.error(error.message);
      setIframeVisible(true)
      setSdkError(error.message)
      return false
    }

  };

  const handleButtonClick = () => {
    // Set the state to true to make the iframe visible
    const yousign = initializeYouSign();

    if (yousign) {
      // Open the YouSign iframe
      const iframe = document.getElementById('yousign-iframe');
      if (iframe) {
        iframe.style.width = '100%'; // Set the desired width
        iframe.style.height = '600px'; // Set the desired height
      }

      yousign.onStarted((data) => {
        // console.log(data);
      });
      yousign.onSuccess((data) => {
        setIframeVisible(true);
        // console.log(data);
        handleSignatureSubmit();
        // setCurrentStep(2);
      });

      yousign.onError((data) => {
        console.log(data);
      });

      yousign.onPing((data) => {
        // console.log("Ping - The signature request is loaded");
        // console.log(data);
      });

      // Show the iframe
    }
  };

  const handleSignatureSubmit = async () => {
    try {
      // setLoading(2);
      const payload = new FormData();
      payload.append("signature", signatureData.signature);
      payload.append("signatureStatus", true);
      payload.append("companyId", companyData.id);
      payload.append(`userId`, user.id);
      // Send the data to the backend
      const config = {
        method: "POST",
        url: "/profile/signature",
        data: payload,
        headers: {
          "x-access-token": user.accessToken
        }
      };
      const response = await axios(config);
      const data = response.data;
      if (data.success) {
        setCompanyData({ ...companyData, signatureStatus: true })
        // setUpdatedUser({ ...user, ...data.user })
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      // setLoading(false);
    }
  };

  const toggleAllert = ({ active = true, type = "", message = "" }) => {
    setAlert({ active, type, message });
  };
  // handle update
  const handleUpdate = async (values, accessToken) => {
    setLoading(true);
    try {
      const payload = new FormData();
      payload.append("profilepic", profilePicData.profilePic);
      // payload.append("signature", signatureData.signature);
      for (const key in values) {
        if (key !== "profilepic" && key !== "signature") {
          payload.append(key, values[key]);
        }
      }
      payload.append("companyId", companyData.id);
      payload.append(`userId`, user.id);
      // Send the data to the backend
      const config = {
        method: "PUT",
        url: "/profile/update",
        data: payload,
        headers: {
          "x-access-token": user.accessToken,
        },
      };
      setLoading(true);
      const response = await axios(config);
      const data = response.data;
      if (data.success) {
        form.resetFields(["signature", "profilepic"]);
        setProfilePicData({ profilePic: null });
        // setSignatureData({ signature: null });
      }
      if (data.updatedUserData) {
        updateUser({ ...user, ...data.updatedUserData });
      }
      toggleAllert({
        type: data.success ? "success" : "error",
        message: data.message,
      });

      setTimeout(() => {
        toggleAllert({ active: false });
      }, 5000);


    } catch (error) {
      console.log(error.message);
      toggleAllert({
        type: "error",
        message: error.message,
      });
    } finally {
      setLoading(false);

    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <Form
      name="step1Form"
      form={form}
      labelAlign="left"
      colon={false}
      size="large"
      requiredMark={false}
      labelCol={{
        md: 6,
        xs: 12,
      }}
      wrapperCol={{
        md: 12,
        xs: 12,
      }}
      initialValues={{
        about_me: "",
      }}
      onFinish={(values) => {

        handleUpdate(values, accessToken);
        // form.setFieldsValue(values)
      }}
    >
      {/* <Row>
        <Col>
          <Button
            style={{
              backgroundColor: `rgba(${token.colorPrimaryRGB}, 0.09)`,
              color: token.colorPrimary,
              border: 0,
              boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.05)`,
              height: 44,
            }}
            size="middle"
            onClick={handleButtonClick}

          >
            Ajouter une nouvelle signature
          </Button>
        </Col>
      </Row>
      <Divider /> */}
      <Row>
        <Col>
          <Typography.Title level={4} className="heading">
            Informations de l’utilisateur
          </Typography.Title>
          <Typography.Paragraph>
            Mettez à jour vos informations.
          </Typography.Paragraph>
        </Col>
        <Col
          style={{
            marginLeft: "auto",
          }}
        >
          {/* <Button size="large" htmlType="button">
            Annuler
          </Button> */}
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            style={{ marginLeft: 16 }}
            loading={loading}
          >
            Enregistrer
          </Button>
        </Col>
      </Row>
      {alert.active ? (
        <Alert
          message={alert.message}
          type={alert.type}
          showIcon
          closable
          onClose={() => toggleAllert({ active: false })}
        />
      ) : null}
      <Divider />
      <Form.Item label="Nom">
        <Row gutter={24}>
          <Col xs={24} sm={12}>
            <Form.Item
              name={"firstName"}
              rules={[
                { required: true, message: "Please input your siret number!" },
              ]}
            >
              <Input
                name="firstName"
                placeholder="Prenom"
                onKeyDown={handleKeyDown}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name={"lastName"}
              rules={[
                { required: true, message: "Please input your last Name" },
              ]}
            >
              <Input
                name="lastName"
                placeholder="Nom"
                onKeyDown={handleKeyDown}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      <Divider />
      <Form.Item label="Numéro de téléphone">
        <Space.Compact>
          <Form.Item name={"countryCode"} noStyle>
            <Select
              name="countryCode"
              style={{ width: 120 }}
              showSearch
              autoClearSearchValue
            >
              {list.map((country, i) => (
                <Select.Option key={i} value={"+" + country.countryCallingCode}>
                  {"+" + country.countryCallingCode}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={"phone"}
            noStyle
            rules={[
              {
                required: true,
                message: "Please enter a phone number",
              },
            ]}
          >
            <Input
              name="phone"
              style={{ width: "calc(100% - 120px)" }}
              placeholder="Enter phone number"
              onKeyDown={handleKeyDown}
            />
          </Form.Item>
        </Space.Compact>
      </Form.Item>
      <Divider />
      <Form.Item
        name="email"
        label="Adresse email"
        rules={[{ required: true, message: "Please enter your email" }]}

      >
        <Input
          prefix={<MailOutlined />}
          name="email"
          placeholder="Votre email Adresse"
          readOnly
          onKeyDown={handleKeyDown}
        />
      </Form.Item>
      <Divider />
      {/* <Form.Item
        label={
          <>
            <div style={{ width: 280 }}>
              <div>Votre photo </div>
              <small className="text-mute">
                Cette photo est visible uniquement sur votre profil utilisateur.
              </small>
            </div>
          </>
        }
      >
        <Row gutter={20}>
          <Col>
            <Avatar size={64} src={avatarSrc}>
              Pic
            </Avatar>
          </Col>
          <Col style={{ flex: 1 }}>
            <Form.Item name={"profilepic"}>
              <Upload
                className="image-upload-grid image-upload-grid-large"
                maxCount={1}
                name="profilepic"
                onRemove={(file) => {
                  handleProfilePicChange(null);
                }}
                beforeUpload={(file) => {
                  handleProfilePicChange(file);
                  return false;
                }}
              >
                <div>
                  <Avatar
                    style={{
                      backgroundColor: "#F2F4F7",
                      border: "6px solid #F9FAFB",
                      alignItems: "center",
                      display: "flex",
                      margin: "auto",
                    }}

                    size={50}
                  >
                    <CloudUploadIcon
                      width={17}
                      height={15}
                      style={{ display: "block" }}
                    />
                  </Avatar>
                  <u className="text-primary">Sélectionnez</u> ou déposez un
                  fichier
                  <br />
                  <small>SVG, PNG, JPG or GIF (max. 800x400px)</small>
                </div>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      <Divider />
      <Form.Item name="role" label="Rôle dans l’entreprise">
        <Input
          name="role"
          placeholder="Directrice commerciale"
          onKeyDown={handleKeyDown}
        />
      </Form.Item>
      <Divider /> */}

      {/* signature iframe */}
      {/* <Row style={{ alignItems: "center", marginBottom: 15 }}>
        <Col>
          <Typography.Paragraph style={{ margin: 0, fontWeight: 500 }}>
            Signature électronique du mandat Sience
          </Typography.Paragraph>
        </Col>
        <Col xs={24}>
          {
            !iframeVisible ? <Loader /> : (sdkError ? <Alert
              message={sdkError}
              type={"error"}
              showIcon
            /> : null)
          }
          <div id="iframe-container" style={{ display: iframeVisible ? 'block' : 'none' }}>
          </div>
        </Col>
      </Row> */}
      {/* <Divider style={{ marginTop: 30 }} /> */}
    </Form>
  );
};

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const Step2Form = ({ data, list, token }) => {
  const notification = useNotificationContext();
  const { google } = useCompanyContext();
  const { user, accessToken } = useContext(AuthContext);
  const [form] = Form.useForm();
  const [alert, setAlert] = useState({ active: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);
  const [downloading, setDowloading] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [siret, setSiret] = useState({});
  const [verifying, setVerifying] = useState(false);
  const [logoData, setLogoData] = useState({
    logo: null,
  });
  const toggleAllert = ({ active = true, type = "", message = "" }) => {
    setAlert({ active, type, message });
  };
  useEffect(() => {
    const formattedData = {
      ...data.company,
      ...Object.keys(data.person1).reduce((acc, key) => {
        acc[`p1_${key}`] = data.person1[key];
        return acc;
      }, {}),
      ...Object.keys(data.person2).reduce((acc, key) => {
        acc[`p2_${key}`] = data.person2[key];
        return acc;
      }, {}),
    };
    form.setFieldsValue(formattedData);
    if (data.company?.logo) {
      setPreviewImage(data.company?.logo);
    }
  }, [data.company, data.person1, data.person2]);

  useEffect(() => {
    const input = document.getElementById("autocomplete");
    if (input, google) {
      try {
        const options = {
          fields: ["address_components", "formatted_address", "geometry", "name"]
        };
        const autocomplete = new google.maps.places.Autocomplete(input, options);
        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          const { formatted_address, address_components, geometry } = place
          const postals = address_components.filter(c => c.types[0] === "postal_code")
          const locality = address_components.filter(c => c.types[0] === "locality")
          form.setFieldsValue({
            address: formatted_address, city: locality[0].long_name, postalCode: postals[0].long_name
          });
        })
      } catch (e) {
        console.log(e.message);
      }
    }
  }, [google]);
  useEffect(() => {
    const fields = form.getFieldsValue(true)
    if (Object.hasOwnProperty.call(siret, "company")) {
      const { name, ape, lastName, firstname, address, postalCode, city } = siret.company
      form.setFieldsValue({
        companyName: name ?? fields.companyName,
        codeAPE: ape,
        address: address,
        city,
        postalCode,
        p2_firstName: firstname,
        p2_lastName: lastName
      })
    }

  }, [siret])
  const handleLogoChange = (file) => {
    setLogoData({
      logo: file,
    });
  };
  // console.log(logoData, "logo");

  const handleUpdate = async (values) => {
    setLoading(true);
    try {
      const payload = new FormData();
      payload.append("logo", logoData.logo);

      for (const key in values) {
        if (key !== "logo" && values[key]) {
          payload.append(key, values[key]);
        }
      }
      payload.append(`userId`, user.id);

      // Send the data to the backend
      const config = {
        method: "PUT",
        url: "/profile/update-company",
        data: payload,
        headers: {
          "x-access-token": user.accessToken,
        },
      };
      const response = await axios(config);
      if (response.data.success) {
        form.resetFields(["logo"]);
        setLogoData({ logo: null })
      } else {
        form.scrollToField("siretNumber", "smooth")
        // notification.setError({ message: "Unsuccessful", description: response.data.message })
        form.setFields([
          {
            name: 'siretNumber',
            errors: [response.data.message],
          },
        ]);
      }
      toggleAllert({
        type: response.data.success ? "success" : "error",
        message: response.data.message,
      });
      setTimeout(() => {
        toggleAllert({ active: false });
      }, 5000);
    } catch (error) {
      console.log(error.message);
      toggleAllert({ type: "error", message: error.message });
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const verifySerit = values => {
    const isSiretNumber = Object.hasOwnProperty.call(values, "siretNumber")
    if (isSiretNumber && values.siretNumber.length > 3) {
      debounceFunction(values.siretNumber, user, setSiret, setVerifying)
    }
  };

  const handleDownloadContract = async () => {
    try {
      const config = {
        method: "GET",
        url: `/profile/contract?companyId=${data.company.id}`,
        headers: {
          "content-type": "multipart/form-data",
          "x-access-token": accessToken ?? user.accessToken,
        },
        responseType: 'arraybuffer'
      };
      setDowloading(true)
      const response = await axios(config);
      const blob = new Blob([response.data], { type: "application/pdf" });
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = "Mandat Sience signé";
      a.click();
    } catch (error) {
      console.log(error.message);
    } finally {
      setDowloading(false);
    }
  };

  const handlePreview = async (file) => {
    if (file) {
      if (!file?.url && !file?.preview) {
        file.preview = await getBase64(file?.originFileObj);
      }
      setPreviewImage(file.url || (file.preview));
      setPreviewOpen(true);
    }
  };

  return (
    <Form
      name="step2Form"
      form={form}
      labelAlign="left"
      colon={false}
      size="large"
      requiredMark={false}
      labelCol={{
        md: 6,
        xs: 12,
      }}
      onFinish={(values) => {
        // console.log(values);
        handleUpdate(values);
      }}
      onValuesChange={verifySerit}
    >
      <Row>
        <Col>
          <Typography.Title level={4} className="heading">
            Information de l’entreprise
          </Typography.Title>
          <Typography.Paragraph>
            Mettez à jour les informations de votre entreprise ici.
          </Typography.Paragraph>
        </Col>
        <Col
          style={{
            marginLeft: "auto",
          }}
        >
          {/* <Button size="large" htmlType="button">
            Annuler
          </Button> */}
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            style={{ marginLeft: 16 }}
            loading={loading}
          >
            Enregistrer
          </Button>
        </Col>
      </Row>
      {alert.active ? (
        <Alert
          message={alert.message}
          type={alert.type}
          showIcon
          closable
          onClose={() => toggleAllert({ active: false })}
        />
      ) : null}
      <Divider />
      <Row>
        <Col>
          <Typography.Title level={4} className="heading">
            Mandat Sience signé
          </Typography.Title>
        </Col>
        <Col style={{ marginLeft: "auto", }}>
          <Button type="link" disabled={downloading} loading={downloading} onClick={handleDownloadContract} >Télécharger</Button>
        </Col>
      </Row>
      <Divider />
      <Form.Item
        label={
          <>
            <div>
              <div>Logo de l’entreprise </div>
              <small className="text-mute">
                Déposez le logo de votre entreprise.
              </small>
            </div>
          </>
        }
      >
        <Form.Item name={"logo"}>
          <div className="company-logo">
            <Image src={previewImage} className="logo company-logo" alt="logo" />
            <Upload
              className="custom-upload"
              // className="image-upload-grid image-upload-grid-large max-width"
              listType="picture-card"
              fileList={[]}
              onPreview={handlePreview}
              onChange={({ file: originFileObj, fileList: newList }) => {
                handlePreview(newList[0])
                handleLogoChange(originFileObj)
              }}
              onRemove={(file) => {
                handleLogoChange(null);
                setPreviewImage(null)
              }}
              beforeUpload={(file) => {
                handleLogoChange(file);
                return false;
              }}
              maxCount={1}
            >
              <div>
                <Avatar
                  style={{
                    backgroundColor: "#F2F4F7",
                    border: "6px solid #F9FAFB",
                    alignItems: "center",
                    display: "flex",
                    margin: "auto",
                  }}
                  size={50}
                >
                  <CloudUploadIcon
                    width={17}
                    height={15}
                    style={{ display: "block" }}
                  />
                </Avatar>
                <u className="text-primary">Click to upload</u> or Drag and drop
                <br />
                <small>SVG, PNG, JPG or GIF (max. 800x400px)</small>
              </div>
            </Upload>
          </div>
        </Form.Item>
      </Form.Item>
      <Form.Item label="Type de structure*">
        <Row gutter={24} style={{ alignItems: "center" }}>
          <Col style={{ flex: 1 }}>
            <Form.Item
              name={"companyType"}
              rules={[
                {
                  required: true,
                  message: "Veuillez entrer le type de structure",
                },
              ]}
              noStyle
            >
              <Select
                autoClearSearchValue
                placeholder="Type de structure"
                name="companyType"
                options={[
                  {
                    value: "Entreprise",
                    label: "Entreprise",
                  },
                  {
                    value: "Association",
                    label: "Association",
                  },
                  {
                    value: " Administration",
                    label: " Administration",
                  },
                ]}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item
        label="Nom de l’entreprise"
        name={"companyName"}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input name="companyName" onKeyDown={handleKeyDown} />
      </Form.Item>
      <Form.Item
        label="Numéro de Siret*"
        name={"siretNumber"}
        hasFeedback
        validateStatus={verifying ? "validating" : (siret.verified ? "success" : siret.verified === undefined ? "" : "error")}
        help={verifying ? "Verifying..." : (siret.verified ? "" : siret.message)}
        rules={[
          {
            required: true,
          },
        ]}

      >
        <Input name="siretNumber" maxLength={14} onKeyDown={handleKeyDown} />
      </Form.Item>
      <Form.Item
        label="Code APE"
        name={"codeAPE"}
      >
        <Input name="codeAPE" onKeyDown={handleKeyDown} />
      </Form.Item>
      <Form.Item
        label="Description"
        name={"description"}

      >
        <Input.TextArea name="description" rows={6} onKeyDown={handleKeyDown} />
      </Form.Item>
      <Form.Item
        label="Adresse de l’entreprise"
        name={"address"}

      >
        <Input id="autocomplete" name="address" onKeyDown={handleKeyDown} prefix={<MapPinIcon width={20} height={20} />} />
      </Form.Item>
      {/* <Form.Item name={"city"} label="Adresse">
        <Input
          prefix={<MapPinIcon width={20} height={20} />}
          placeholder="Adresse postale"
          onKeyDown={handleKeyDown}
        />
      </Form.Item>
      <Form.Item name={"postalCode"} label="Adresse">
        <Input
          prefix={<MapPinIcon width={20} height={20} />}
          placeholder="Adresse postale"
          onKeyDown={handleKeyDown}
        />
      </Form.Item> */}
      <Form.Item
        label="IBAN"
        name={"iban"}
      >
        <Input name="iban" rows={6} onKeyDown={handleKeyDown} />
      </Form.Item>
      <Form.Item
        label="BIC"
        name={"bic"}
      >
        <Input name="bic" rows={6} onKeyDown={handleKeyDown} />
      </Form.Item>
      <Divider />
      <Typography.Title level={4} className="heading">
        Information du dirigeant
      </Typography.Title>
      <Typography.Paragraph>
        Renseignez les informations du dirigeant de l’entreprise.
      </Typography.Paragraph>
      <Divider />
      <Form.Item label="Nom">
        <Row gutter={24}>
          <Col xs={24} sm={12}>
            <Form.Item name={"p2_firstName"} noStyle>
              <Input
                name="p2firstName"
                placeholder="Prenom"
                onKeyDown={handleKeyDown}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item name={"p2_lastName"} noStyle>
              <Input
                name="p2lastName"
                placeholder="Nom"
                onKeyDown={handleKeyDown}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item
        name="p2_email"
        label="Email professionnel"
      >
        <Input
          name="p2email"
          prefix={<MailOutlined />}
          placeholder="Votre email Adresse"
          onKeyDown={handleKeyDown}
        />
      </Form.Item>
      <Form.Item label="Numéro de téléphone">
        <Space.Compact>
          <Form.Item name={"p2_countryCode"} noStyle>
            <Select style={{ width: 120 }} showSearch autoClearSearchValue>
              {list.map((country, i) => (
                <Select.Option key={i} value={"+" + country.countryCallingCode}>
                  {"+" + country.countryCallingCode}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={"p2_phone"}
            noStyle
          >
            <Input
              name="p2phone"
              style={{ width: "calc(100% - 120px)" }}
              placeholder="Enter phone number"
              onKeyDown={handleKeyDown}
            />
          </Form.Item>
        </Space.Compact>
      </Form.Item>
    </Form>
  );
};

export const Step3Form = ({ list, MAX_BIO_CHAR, token }) => {
  const { user } = useContext(AuthContext);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleUpdatePassword = async (values) => {
    try {
      setLoading(true);
      const userId = user.id;
      const { currentPassword, newPassword, confirmPassword } = values;
      if (newPassword !== confirmPassword) {
        setAlert({ type: "error", message: "Les mots de passe ne correspondent pas." });
        return;
      }
      const response = await fetch("/profile/update-password", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": user.accessToken,
        },
        body: JSON.stringify({ currentPassword, newPassword, userId }),
      });

      const data = await response.json();

      if (data.success) {
        setAlert({ type: "success", message: data.message });
        form.resetFields();
      } else {
        setAlert({ type: "error", message: data.message });
      }

      setTimeout(() => {
        setAlert(null);
      }, 5000);
      //
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // const validateNewPassword = (_, value, callback) => {
  //   const confirm = form.getFieldValue("confirmPassword");

  //   if (!value?.trim()) {
  //     callback("Please enter your new password");
  //   } else if (value.length < 8) {
  //     callback("Password should be at least 8 characters");
  //   } else if (!value.match(/.*[A-Z].*/)) {
  //     callback("Password should include at least one uppercase letter");
  //   } else if (!value.match(/.*[a-z].*/)) {
  //     callback("Password should include at least one lowercase letter");
  //   } else if (!value.match(/.*\d.*/)) {
  //     callback("Password should include at least one digit");
  //   } else if (!value.match(/.*[!@#$%^&*()].*/)) {
  //     callback("Password should include at least one special character");
  //     // } else if (value !== confirm) {
  //     //   callback();
  //   } else {
  //     callback();
  //   }
  // };

  const reset = () => {
    form.resetFields();
  };

  return (
    <Form
      name="step3Form"
      form={form}
      labelAlign="left"
      colon={false}
      size="large"
      requiredMark={false}
      labelCol={{
        md: 6,
        xs: 12,
      }}
      wrapperCol={{
        md: 12,
        xs: 12,
      }}
      onFinish={(values) => {
        handleUpdatePassword(values);
      }}
    >
      <Row>
        <Col>
          <Typography.Title level={4} className="heading">
            Changer le mot de passe
          </Typography.Title>
        </Col>
        <Col
          style={{
            marginLeft: "auto",
          }}
        >
          {/* <Button size="large" htmlType="button" onClick={reset}>
            Annuler
          </Button> */}
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            style={{ marginLeft: 16 }}
            loading={loading}
          >
            Enregistrer
          </Button>
        </Col>
      </Row>
      {alert && (
        <Alert message={alert.message} type={alert.type} showIcon closable />
      )}
      <Divider />
      <Form.Item
        label={
          <div
            style={{
              whiteSpace: "normal",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography.Paragraph style={{ fontWeight: 500, margin: 0 }}>
              Changer le mot de passe
            </Typography.Paragraph>
            <Typography.Paragraph style={{ color: "#999", margin: 0 }}>
              Minimum de 8 caractères composés de chiffres, lettres minuscules,
              lettres majuscules et sans espace.
            </Typography.Paragraph>
          </div>
        }
      >
        <label style={{ marginBottom: 10, display: "block" }}>
          Mot de passe actuel
        </label>
        <Form.Item
          name="currentPassword"
          rules={[
            {
              required: true,
              message: "Veuillez entrer votre mot de passe actuel",
            },
          ]}
        >
          <Input.Password placeholder="Mot de passe actuel" />
        </Form.Item>
        <Divider />
        <label style={{ marginBottom: 10, display: "block" }}>
          Nouveau mot de passe
        </label>
        <Form.Item
          name="newPassword"
          rules={[
            {
              validator: validatePassword,
            },
          ]}
        >
          <Input.Password
            name="newPassword"
            placeholder="Entrez le nouveau mot de passe"
          />
        </Form.Item>
        <label style={{ marginBottom: 10, display: "block" }}>
          Confirmez le nouveau mot de passe
        </label>
        <Form.Item
          validateFirst
          name="confirmPassword"

          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("Les mots de passe ne correspondent pas");
              },
            }),
          ]}
        >
          <Input.Password
            name="confirmPassword"
            placeholder="Confirmez le nouveau mot de passe"
          />
        </Form.Item>
      </Form.Item>
    </Form>
  );
};
