import { Alert, Col, Form, Input, InputNumber, Modal, Row, Select, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { numberFormatter } from '../../../App'
import { useActionContext } from '../../../context/ActionContext'
import { normalize, saveInvestment } from '../../../functions'
import { useAuthContext } from '../../../context/AuthContext'
import { useProjectContext } from '../../../context/ProjectContext'

const CsrAction = ({ open, setOpen, defaultVal }) => {
    const [form] = Form.useForm();
    const { user, accessToken } = useAuthContext()
    const actions = useActionContext()
    const { currentProject, updateCurrentProject, updateInvestTotal, invoicesTotal, investTotal } = useProjectContext()
    const available = invoicesTotal - investTotal

    // const [associations, setAssociations] = useState([]);
    const [selected, setSelected] = useState("");
    const [options, setOptions] = useState([]);
    const [association, setAssociation] = useState("");
    const [amount, setAmount] = useState(1);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ active: false, type: '', message: '' });

    useEffect(() => {
        if (options.length) {
            const filtered = options.filter(o => o.value === selected)
            setAssociation(filtered[0]?.label)
        }
    }, [selected])
    useEffect(() => {
        if (defaultVal) {
            setSelected(defaultVal)
        }
    }, [defaultVal])

    useEffect(() => {
        // if (associations.length !== actions.associations.length) {
        if (actions.associations.length) {
            const approved = actions.associations.filter(item => item.status === 1)
            const list = approved.map(a => {
                return ({
                    value: normalize(a.name) + `-${a.key}`,
                    label: a.name + `${a.info ? ` - ${a.info}` : ""}`,
                    key: a.key
                })
            })
            setSelected(defaultVal ?? list[0]?.value)
            setOptions([...list])
            // setAssociations([...actions.associations]);
        }
    }, [actions.associations])

    const toggleAlert = ({ active = true, type = '', message = '' }) => {
        setAlert({ active, type, message })
    }

    const handleSubmit = async () => {
        try {
            if (currentProject && available > 0 && amount > 0 && amount <= available) {
                const filtered = options.filter(o => o.value === selected)
                if (filtered.length) {
                    const payload = {
                        "amount": amount,
                        "status": 0,
                        "associationId": filtered[0].key,
                        "projectId": currentProject.id,
                        "userId": user.id,
                    }
                    setLoading(true)
                    const response = await saveInvestment(accessToken ?? user.accessToken, payload)
                    if (Object.hasOwnProperty.call(response, "investment")) {
                        const investment = response.investment
                        if (Object.hasOwnProperty.call(investment, "id")) {
                            updateInvestTotal(amount)
                            const associations = [...actions.associations]
                            for (const asso of associations) {
                                if (asso.id.toString() === investment.associationId.toString()) {
                                    asso.investments.unshift(investment)
                                }
                            }
                            actions.setAssociations([...associations])
                            const proj = { ...currentProject }
                            const invsts = [...proj.investments]
                            invsts.unshift(investment)
                            proj.investments = [...invsts]
                            updateCurrentProject(proj)
                        }
                    }
                    if (Object.hasOwnProperty.call(response, "association")) {
                        const association = response.association
                        const list = actions.associationsToShow
                        const index = list?.findIndex(a => a.id === association.id)
                        if (index >= 0) {
                            list.splice(index, 1, association)
                        } else {
                            list.unshift(association)
                        }
                        actions.setAssociationsToShow([...list])
                    }
                    setAmount(1)
                    setOpen(false)
                    form.setFieldsValue(null)
                } else {
                    const type = 'error'
                    const message = "Aucune association sélectionnée"
                    toggleAlert({ type, message })
                }
            } else {
                const type = 'info'
                const message = !currentProject ? 'There is no open project' : available <= 0 ? 'Insufficient balance' : (amount <= 0 || amount > available) ? `Amount must be greater than zero and less than ${available}` : ''
                toggleAlert({ type, message })
            }
        } catch (e) {
            toggleAlert({ type: 'error', message: e.message })
            console.log(e.message);
        } finally {
            setLoading(false)
        }
    }
    const close = () => { setOpen(false); form.setFieldsValue(null); setAmount(1); setSelected(defaultVal ?? options[0]?.value); toggleAlert({ active: false }) }
    return (
        <Modal
            width={680}
            open={open}
            onCancel={close}
            okText={"Confirmer l’investissement"}
            cancelText={"Annuler"}
            cancelButtonProps={{ block: false, size: "large" }}
            okButtonProps={{ block: false, size: "large", loading: loading }}
            onOk={() => handleSubmit()}
        >
            <Row>
                <Col style={{ flex: 1 }}>
                    <Typography.Title
                        level={4}
                        style={{
                            margin: 0,
                            fontSize: 18,
                            color: "#000",
                            fontWeight: 500,
                        }}
                    >
                        Nouvel investissement
                    </Typography.Title>
                    <span style={{ color: "#475467", fontSize: 14 }}>
                        Investir dans une ou plusieurs causes RSE
                    </span>
                </Col>
                <Col style={{ textAlign: "right", paddingRight: 20 }}>
                    <span style={{ fontSize: 18, color: "#000", fontWeight: 500 }}>
                        Montant disponible
                    </span>
                    <span style={{ display: "block", color: "#475467", fontSize: 14 }}>
                        {numberFormatter(available)} €
                    </span>
                </Col>
            </Row>
            {
                alert.active ? (<Alert showIcon closable onClose={() => toggleAlert({ active: false })} type={alert.type} message={alert.message} />) : null
            }
            <Row>
                <Col xs={24}>
                    <div className="card-payment">
                        <Form form={form}>
                            <Form.Item
                                labelAlign="left"
                                labelCol={{
                                    xs: 24,
                                }}
                                colon={false}
                                label="Associations"
                            >
                                <Select
                                    size='large'
                                    value={selected}
                                    options={options}
                                    onChange={value => setSelected(value)}
                                />
                            </Form.Item>
                            <p style={{ fontSize: 16, fontWeight: 600, margin: 0, padding: "0 0 20px" }}>
                                {/* Educ’Up - Favoriser l’éducation de tous. */}
                                {association}
                            </p>
                            {/* <Row
                                gutter={10}
                                style={{ alignItems: "center", marginBlock: 10 }}
                            >
                                <Col>
                                <CompanyLogo
                                    width={40}
                                    height={40}
                                    style={{ display: "block" }}
                                />
                                </Col>
                                <Col>
                                <span style={{ fontSize: 16, fontWeight: 500 }}>Educ’Up</span>
                                </Col>
                            </Row> */}
                            <Form.Item
                                help={
                                    <>
                                        Les paiements sont soumis à un contrôle de l’équipe Sience
                                        avant envoi.
                                    </>
                                }
                                labelAlign="left"
                                labelCol={{
                                    xs: 24,
                                }}
                                colon={false}
                                label="Montant de l’investissement"
                            >
                                <InputNumber style={{ width: "100%" }} value={amount} max={available} min={1} placeholder="XXX €" size="large" onChange={value => setAmount(value)} />
                                {/* <Input placeholder="XXX €" size="large" value={amount} onChange={e => setAmount(e.target.value)} /> */}
                            </Form.Item>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Modal>
    )
}

export default CsrAction
