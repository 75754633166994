import React, { useState } from "react";
import { Form, Input, Button, Typography, Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { validatePassword } from "../functions";
import { useAuthContext } from "../context/AuthContext";

const SignupPage = () => {
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const location = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await axios.post("/auth/register", {
        user: {
          username: values.username,
          email: values.email,
          password: values.password,
        },
      })
        .then(res => {
          if (res.data.success) {
            location("/code-sent-success", {
              state: { data: values },
            });
          } else {
            setError(res.data.message)
          }
        })
        .catch(e => setError(e.message))

    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const validatePassword = (_, value, callback) => {
    // if (!value?.trim()) {
    //   callback("Veuillez saisir votre mot de passe");
    // } else
    if (value.length < 6) {
      callback("Le mot de passe doit contenir au moins 6 caractères");
    }
    // else if (!value.match(/.*[A-Z].*/)) {
    //   callback("Le mot de passe doitcontenir au moins une letter capitale");
    // } else if (!value.match(/.*[a-z].*/)) {
    //   callback("Le mot de passe doit inclure au moins une lettre minuscule");
    // } else if (!value.match(/.*\d.*/)) {
    //   callback("Le mot de passe doit contenir au moins un chiffre");
    // } else if (!value.match(/.*[!@#$%^&*()].*/)) {
    //   callback("Le mot de passe doitcontenir au moins un caractère spécial");
    // }
    else {
      callback();
    }
  };

  if (!user) {
    return (
      <div className="authpage-wrapper">
        <div className="text-center">
          <img
            alt=""
            src="/logo.png"
            style={{ height: 59, maxWidth: "100%", width: 95 }}
          />
          <Typography.Title level={2}>S’inscrire</Typography.Title>
          <Typography.Paragraph>Simple, rapide & efficace</Typography.Paragraph>
        </div>
        <Form
          name="signupForm"
          layout="vertical"
          requiredMark={false}
          size="large"
          onFinish={onFinish}
        >
          {error && (
            <Form.Item>
              <Alert type="error" message={error} showIcon />
            </Form.Item>
          )}
          <Form.Item
            label="Prénom Nom"
            name="username"
            rules={[
              {
                required: true,
                message: "Veuillez saisir votre nom d'utilisateur",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Veuillez entrer une adresse email valide" },
              {
                type: "email",
                message: "Veuillez entrer une adresse email valide",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Mot de passe"
            name="password"
            rules={[{ validator: validatePassword }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit" block>
              Valider
            </Button>
          </Form.Item>
          <Form.Item>
            <div style={{ textAlign: "center" }}>
              Vous avez déjà un compte?{" "}
              <Link to="/login">
                <strong>Se connecter</strong>
              </Link>
            </div>
          </Form.Item>
        </Form>
      </div>
    );
  }
};

export default SignupPage;
