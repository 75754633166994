import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PDFViewerComp = props => {
    // const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const path = `/project/invoice/pdf/${props.id}`
    return (
        <>
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                <div
                    className={props.thumbnail ? "pdf-view thumbnail" : 'pdf-view'}
                    style={{
                        // maxHeight: props.height ?? '575px',
                        // minHeight: props.height ?? '575px',
                        height: props.height ?? '575px',
                        width: props.width ?? '506px',
                        overflowY: props.height ? "hidden" : "auto"
                    }}
                >
                    <Viewer
                        fileUrl={props.file ?? path}
                    // plugins={[defaultLayoutPluginInstance]}
                    />
                    {/* {
                        props.file ? <Viewer
                            fileUrl={props.file ?? path}
                        // plugins={[defaultLayoutPluginInstance]}
                        /> : null
                    } */}

                </div>
            </Worker>
        </>
    );
}

export default PDFViewerComp