import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuthContext } from "./AuthContext";
import { getDocs, getProjects } from "../functions";
import { useNotificationContext } from "./NotificationContext";

export const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {
    const { user, accessToken } = useAuthContext()
    const { setError } = useNotificationContext()
    
    const [projects, setProjects] = useState([]);
    const [currentProject, setCurrentProject] = useState(null);
    const [invoicesTotal, setInvoicesTotal] = useState(0);
    const [investTotal, setInvestTotal] = useState(0);
    const [documents, setDocuments] = useState([]);


    useEffect(() => {
        (async () => {
            if (projects.length === 0 && user) {
                const recievedProjects = await getProjects(accessToken ?? user.accessToken);
                if (recievedProjects?.length) {
                    const filtered = recievedProjects.filter(p => p.active)
                    setCurrentProject(filtered[0])
                    storeProjects([...recievedProjects]);
                }

            }
            if (documents.length === 0 && user) {
                const recieved = await getDocs(user.id, accessToken, setError);
                if (recieved) {
                    setDocuments(recieved)
                }
            }
        })();
    }, [user])

    useEffect(() => {
        if (currentProject) {
            let p = 0
            for (const inv of currentProject.invoices) {
                if (inv.status === 1) { // if refunded
                    p += inv.refund
                }
            }
            setInvoicesTotal(p)
            let p1 = 0
            for (const invst of currentProject.investments) {
                // if (invst.status === 1) { // if verified
                p1 += invst.amount
                // }
            }
            setInvestTotal(p1)
        }
    }, [currentProject])


    const storeProjects = (list) => {

        if (list?.length) {
            setProjects(list);
        }
    };

    const updateCurrentProject = p => {
        setCurrentProject(p);

    };
    const updateInvestTotal = t => {
        setInvestTotal(prev => prev + t);

    };

    return (
        <ProjectContext.Provider value={{ projects, invoicesTotal, documents, currentProject, updateCurrentProject, updateInvestTotal, investTotal, storeProjects, setProjects }}>
            {children}
        </ProjectContext.Provider>
    );
};

export const useProjectContext = () => useContext(ProjectContext)
