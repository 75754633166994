import React, { useState, useEffect } from "react";
import { Form, Input, Button, message, Typography, Alert } from "antd";
import { ReactComponent as KeyIcon } from "../images/key.svg";
import "../css/style.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { validatePassword } from "../functions";  
const { Title } = Typography;


const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [isverified, setIsverified] = useState(false);
    const [ error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [userId, setuserId]  =useState(null)

    useEffect(() => {
        (async () => {
            const options = {
                method: "GET",
                url: `auth/forgot-password-verify/${searchParams.get(
                    "t"
                )}?type=reset`,
                headers: {
                    "content-type": "application/json",
                },
                
            };

            axios
                .request(options)
                .then((response) => {
                    const data  = response.data
                    setIsverified(response.data.success);
                    setuserId(data.userId)
                })
                .catch((e) => {
                    setError(e.response.data.message);
                });
        })();
    }, []);


    const handleResetPassword = async (values) => {
        // Perform the reset password  logic here
        const { newPassword, confirmPassword } = values
        if (newPassword !== confirmPassword) {
            message.error("Passwords do not match.");
            return;
        }
        setLoading(true);
        try {
            const response = await axios.put(`/auth/reset-update-password?userId=${userId}`, { newPassword });
            if (response.data.success) {
                setLoading(false);
                message.success("Password reset successful");
                navigate("/reset-password-success");
            } else {
                setError(response.data.message);
            }
        } catch (error) {
            console.error(error.message)
        }
        finally{
            setLoading(false)
        }
    };

    // const validatePassword = (_, value, callback) => {
    //     if (!value?.trim()) {
    //         callback("Please confirm your new password");
    //     } else if (value.length < 8) {
    //         callback("Password should be at least 8 characters");
    //     } else if (!value.match(/.*[A-Z].*/)) {
    //         callback("Password should include at least one uppercase letter");
    //     } else if (!value.match(/.*[a-z].*/)) {
    //         callback("Password should include at least one lowercase letter");
    //     } else if (!value.match(/.*\d.*/)) {
    //         callback("Password should include at least one digit");
    //     } else if (!value.match(/.*[!@#$%^&*()].*/)) {
    //         callback("Password should include at least one special character");
    //     } else {
    //         callback();
    //     }
    // };

    if (isverified) {
        return (
            <div className="authpage-wrapper">
                <div className="text-center">
                    <KeyIcon width={66} height={66} />
                    <Title level={2}>Definir un nouveau mot de passe</Title>
                    <Typography.Paragraph style={{ fontSize: 16 }}>
                        Votre nouveau mot de passe doit être différent des mots
                        de passe précédemment utilisés.
                    </Typography.Paragraph>
                </div>
                <Form
                    name="resetPasswordForm"
                    layout="vertical"
                    requiredMark={false}
                    size="large"
                    onFinish={handleResetPassword}
                >
                    {error && (
                        <Form.Item>
                            <Alert type="error" message={error} showIcon />
                        </Form.Item>
                    )}
                    <Form.Item
                        label="Mot de passe"
                        name="newPassword"
                        rules={[{ validator: validatePassword }]}
                    >
                        <Input.Password name="newPassword"/>
                    </Form.Item>
                    <Form.Item
                        label="Confirm Password"
                        name="confirmPassword"
                        dependencies={["newPassword"]}
                        rules={[
                            // {
                            //     validator: validatePassword,
                            // },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (
                                        !value ||
                                        getFieldValue("newPassword") === value
                                    ) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        "Passwords do not match"
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input.Password  name="confirmPassword"/>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            block
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            Valider
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            block
                            icon={<ArrowLeftOutlined />}
                            htmlType="button"
                            type="link"
                            onClick={() => navigate("/login")}
                            className="text-mute"
                        >
                            Retour
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    } else {
        return error ? (
            <Form.Item>
                <Alert type="error" message={error} showIcon />
            </Form.Item>
        ) : null;
    }
};

export default ResetPassword;
