import React, { useState, useEffect } from "react";
import { Button, Col, Form, Input, Modal, Row, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { DataTable } from "../Components/DataTable";
import { t } from "i18next";
import { useAdminContext } from "../../../context/admin/AdminContext";
import { useAuthContext } from "../../../context/AuthContext";
import { searchInDataTable } from "../../../functions";


const Distributers = (props) => {
    const { distributors, addDistributor } = useAdminContext()
    const { user, accessToken } = useAuthContext()
    const [open, openModal] = useState(false);
    const [loading, setloading] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState("");
  const [filterDistributors, setFilterDistributors] =
    useState(distributors);



    const [form, form1] = Form.useForm();

    const handleDistributor = async values => {
        setloading(1)
        await addDistributor(values, accessToken ?? user.accessToken)
        setloading(false)
        openModal(false)
        form.resetFields()
    }

    const handleFormatPhone = e => {
        const inputText = e.target.value;
        const formatted = inputText.replace(/\s/g, '').replace(/(.{2})/g, '$1 '); // Add space after every 2 characters
        const values = {
            [e.target.name]: formatted,
        }
        if (inputText.length % 3 === 2 && e.nativeEvent.inputType === 'deleteContentBackward') {
            // Handle backspace by removing the last space
            values[e.target.name] = formatted.slice(0, -1);
        }
        form.setFieldsValue(values);
    };

    const handleSearchInputChange = (event) => {
        const keyword = event.target.value;
        setSearchKeyword(keyword);
        const filteredData = searchInDataTable(keyword, distributors, "title");
        setFilterDistributors(filteredData);
      };

     useEffect(() => {
    setFilterDistributors(distributors);
  }, [distributors]);

    return (
        <>
            <Modal
                className="uploadImage"
                width={680}
                open={open === 1}
                onCancel={() => openModal(false)}
                footer={null}
                // okText={t("ADD")}
                // cancelText={"Annuler"}
                // cancelButtonProps={{ style: { display: "none" } }}
                // okButtonProps={{ block: true, size: "large", style: { display: "none" } }}
                title={"Nouveau distributeur"}
                bodyStyle={{
                    paddingInline: 20,
                    paddingBlock: 5,
                }}
            >
                <Form form={form} requiredMark={false} layout="vertical" size="large" onFinish={handleDistributor}>
                    <Form.Item name={"title"} label="Enseigne" rules={[{ required: true }]}>
                        <Input placeholder="Nom de l’enseigne" />
                    </Form.Item>
                    <div
                        className="card-payment"
                        layout="vertical"
                        size="large"
                        style={{ marginTop: 0 }}
                    >
                        <Row gutter={16}>
                            <Col xs={24}>
                                <Form.Item name={"referrer"} label="Nom du référent" rules={[{ required: true }]}>
                                    <Input placeholder="Pierre Dupont" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name={"phone"} label="N° de téléphone" rules={[{ required: true }]}>
                                    <Input name={"phone"} placeholder="06 78 90 12 34" onChange={handleFormatPhone} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name={"email"} label="Adresse email" rules={[{ required: true, type: 'email' }]}>
                                    <Input placeholder="p.dupont@asso.com" />
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Form.Item name={"comment"} label="Commentaire" rules={[{ required: true }]}>
                                    <Input placeholder="Texte" />
                                </Form.Item>
                            </Col>

                        </Row>
                    </div>
                    <Form.Item style={{ margin: 0 }}>
                        <Button loading={loading === 1} disabled={loading === 1} style={{ width: "100%" }} size="large" type="primary" htmlType="submit">{t("ADD")}</Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Row style={{ marginBottom: 24 }}>
                <Col style={{ flex: 1 }}>
                    <Typography.Title style={{ margin: 0, color: "#101828" }} level={2}>
                        Distributers
                    </Typography.Title>
                </Col>
            </Row>

            <Row gutter={20}>
                <Col xs={24}>
                    <Form layout="vertical" form={form1} name="searchForm" size="large">
                        <Row gutter={12}>
                            <Col xs={12} md={5}>
                                <Form.Item name="search" label="Rechercher par nom">
                                    <Input
                                        prefix={<SearchOutlined size={15} />}
                                        placeholder="Rechercher"
                                        value={searchKeyword}
                                        onChange={handleSearchInputChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                xs={12}
                                md
                                style={{
                                    marginLeft: "auto",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    gap: 12,
                                }}
                            >
                                <Form.Item label="&nbsp;">
                                    <Button
                                        onClick={() => openModal(1)}
                                        style={{ marginLeft: 12 }}
                                        type="primary"
                                    >
                                        {t("NEW_DISTRIBUTER")}
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col xs={12} md></Col>
                        </Row>
                    </Form>
                    <DataTable
                    
                        dataSource={searchKeyword ? filterDistributors : distributors}
                        columns={[
                            {
                                title: "Distributeurs",
                                key: "title",
                                dataIndex: "title",
                                render: (val) => {
                                    return (
                                        <span style={{ color: "#101828", fontWeight: 500 }}>
                                            {val}
                                        </span>
                                    );
                                },
                            },
                            {
                                key: "referrer",
                                dataIndex: "referrer",
                                title: "Référents",
                            },
                            {
                                key: "phone",
                                dataIndex: "phone",
                                title: "N° Tél",
                                render: (phone) => {
                                    return (
                                        <span style={{ color: "#101828", fontWeight: 500 }}>
                                            {phone.replace(/\s+/g, ".")}
                                        </span>
                                    );
                                },
                            },
                            {
                                key: "email",
                                dataIndex: "email",
                                title: "Mails",
                            },
                            {
                                key: "comment",
                                dataIndex: "comment",
                                title: "Commentaires",
                            },
                        ]}
                    />
                </Col>
            </Row>
        </>
    );
};

export default Distributers;
