import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Typography, Upload, Checkbox, Alert, Form, Button } from "antd";
import { ReactComponent as AddButtonIcon } from "../../../images/add-btn.svg";
import { AuthContext } from "../../../context/AuthContext";
import axios from "axios";
import { ProjectContext } from "../../../context/ProjectContext";

const CreateProjectModal = ({
  isOpen,
  onClose,
  isOnBoard,
  projectId,
  title,
  handleClick,
}) => {
  const navigation = useNavigate();
  const { user, updateUser, accessToken } = useContext(AuthContext);
  const [form] = Form.useForm();
  const { projects, storeProjects } = useContext(ProjectContext);
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ active: false, type: "", message: "" });
  const [isChecked, setIsChecked] = useState(false);
  const [validation, setValidation] = useState("");

  const [projectTitle, setProjectTitle] = useState(title);

  useEffect(() => {
    setProjectTitle(title);
  }, [title]);

  useEffect(() => {
    if (projects.length && projectId) {
      const filteredPorjects = projects.filter(
        (p) => p.id?.toString() === projectId?.toString()
      );
      if (filteredPorjects.length) {
        setProjectTitle(filteredPorjects[0]?.title);
      }
    }
  }, [projectId]);

  const toggleAlert = ({ active = true, type = "", message = "" }) => {
    setAlert({ active, type, message });
  };
  const handleProjectSubmit = async () => {
    try {
      let type = "success";
      let message = "";

      if (invoices.length <= 0 && !isChecked) {
        type = "info";
        message = "Aucune facture n'est sélectionnée";
        setValidation("Veuillez cocher la case");
      } else if (invoices.length <= 0) {
        type = "info";
        message = "Aucune facture n'est sélectionnée";
      } else if (!isChecked) {
        setValidation("Veuillez cocher la case");
      } else {
        const payload = new FormData();
        for (const invoice of invoices) {
          payload.append("invoices", invoice);
        }
        // payload.append("title", projectTitle);
        payload.append(`userId`, user.id);
        if (projectId) {
          payload.append(`projectId`, projectId);
          // Send the data to the backend
          const config = {
            method: "POST",
            url: "/profile/project",
            data: payload,
            headers: {
              "content-type": "multipart/form-data",
              "x-access-token": accessToken ?? user?.accessToken,
            },

          };
          setInvoices([]);
          setLoading(true);
          const response = await axios(config);
          message = "Success";
          storeProjects([...response.data.projects]);

          updateUser({ ...user, ...response.data.user })
          handleClose();

          if (isOnBoard) {
            navigation("/dashboard", { replace: true });
          }
        } else {
          type = "error";
          message = "There is no open project!";
        }
      }
      if (type !== "success") {
        toggleAlert({ type, message });
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleProjectInputChange = (file, remove = false) => {
    if (remove) {
      const index = invoices.indexOf(file);
      const newInvoices = invoices.slice();
      newInvoices.splice(index, 1);
      setInvoices(newInvoices);
    } else {
      toggleAlert({ active: false });
      setInvoices((prevFiles) => [...prevFiles, file]);
    }
  };
  const handleClose = () => {
    onClose();
    setInvoices([]);
    toggleAlert({ active: false });
    setIsChecked(false);
    setValidation("");
  };
  const handleCheckBoxChange = (e) => {
    setIsChecked(e.target.checked);
    setValidation("");
  };

  return (
    <Modal
      className="uploadImage"
      width={680}
      // open={open === 1}
      // onCancel={() => openModal(false)}
      okText={"Soumettre"}
      open={isOpen}
      onCancel={handleClose}
      cancelText={"Ajouter des factures plus tard"}
      cancelButtonProps={{
        block: true,
        size: "large",
        onClick: handleClick ?? handleClose,
      }}
      okButtonProps={{
        block: true,
        size: "large",
        loading: loading,
        htmlType: "submit",
      }}
      onOk={handleProjectSubmit}
    >
      <Typography.Title level={4} style={{ margin: 0, fontSize: 18 }}>
        {projectTitle}
      </Typography.Title>
      <span style={{ color: "#999", fontSize: 14 }}>
        Ajouter une ou plusieurs factures
      </span>
      {alert.active ? (
        <Alert
          showIcon
          closable
          onClose={() => toggleAlert({ active: false })}
          type={alert.type}
          message={alert.message}
        />
      ) : null}
      <Form>
        <Upload
          className="image-upload-grid image-upload-grid-large border-dashed"
          name="invoices"
          multiple
          onRemove={(file) => {
            handleProjectInputChange(file, true);
          }}
          beforeUpload={(file) => {
            handleProjectInputChange(file);
            return false;
          }}
          fileList={invoices}
        >
          <div>
            <AddButtonIcon
              width={34}
              height={34}
              style={{
                display: "block",
                margin: "0 auto 10px",
              }}
            />
            Déposez ou téléchargez une ou plusieurs factures
          </div>
        </Upload>
        <Checkbox checked={isChecked} onChange={handleCheckBoxChange}>
          J’atteste la véracité des factures téléchargées.
        </Checkbox>
        {validation && <p style={{ color: "red", margin: 0 }}>{validation}</p>}
      </Form>
    </Modal>
  );
};

export default CreateProjectModal;
