import { Card, Col, Row, Statistic, Typography } from "antd";
import { numberFormatter } from "../../App";

const StatisticsCard = ({ label, value, description }) => {
  return (
    <Card
      bordered={false}
      bodyStyle={{ padding: 20 }}
      rootClassName="stats-card"
    >
      <Statistic
        formatter={(value) => numberFormatter(value, 1)}
        title={
          <Row>
            <Col style={{ flex: 1 }}>
              <Typography.Title
                level={5}
                style={{ margin: 0, fontWeight: 500 }}
              >
                {label}
              </Typography.Title>
            </Col>
            <Col></Col>
          </Row>
        }
        value={value}
      />
      <p className="stats-card-description">{description}</p>
    </Card>
  );
};

export { StatisticsCard };
