import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuthContext } from "./AuthContext";
import { createAssociation, getAssociations } from "../functions";

export const ActionContext = createContext();

export const ActionProvider = ({ children }) => {
    const { user, accessToken } = useAuthContext()
    const [associationsToShow, setAssociationsToShow] = useState([]);
    const [associations, setAssociations] = useState([]);
    const [newAssociations, setNewAssociations] = useState([]);
    const [pendingAssociations, setPendingAssociations] = useState({});

    const getPending = list => {
        const p = {}
        for (const a of list) {
            let price = 0;
            for (const i of a.investments) {
                price += i.amount;
            }
            a.price = price
            
            if (price > 0) {
                if (a.status === 0) {
                    if (Object.hasOwnProperty.call(p, a.userId)) {
                        p[a.userId] += 1
                    } else {
                        p[a.userId] = 1
                    }
                }
            }
        }
        setPendingAssociations(p)
    }

    useEffect(() => {
        if (associations.length === 0 && user) {
            (async () => {
                const recieved = await getAssociations(accessToken ?? user?.accessToken);
                if (recieved.asociations?.length) {
                    setAssociations([...recieved.asociations]);
                }
                if (recieved.invested?.length) {
                    setAssociationsToShow([...recieved.invested])
                }
            })();
        }
    }, [user])

    useEffect(() => {
        if (associations.length) {
            getPending(associations)
            const filtered = associations.filter(a => a.status === 0 && a.userId.toString() === user?.id?.toString())
            setNewAssociations([...filtered])

            // let a = []
            // for (const item of associations) {
            //     const invsts = item.investments.filter(i => i.userId.toString() === user.id.toString())
            //     if (invsts.length) {
            //         a.push({ ...item, investments: invsts })
            //     }
            // }
            // setAssociationsToShow([...a])
        }
    }, [associations])

    const handleCreateAssociation = async (values, form, setLoading) => {
        const payload = {
            "userId": user.id,
            "status": 0,
            ...values
        }
        setLoading(true)
        const association = await createAssociation(accessToken ?? user.accessToken, payload)
        if (association?.id) {
            setNewAssociations(prev => {
                const list = [...prev]
                list.unshift(association)
                return list
            })
            form.resetFields();
        }
        setLoading(false)
    }

    return (
        <ActionContext.Provider value={{ associations, newAssociations, pendingAssociations, associationsToShow, setAssociationsToShow, setAssociations, handleCreateAssociation }}>
            {children}
        </ActionContext.Provider>
    );
};

export const useActionContext = () => useContext(ActionContext)