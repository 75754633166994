import React from "react";
import { Result, Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as KeyIcon } from "../images/email.svg";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const CodeSentSuccess = () => {
  const location = useLocation();
  const values = location.state?.data;
  const navigate = useNavigate();
  return (
    <div className="authpage-wrapper">
      <Result
        style={{
          width: 380,
          paddingInline: 0,
          fontSize: 14,
        }}
        icon={<KeyIcon width={66} height={66} />}
        status="success"
        title="Vérifiez votre messagerie"
        subTitle={`Nous avons envoyé un lien de vérification à ${values?.email}`}
        extra={[

          <div style={{ textAlign: "center" }}>
            Vous n'avez pas reçu l'e-mail ?{" "}
            <Link to="/login">
              <strong>Cliquez pour renvoyer</strong>
            </Link>
          </div>,
          <br />,
          <Button
            size="large"
            type="primary"
            onClick={() => navigate("/login", { state: { data: values } })}
            block
          >
            Valider
          </Button>,
          // <br />,
          // <br />,

          // <div style={{ textAlign: "center" }}>
          //   Vous n'avez pas reçu l'e-mail ?{" "}
          //   <Link to="/login">
          //     <strong>Cliquez pour renvoyer</strong>
          //   </Link>
          // </div>,
          // <br />,
          // <Button
          //   size="large"
          //   block
          //   icon={<ArrowLeftOutlined />}
          //   htmlType="button"
          //   type="link"
          //   onClick={() => navigate("/login")}
          // >
          //   Retour
          // </Button>,

          // <Button
          //   block
          //   icon={<ArrowLeftOutlined />}
          //   htmlType="button"
          //   type="link"
          //   onClick={() => navigate("/login")}
          // >
          //   Retour
          // </Button>
        ]}
      />
    </div>
  );
};

export default CodeSentSuccess;
