import React, { useEffect, useContext, useState } from "react";
import { MainLayout } from "./Layout";
import { Avatar, Button, Card, Col, DatePicker, Image, Modal, Row, Typography, theme } from "antd";
import { DownloadOutlined, FileOutlined, FilterOutlined } from "@ant-design/icons";
import { DataTable } from "./Components/DataTable";
import { DrawTag } from "./Components/DrawTag";
import { numberFormatter } from "../App";
import { useParams } from "react-router-dom";
import { ProjectContext, useProjectContext } from "../context/ProjectContext";
import { formatDate, getProjects } from "../functions";
import { AuthContext } from "../context/AuthContext";
import CreateProjectModal from "./Components/CreateProjectModal";
import EmptyState from "./Components/EmptyState";
import axios from "axios";
import PDFViewerComp from "./AdminArea/Components/PDFViewerComp";

const RemboursementDetail = (props) => {
  const { id } = useParams();

  const { projects, storeProjects } = useContext(ProjectContext);
  const { user, accessToken } = useContext(AuthContext);
  const { documents } = useProjectContext();
  const { token } = theme.useToken();
  const [data, setData] = useState({});
  const [invoices, setInvoices] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [allowInvoice, setAllowInvoice] = useState(false);
  const [doc, setDoc] = useState(null);
  const statusList = [
    {
      value: "0",
      label: "En cours de traitement",
      color: token.colorWarning,
    },
    {
      value: "1",
      label: "Remboursé",
      color: token.colorSuccess,
    },
    {
      value: "-1",
      label: "Refusé",
      color: token.colorErrorText,
    },
  ];

  const filterProjects = (list) =>
    list.filter((i) => !isNaN(i.id) && i.id.toString() === id);

  useEffect(() => {
    if (data) {
      setAllowInvoice(data.active)
    }
  }, [data]);
  useEffect(() => {
    if (projects.length) {
      const current = filterProjects(projects)[0];
      setData(current);
      setInvoices(current?.invoices);
    }
  }, [projects]);

  const statuses = {
    0: "En cours de traitement",
    1: "Remboursé",
    "-1": "Refusé",
  };

  const openModal = () => {
    setModalVisible(true);
  };
  const closeModal = () => {
    setModalVisible(false);
  };

  // const handlePicker = (dates, dateStrings) => {
  //   if (dateStrings[0] !== "" && dateStrings[1] !== "") {
  //     const start = new Date(dateStrings[0]);
  //     const end = new Date(dateStrings[1]);
  //     start.setHours(0, 0, 0, 0);
  //     end.setHours(0, 0, 0, 0);

  //     const filtered = data.invoices.filter((invc) => {

  //       const check = new Date(invc.createdAt);
  //       check.setHours(0, 0, 0, 0);
  //       return check >= start && check <= end;
  //     });
  //     setInvoices([...filtered]);
  //   } else {
  //     setInvoices([...data.invoices]);
  //   }
  // };

  const handleDownloadAll = async () => {
    try {
      const config = {
        method: "GET",
        url: `/project/download?userId=${user.id}&t=${data.title}`,
        headers: {
          "Content-Type": "application/zip",
          "x-access-token": user.accessToken,
        },
        responseType: "blob",
      };
      const response = await axios(config);
      const blob = new Blob([response.data], { type: "application/zip" });
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = `${data.title}.zip`;
      a.click();
    } catch (error) {
      console.error("Error downloading ZIP file:", error);
    }
  };

  return (
    <MainLayout>
      <Row style={{ marginBottom: 24 }}>
        <Col style={{ flex: 1 }}>
          <Typography.Title style={{ margin: 0, color: "#101828" }} level={2}>
            Mes projets - {data?.title}
          </Typography.Title>
          <Typography.Title
            level={5}
            style={{ color: "#475467", margin: 0, fontWeight: 400 }}
          >
            Suivez vos demandes de remboursement.
          </Typography.Title>
        </Col>
        {/* <Col>
          <Button
            size="large"
            style={{ marginLeft: 16 }}
            type="primary"
            disabled={data.status === "1"}
            onClick={() => {
              if (data.status === "0") {
                openModal()
              }
            }}
          >
            Nouvelle demande
          </Button>
        </Col> */}
      </Row>
      <Row style={{ marginBottom: 24 }}>
        <Col style={{ flex: 1 }}>
          <Button
            type="link"
            style={{ backgroundColor: "#F3ECF4" }}
            size="large"
            disabled={!allowInvoice}
            onClick={() => {
              if (allowInvoice) {
                openModal();
              }
            }}
          >
            Ajouter des factures au projet en cours
          </Button>
          <CreateProjectModal
            isOpen={isModalVisible}
            onClose={closeModal}
            isOnBoard={false}
            projectId={id}
          />
        </Col>
        {/* <Col>
          <DatePicker.RangePicker size="large" onChange={handlePicker} />
        </Col> */}
      </Row>
      <Row gutter={20}>
        <Col xs={24} style={{ marginTop: 30 }}>
          <Row>
            <Col style={{ flex: 1 }}>
              <Typography.Title level={4} style={{ margin: 0 }}>
                {data?.subTitle}
              </Typography.Title>
              <Typography.Paragraph>
                Retrouvez le suivi des remboursements du {data?.title} en temps
                réel ici
              </Typography.Paragraph>
            </Col>
            <Col>
              <Button
                type="default"
                size="large"
                onClick={handleDownloadAll}
                icon={<DownloadOutlined />}
              >
                Tout télécharger
              </Button>
            </Col>
          </Row>
          <Card
            bordered={false}
            bodyStyle={{ padding: 20 }}
            style={{ marginBottom: 20, marginTop: 20 }}
          >
            <DataTable
              name={"title"}
              statusList={statusList}
              dateFilter={true}
              empty={
                <EmptyState
                  btnText={`Ajouter de nouvelles factures`}
                  message={`Toujours pas de facture ?`}
                  disabled={!allowInvoice}
                  onClick={openModal}
                />
              }
              dataSource={invoices}
              columns={[
                {
                  title: "Factures",
                  key: "title",
                  dataIndex: "title",
                  // render: (value, row) => {
                  //   // value = value?.split(".")[0];
                  //   return (
                  //     <Card.Meta
                  //       className="table-meta-cell-2"
                  //       avatar={
                  //         <Avatar
                  //           style={{
                  //             background: "#D8C3DD",
                  //           }}
                  //           size={40}
                  //           icon={
                  //             <FileOutlined
                  //               style={{
                  //                 color: token.colorPrimary,
                  //               }}
                  //             />
                  //           }
                  //         />
                  //       }
                  //       title={<span>{value}</span>}
                  //     />
                  //   );
                  // },
                },
                {
                  title: "Date soumission",
                  key: "submitDate",
                  dataIndex: "submitDate",
                  render: (date) => {
                    return <span>{formatDate(date)}</span>;
                  },
                },
                {
                  key: "status",
                  dataIndex: "status",
                  title: "Statut",
                  render: (status) => {
                    return (
                      <DrawTag bordered={false} value={statuses[status]} />
                    );
                  },
                },
                {
                  key: "refund",
                  dataIndex: "refund",
                  title: "Montant DRCP remboursé",
                  render: (refund, row) => {
                    return refund > 0 && row.status === 1
                      ? numberFormatter(refund) + " €"
                      : null;
                  },
                },
                {
                  // key: "download",
                  // dataIndex: "id",
                  // title: "",
                  render: (file) => {
                    return (
                      <a
                        href={file.filePath}
                        target="_blank"
                        download={file.fileName}
                      >
                        {" "}
                        Télécharger
                      </a>
                    );
                  },
                },
              ]}
            />
          </Card>
          <Card bordered={false} bodyStyle={{ padding: 0 }}>
            <DataTable
              noFilters
              dataSource={documents}
              columns={[
                {
                  title: "Documents",
                  key: "name",
                  dataIndex: "name",
                  render: (value, row) => {
                    return (
                      <Card.Meta
                        className="table-meta-cell-2"
                        avatar={
                          <Avatar
                            style={{
                              background: "#D8C3DD",
                            }}
                            size={40}
                            icon={
                              <FileOutlined
                                style={{
                                  color: token.colorPrimary,
                                }}
                              />
                            }
                          />
                        }
                        title={<span>{value}</span>}
                      />
                    );
                  },
                },
                {
                  key: "file",
                  dataIndex: "file",
                  title: "",
                  width: 300,
                  render: (file, row) => {
                    return (
                      <>
                        <Button type="link" onClick={() => { setDoc(row); setOpen(true) }}>Voir</Button>
                        <a
                          href={file}
                          target="_blank"
                          download={row.fileName}
                        >
                          {" "}
                          Télécharger
                        </a>
                      </>
                    );
                  },
                },
              ]}
            />
          </Card>
          <Modal
            // style={{ height: "300px" }}
            className="uploadImage"
            // width={680}
            open={open}
            onCancel={() => { setDoc(null); setOpen(false) }}
            footer={false}
          >
            <Typography.Title
              level={4}
              style={{ margin: 0, fontSize: 18 }}
            >
              {doc?.name}
            </Typography.Title>
            {
              (() => {
                if (doc) {
                  const values = doc.fileName.split(".")
                  const extension = values[values.length - 1]
                  if (extension === "pdf") {
                    return <PDFViewerComp file={doc.file} width={"100%"} height={399} />
                  }
                  return <Image src={doc?.file} alt={doc?.name} />
                }
              })()
            }
          </Modal>
        </Col>
      </Row>
    </MainLayout>
  );
};

export { RemboursementDetail };
