import React, { useEffect, useState } from "react";
import { DataTable } from '../Components/DataTable';
import { Avatar, Button, Card, Form, Input, Modal, Table, theme } from 'antd';
import { numberFormatter } from '../AdminLayout';
import { DrawTag } from '../Components/DrawTag';
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useActionContext } from '../../../context/ActionContext';
import moment from "moment";
import { t } from "i18next";
import { useAuthContext } from "../../../context/AuthContext";
import { updateAssociation } from "../../../functions";
import { useNotificationContext } from "../../../context/NotificationContext";

const ActionsList = props => {
    const [form] = Form.useForm();
    const { token } = theme.useToken()
    const { pendingAssociations, associations, setAssociations } = useActionContext()
    const { accessToken } = useAuthContext()
    const { setSuccess, setError } = useNotificationContext()
    const [list, setList] = useState([]);
    const [open, openModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalData, setModalData] = useState(null);

    useEffect(() => {
        const __associations = [];
        if (associations.length) {
            // const filtered = associations.filter(a => a.userId?.toString() === props.userId?.toString())
            // for (const asso of filtered) {
            for (const asso of associations) {
                let price = 0;
                const invsts = asso.investments.filter(i => i.userId.toString() === props.userId.toString())
                for (const i of invsts) {
                    price += i.amount;
                }
                asso.price = price
                asso.investments = invsts
                if (asso.price > 0) {
                    __associations.push(asso);
                }
            }
            setList(__associations);
        }
    }, [associations]);
    const statusList = [
        {
            value: 1,
            label: "Terminé",
            color: token.colorPrimary,
        },
        {
            value: 0,
            label: "En cours",
            color: token.colorSuccess,
        },
        // {
        //     value: -1,
        //     label: "Refusé",
        //     color: token.colorErrorText,
        // },
    ]
    const setOpenModal = (flag, row) => {
        openModal(flag);
        setModalData([row]);
    };

    const updateStatus = async (id, status) => {
        setLoading(true)
        const values = form.getFieldsValue()
        const response = await updateAssociation(accessToken, { ...values, id: id, status: status })
        const notif = { message: response.success ? "Successful!" : "Unsuccessful", description: response.message }

        if (response.success) {
            const list = [...associations]
            const index = list.findIndex(a => a.id.toString() === id.toString())
            list.splice(index, 1, response.association)

            setAssociations([...list])
            setSuccess(notif)
            setOpenModal(false, null)
        } else {
            setError(notif)
        }
        setLoading(false)
    }

    return (
        <div>
            <DataTable
                alterBg
                statusList={statusList}
                dataSource={list}
                columns={[
                    {
                        title: "Causes",
                        key: "name",
                        dataIndex: "name",
                        render: (value, row) => {
                            return (
                                <Card.Meta
                                    className="table-meta-cell"
                                    avatar={
                                        <Avatar size={40} src={row.logo} />
                                    }
                                    title={<span>{value}</span>}
                                    description={row.url}
                                />
                            );
                        },
                    },
                    {
                        key: "status",
                        dataIndex: "status",
                        title: "Statuts",
                        width: 114,
                        render: (status, row) => {
                            const filtered = statusList.filter(s => s.value === status)
                            return <DrawTag dots value={filtered[0]?.label} />;
                        },
                    },
                    {
                        key: "price",
                        dataIndex: "price",
                        title: "Montants totaux investis",
                        render: (price) => {
                            return numberFormatter(price);
                        },
                    },
                    {
                        key: "status",
                        dataIndex: "status",
                        title: "",
                        render: (status, row) => {
                            return status === 1 ? (
                                <Button
                                    disabled
                                    icon={
                                        <CheckOutlined
                                            style={{ color: "#039855" }}
                                        />
                                    }
                                />
                            ) : status === -1 ? <Button
                                disabled
                                icon={
                                    <CloseOutlined
                                        style={{ color: token.colorErrorText }}
                                    />
                                }
                            /> : (
                                <>
                                    <Button
                                        type="text"
                                        icon={<CheckOutlined />}
                                        onClick={() => updateStatus(row.id, 1)}
                                    />
                                    {/* <Button
                                        onClick={() => setOpenModal(true, row)}
                                        type="text"
                                        danger
                                        icon={<CloseOutlined />}
                                    /> */}
                                </>
                            );
                        },
                    },
                ]}
            />
            <Modal
                className="uploadImage"
                width={680}
                open={open}
                onCancel={() => {
                    openModal(false);
                    setModalData(null);
                }}
                okText={t("ADD")}
                cancelText={"Refuser"}
                title={"Nouvelle action RSE"}
                cancelButtonProps={{ block: true, size: "large" }}
                okButtonProps={{
                    block: true, size: "large", loading: loading, disabled: loading, onClick: () => updateStatus(modalData[0]?.id, -1)
                }}
            >
                <Form form={form} layout="vertical" size="large">
                    {(values) => (
                        <>
                            {modalData?.length ? (
                                <Table
                                    pagination={false}
                                    style={{ marginBottom: 20 }}
                                    columns={[
                                        {
                                            key: "name",
                                            dataIndex: "name",
                                            title: "Association",
                                        },
                                        {
                                            key: "createdAt",
                                            dataIndex: "createdAt",
                                            title: "Date",
                                            render: (value) => {
                                                return value ? moment(value).format("DD/MM/YYYY") : null;
                                            },
                                        },
                                        {
                                            key: "price",
                                            dataIndex: "price",
                                            title: "Montant",
                                            render: (price) => {
                                                return numberFormatter(price);
                                            },
                                        },
                                    ]}
                                    dataSource={modalData}
                                />
                            ) : null}
                            <Form.Item name={"reason"} label="Motif du refus">
                                <Input placeholder="Texte" />
                            </Form.Item>
                        </>
                    )}
                </Form>
            </Modal>
        </div>
    )
}

export default ActionsList
