import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuthContext } from "../AuthContext";
import axios from "axios";
import { useNotificationContext } from "../NotificationContext";

export const AdminContext = createContext();


export const AdminProvider = ({ children, navigation }) => {
    const { setSuccess, setError } = useNotificationContext()
    const { user, accessToken } = useAuthContext()

    const [title, setTitle] = useState("Dashboard");
    const [users, setUsers] = useState([]);
    const [distributors, setDistributors] = useState([]);
    const [userToEdit, setUserToEdit] = useState(null);

    useEffect(() => {
        if (user && distributors.length <= 0) {
            (async () => await getDistributors(accessToken ?? user.accessToken))();
        }
    }, [user])

    const setAdminTitle = title => {
        setTitle(title)
    }

    const selectUser = user => {
        setUserToEdit(user)
    }

    const getUsers = async accessToken => {
        const config = {
            "method": "GET",
            "url": "/user/list/get",
            "headers": {
                "x-access-token": accessToken,
            }
        }

        const response = await axios.request(config)
        if (response.data?.users) {
            setUsers(response.data.users)
        }
    }
    const getDistributors = async accessToken => {
        const config = {
            "method": "GET",
            "url": "/distributor",
            "headers": {
                "x-access-token": accessToken,
            }
        }

        const response = await axios.request(config)
        if (response.data?.distributors) {
            setDistributors(response.data.distributors)
        }
    }
    const addDistributor = async (values, accessToken) => {
        const config = {
            "method": "POST",
            "url": "/distributor",
            "headers": {
                "x-access-token": accessToken,
            },
            "data": values
        }
        const response = await axios.request(config)
        if (response.data?.distributor) {
            setDistributors(prev => [...prev, response.data.distributor])
        }
        const notif = { message: response.data.message, description: "" }
        if (response.data.success) {
            setSuccess(notif)
        } else {
            setError(notif)
        }
    }
    const addInvoice = async data => {
        const formData = new FormData()
        for (const key in data) {
            const value = data[key];
            if (key === "file") {
                formData.append("invoice", value)
            } else if (key === "devices") {
                formData.append(key, JSON.stringify(value))
            } else {
                formData.append(key, value ?? '')
            }
        }
        const config = {
            "method": data.id ? "PUT" : "POST",
            "url": "/project/invoice",
            "headers": {
                "content-type": "multipart/form-data",
                "x-access-token": accessToken ?? user.accessToken,
            },
            "data": formData
        }
        const response = await axios.request(config)

        const notif = { message: response.data.message, description: "" }
        if (response.data.success) {
            setSuccess(notif)
            return response.data.invoice
        } else {
            setError(notif)
            return false
        }

    }

    const updateInvoiceRefund = async data => {
        const config = {
            "method": "PUT",
            "url": "/project/invoice/refund",
            "headers": {
                "x-access-token": accessToken ?? user.accessToken,
            },
            "data": data
        }
        const response = await axios.request(config)
        const notif = { message: response.data.message, description: "" }
        if (response.data.success) {
            setSuccess({...notif, message: "Remboursement enregistré"})
            return response.data.invoice
        }
        setError(notif)
        return false
    }

    return (
        <AdminContext.Provider value={{ "adminTitle": title, distributors, setAdminTitle, users, getUsers, setUsers, userToEdit, selectUser, addDistributor, addInvoice, updateInvoiceRefund }}>
            {children}
        </AdminContext.Provider>
    );
};

export const useAdminContext = () => useContext(AdminContext)