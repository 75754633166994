import React, { useContext, useState, } from "react";
import { Form, Input, Button, Typography, Checkbox, Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import "../css/style.css";
import axios from "axios";


const { Title } = Typography;

const LoginPage = () => {

  const [form] = Form.useForm();
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const { user, login, logout } = useContext(AuthContext);
  const [error, setError] = useState(null);

  const handleLogin = async (values) => {
    setLoading(true);
    try {
      const config = {
        method: "POST",
        url: "/auth/login",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          email: values.username,
          password: values.password,
        },
      };
      const response = await axios(config);
      if (response.data.success) {
        const user = response.data.data.user;
        const accessToken = response.data.data.access_token;

        login({ ...user, accessToken });

        if (user) {
          if (user.isAdmin || user.isOnboarding) {
            navigation("/", {
              replace: true,
            });
          } else {
            navigation("/profile", {
              replace: true,
            });
          }
        } else {
          logout();
        }
      } else {
        setError(response.data.message); // Set the error message in state
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (!user) {
    return (
      <div className="authpage-wrapper">
        <Form
          name="loginForm"
          initialValues={{ remember: true }}
          onFinish={handleLogin}
          layout="vertical"
          requiredMark={false}
          size="large"
        >
          <img
            alt=""
            src="/logo.png"
            style={{ height: 59, maxWidth: "100%", width: 95 }}
          />
          <Title level={2} className="text-center">
            Connectez-vous
          </Title>
          <Typography.Paragraph className="text-center">
            Content de vous revoir !
          </Typography.Paragraph>

          {error && (
            <Form.Item>
              <Alert type="error" message={error} showIcon />
            </Form.Item>
          )}

          <Form.Item
            label={"Email"}
            name="username"
            rules={[
              {
                required: true,
                message: "s'il vous plaît entrez votre nom d'utilisateur",
              },
            ]}
          >
            <Input
              placeholder="Identifiant"
            />
          </Form.Item>

          <Form.Item
            label={"Mot de passe"}
            name="password"
            rules={[
              {
                required: true,
                message: "s'il vous plait entrez votre mot de passe",
              },
            ]}
          >
            <Input.Password placeholder="Mot de passe" />
          </Form.Item>

          <Form.Item name="remember">
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "space-between",
              }}
            >
              <span>
                <Checkbox
                //  checked={rememberMe}
                //  onChange={(e) => setRememberMe(e.target.checked)}
                >

                  Se souvenir de moi

                </Checkbox>
              </span>
              <span style={{ marginLeft: "auto" }}>
                <Link to="/forgot-password">
                  <strong>Mot de passe oublié ?</strong>
                </Link>
              </span>
            </div>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={loading}>
              Se connecter
            </Button>
          </Form.Item>
          {/* <Form.Item>
            <Button
              htmlType="button"
              block
              loading={loading}
              className="center-btn"
              icon={<GoogleIcon width={24} height={24} />}
            >
              Se connecter avec Google
            </Button>
          </Form.Item> */}

          <Form.Item>
            <div style={{ textAlign: "center" }}>
              Pas encore inscrit ?{" "}
              <Link to="/signup">
                <strong>S’inscrire</strong>
              </Link>
            </div>
          </Form.Item>
        </Form>
      </div>
    );
  }
};

export default LoginPage;
