// Conversion factors
export const conversionFactors = {
    mo: 1 / 1000,    // 1 MO = 0.001 GO
    go: 1,            // 1 GO = 1 GO
    to: 1000,         // 1 TO = 1000 GO
};

export const domains = {
    live: "http://app.sience.fr",
    test: "http://localhost:5000"
}