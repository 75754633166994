import React from "react";
import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { ReactComponent as KeyIcon } from "../images/success-check.svg";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "../css/style.css";

const ResetPasswordSuccess = () => {
  const navigate = useNavigate();
  return (
    <div className="authpage-wrapper">
      <Result
        style={{
          width: 380,
          paddingInline: 0,
          fontSize: 14,
        }}
        icon={<KeyIcon width={66} height={66} />}
        status="success"
        title="Mot de passe réinitialisé"
        subTitle={
          <p style={{ fontSize: 16, color: "#475467" }}>
            Votre mot de passe a été réinitialisé avec succès. Cliquez
            ci-dessous pour vous connecter comme par magie.
          </p>
        }
        extra={[
          <Button
            type="primary"
            size="large"
            block
            onClick={() => navigate("/login")}
            key="resend-link"
          >
            <strong>Continuer</strong>
          </Button>,
          <br />,
          <br />,
          <Button
            size="large"
            block
            icon={<ArrowLeftOutlined />}
            htmlType="button"
            type="link"
            onClick={() => navigate("/login")}
            className="text-mute"
          >
            Retour
          </Button>,
        ]}
      />
    </div>
  );
};

export default ResetPasswordSuccess;
