import axios from "axios";

// const getDate = date => new Date(date)
const getDate = date => {
    const d = date ? new Date(date) : new Date()
    const inMinutes = d.getTimezoneOffset()
    const inHours = inMinutes / 60
    const h = inHours >= 0 ? d.getHours() + inHours : d.getHours() - inHours
    d.setHours(h)
    return d
}
export const createProjectTitle = () => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    // First semester: 1st December to 31st May
    const first = {
        start: getDate(`12/01/${year}`),
        end: getDate(`05/31/${year + 1}`)
    }

    // Second semester: 1st June to 30th November
    const second = {
        start: getDate(`06/01/${year}`),
        end: getDate(`11/30/${year}`)
    }

    let p = {}

    // Check if the current date falls in the first semester (1st Dec - 31st May)
    if (currentDate >= first.start && currentDate <= first.end) {
        p.title = `Projet semestre #1, ${year}`;
        p.startDate = first.start;
        p.endDate = first.end;
    }
    // Check if the current date falls in the second semester (1st June - 30th Nov)
    else if (currentDate >= second.start && currentDate <= second.end) {
        p.title = `Projet semestre #2, ${year}`;
        p.startDate = second.start;
        p.endDate = second.end;
    }

    return p
};

export const getUser = async (accessToken) => {
    try {
        const config = {
            method: "GET",
            url: "/auth/user",
            headers: {
                "x-access-token": accessToken,
            },
        };
        const response = await axios(config);
        if (response.data.success) {
            return response.data.user;
        }
        return {};
    } catch (e) {
        console.log(e.message);
    }
};

export const handleDownload = async (ids, accessToken) => {
    const config = {
        method: "GET",
        url: `/project/download?ids=${ids.join()}`,
        headers: {
            "Content-Type": "application/zip",
            "x-access-token": accessToken,
        },
        responseType: "blob",
    };
    const response = await axios(config);
    const blob = new Blob([response.data], { type: "application/zip" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = "invoices.zip";
    a.click();
}

export const getDocs = async (userId, accessToken, setError) => {
    const config = {
        "method": "GET",
        "url": `/document`,
        "headers": {
            "x-access-token": accessToken
        }
    }
    config.url += userId ? `?userId=${userId}` : ``
    const response = await axios.request(config)
    const notif = { message: response.data.message, description: "" }
    if (response.data.success) {
        return response.data.documents
    } else {
        setError(notif)
        return false
    }
}

export const getProjects = async (accessToken) => {
    try {
        const config = {
            method: "GET",
            url: "/project/get",
            headers: {
                "x-access-token": accessToken,
            },
        };
        const response = await axios(config);
        if (response.data.projects?.length) {
            return response.data.projects;
        }
        return [];
    } catch (e) {
        console.log(e.message);
    }
};

export const getAssociations = async (accessToken) => {
    try {
        const config = {
            method: "GET",
            url: "/actions/asociations",
            headers: {
                "x-access-token": accessToken,
            },
        };
        const response = await axios(config);
        return response.data
    } catch (e) {
        console.log(e.message);
    }
};

export const createAssociation = async (accessToken, data) => {
    try {
        const config = {
            method: "POST",
            url: "/actions/association",
            data,
            headers: {
                "x-access-token": accessToken,
            },
        };
        const response = await axios(config);
        return response.data.association ?? {}
    } catch (e) {
        console.log(e.message);
    }
};

export const setErpRead = async (id, accessToken) => {
    try {
        const config = {
            method: "GET",
            url: `/enterprises/read/${id}`,
            headers: {
                "x-access-token": accessToken,
            },
        };
        const response = await axios(config);
        return response.data.company
    } catch (e) {
        console.log(e.message);
        return { message: e.message }
    }
};

export const setInvRead = async (id, accessToken) => {
    try {
        const config = {
            method: "GET",
            url: `/project/invoice/read/${id}`,
            headers: {
                "x-access-token": accessToken,
            },
        };
        const response = await axios(config);
        return response.data.invoice
    } catch (e) {
        console.log(e.message);
        return { message: e.message }
    }
};

export const getCompany = async accessToken => {
    try {
        const config = {
            method: "GET",
            url: "/profile/company",
            headers: {
                "x-access-token": accessToken,
            },
        };
        const response = await axios(config);
        return response.data
    } catch (e) {
        console.log(e.message);
        return { message: e.message }
    }
};

export const saveInvestment = async (accessToken, data) => {
    try {
        const config = {
            method: "POST",
            url: "/actions/investment",
            data,
            headers: {
                "x-access-token": accessToken,
            },
        };
        const response = await axios(config);
        return response.data
    } catch (e) {
        console.log(e.message);
    }
};

export const updateAssociation = async (accessToken, data) => {
    try {
        const config = {
            method: "PUT",
            url: "/actions/association-update",
            data,
            headers: {
                "x-access-token": accessToken,
            },
        };
        const response = await axios(config);
        return response.data
    } catch (e) {
        return { message: e.message };
    }
};

export const formatDate = (d) => {
    const dt = new Date(d);
    const day = dt.getDate();
    const month = dt.getMonth() + 1;
    const year = dt.getFullYear();

    return `${day}/${month}/${year}`;
};

export const normalize = val => val?.toLowerCase()?.replace(/\s+/g, "")

export const validatePassword = (_, value, callback) => {
    if (!value?.trim()) {
        callback("Veuillez entrer un nouveau mot de passe.");
    } else if (value.length < 6) {
        callback("Le mot de passe doit contenir au moins 6 caractères");
    }
    else {
        callback();
    }
};

export const getDateInFrench = date => {
    const targetTimeZone = 'Europe/Paris'; // Eastern Time Zone
    const options = {
        timeZone: targetTimeZone,
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
        // hour: 'numeric',
        // minute: 'numeric',
        // second: 'numeric',
    };

    const dateInTargetTimeZone = new Intl.DateTimeFormat('fr-FR', options).format(new Date());
    return dateInTargetTimeZone
}
export const getOffsetDate = date => {
    const d = date ? new Date(date) : new Date()
    const inMinutes = d.getTimezoneOffset()
    const inHours = inMinutes / 60
    const h = inHours >= 0 ? d.getHours() + inHours : d.getHours() - inHours
    d.setHours(h)
    return d
}

export const convertToGO = (capacity, unit) => {
    // Define conversion factors
    const conversions = {
        mo: 0.001,      // 1 MO = 0.001 GO
        go: 1,
        to: 1000,       // 1 TO = 1000 GO
    };

    // Check if the unit is valid
    if (conversions.hasOwnProperty(unit)) {
        return capacity * conversions[unit];
    } else {
        // Handle the case where the unit is not recognized
        console.log("Invalid unit.");
        return null; // or any other appropriate error handling
    }
}

// globalSearchFunction
export const searchInDataTable = (keyword, data, filter) => data?.filter(item => item[filter].toLowerCase().includes(keyword.toLowerCase()))

const verifySiret = (siret, user, setSiret, setVerifying) => {

    const config = {
        method: "GET",
        url: `/profile/siret/${siret}`,
        headers: {
            "x-access-token": user.accessToken,
        },
    };
    (async () => {
        const response = await axios(config);
        const header = response.data.header

        const verifiedSiret = {
            verified: header.statut === 200,
            message: header.message,
        }

        if (verifiedSiret.verified) {
            const { uniteLegale, adresseEtablissement } = response.data.etablissement
            let street = adresseEtablissement.numeroVoieEtablissement + " " + adresseEtablissement.typeVoieEtablissement + " " + adresseEtablissement.libelleVoieEtablissement
            let postal = adresseEtablissement.codePostalEtablissement ? ` ${adresseEtablissement.codePostalEtablissement}` : ""
            let city = adresseEtablissement?.libelleCommuneEtablissement ? `, ${adresseEtablissement?.libelleCommuneEtablissement}` : ""
            let address = street + city + postal
            verifiedSiret.company = {
                name: uniteLegale.denominationUniteLegale,
                ape: uniteLegale.activitePrincipaleUniteLegale,
                lastName: uniteLegale.nomUniteLegale,
                firstname: uniteLegale.prenomUsuelUniteLegale,
                address,
                // address: adresseEtablissement.numeroVoieEtablissement + " " + adresseEtablissement.typeVoieEtablissement + " " + adresseEtablissement.libelleVoieEtablissement,
                postalCode: postal,
                city: city,
            }
        }
        console.log("verifiedSiret.company", verifiedSiret.company);

        setSiret({ ...verifiedSiret })
        setVerifying(false)
    })();
}

const debounce = (func, delay) => {
    let timer;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(timer);
        timer = setTimeout(() => {
            args[args.length - 1](true) // ===>> setVerifying(true)
            func.apply(context, args);
        }, delay);
    };
}

export const debounceFunction = debounce(verifySiret, 1000); // 300ms delay


export const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });