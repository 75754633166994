import React, { useState } from 'react'
import moment from "moment";
import { DataTable } from '../Components/DataTable';
import { Button, Col, InputNumber, Row, Space, Tooltip, theme } from 'antd';
import { numberFormatter } from '../AdminLayout';
import { DrawTag } from '../Components/DrawTag';
import { Link } from "react-router-dom";
import { EditOutlined, EyeOutlined, LoadingOutlined, CloseOutlined } from "@ant-design/icons";
import { useAdminContext } from '../../../context/admin/AdminContext';
import { useEnterprisesContext } from '../../../context/admin/EnterprisesContext';
import { useProjectContext } from '../../../context/ProjectContext';
import { setInvRead } from '../../../functions';
import { useAuthContext } from '../../../context/AuthContext';

const EncorsProjects = props => {
    const { token } = theme.useToken()

    const { updateInvoiceRefund } = useAdminContext()
    const { enterprise, selectEnterprise, enterprises, setEnterprises } = useEnterprisesContext()
    const { projects, storeProjects, setProjects } = useProjectContext()
    const { accessToken } = useAuthContext()

    const [reimbursed, setReimbursed] = useState({})
    const [loading, setLoading] = useState({})
    const [showInput, setShowInput] = useState({})

    const handleChange = ({ key, value }) => {
        setReimbursed(prev => ({ ...prev, [key]: value }));
    }
    const handleSave = async (id, companyId, projectId) => {
        if (reimbursed[id]) {
            setLoading(true)
            const invoice = await updateInvoiceRefund({ id, projectId, refund: reimbursed[id] })

            if (invoice) {
                const projectslist = [...projects]
                for (const p of projectslist) {
                    if (p.id.toString() === projectId) {
                        p.status = 1
                        p.price = reimbursed[id]
                        const invcsList = [...p.invoices]
                        for (const item of invcsList) {
                            if (item.id.toString() === id.toString()) {
                                item.refund = reimbursed[id]
                            }
                        }
                    }
                }
                storeProjects([...projectslist])

                const list = [...enterprises]
                for (const e of list) {
                    if (e.id === companyId) {
                        for (const inv of e.invoices) {
                            if (inv.id === id) {
                                inv.refund = reimbursed[id]
                            }
                        }
                    }
                }
                setEnterprises([...list])

                const selected = { ...enterprise }
                const invcs = [...selected.invoices]
                for (const invc of invcs) {
                    if (invc.id.toString() === id.toString()) {
                        invc.refund = reimbursed[id]
                    }
                }
                selected.invoices = [...invcs]
                selectEnterprise({ ...selected })

                setLoading(false)
                setShowInput(prev => ({ ...prev, [id]: false }))
            }
        }
    }

    return (
        <div>
            <DataTable
                statusList={
                    [
                        {
                            value: "Paid",
                            label: "Remboursé",
                            color: token.colorSuccess,
                        },
                        {
                            value: "In Progress",
                            label: "En cours de traitement",
                            color: token.colorWarning,
                        },
                    ]
                }
                dataSource={[...props.invoices]}
                columns={[
                    {
                        title: "Factures",
                        key: "title",
                        dataIndex: "title",
                    },
                    {
                        key: "submitDate",
                        dataIndex: "submitDate",
                        title: "Date de la facture",
                        render: (value) => {
                            return value ? moment(value).format("DD/MM/YYYY") : null;
                        },
                    },
                    {
                        key: "supplier",
                        dataIndex: "supplier",
                        title: "Fournisseurs",
                    },
                    {
                        key: "estimated",
                        dataIndex: "estimated",
                        title: "Montants estimatifs",
                        render: (estimated) => {
                            return estimated !== null
                                ? numberFormatter(estimated)
                                : null;
                        },
                    },
                    {
                        key: "price",
                        dataIndex: "price",
                        title: "Montant CF affiché",
                        render: (price) => {
                            return price ? numberFormatter(price) : null;
                        },
                    },
                    {
                        key: "matching",
                        dataIndex: "matching",
                        title: "Matching",
                        render: (value, row) => {
                            const isMatching = (row.price && row.estimated && row.price === row.estimated) ? "Yes" : "No"
                            return <DrawTag dots value={isMatching} />
                        },
                    },
                    {
                        key: "refund",
                        dataIndex: "refund",
                        title: "Montant Remboursé",
                        render: (refund, row) => {
                            return refund ? (
                                numberFormatter(refund)
                            ) : (
                                showInput[row.id] ? <Row gutter={4}>
                                    <Col span={23}>
                                        <Tooltip title="Entrez un remboursement" color={"#000"} key={"#000"}>
                                            <InputNumber
                                                autoFocus
                                                placeholder="Entrez un remboursement"
                                                controls={false}
                                                style={{ width: "100%" }}
                                                suffix={loading[row.id] ? <LoadingOutlined /> : null}
                                                value={reimbursed[row.id]}
                                                onChange={value => handleChange({ key: [row.id], value })}
                                                // onBlur={() => handleSave(row.id, row.companyId)}
                                                onPressEnter={() => handleSave(row.id, row.companyId, row.projectId)}
                                            />
                                        </Tooltip>

                                    </Col>
                                    <Col span={1}>
                                        <CloseOutlined onClick={() => setShowInput(prev => ({ ...prev, [row.id]: false }))} />
                                    </Col>
                                </Row> : <Button
                                    type="link"
                                    style={{ padding: 0 }}
                                    onClick={() => setShowInput(prev => ({ ...prev, [row.id]: true }))}
                                >
                                    <u style={{ fontWeight: "normal" }}>Ajouter</u>
                                </Button>
                            );
                        },
                    },
                    {
                        key: "id",
                        dataIndex: "id",
                        title: "",
                        width: 125,
                        render: (id, row) => {
                            return (
                                <Space size={"large"}>
                                    <Link
                                        to={"/invoice"}
                                        state={{ ...props.invoiceInfo, viewOnly: true, selectedInvoice: row }}
                                        onClick={async () => {
                                            if (!row.read) {
                                                const invoice = await setInvRead(id, accessToken)
                                                if (Object.hasOwnProperty.call(invoice, "id")) {
                                                    setProjects(projects => {
                                                        for (const p of projects) {
                                                            if (p.id.toString() === invoice.projectId.toString()) {
                                                                const i = p.invoices.findIndex(inv => inv.id.toString() === invoice.id.toString())
                                                                p.invoices.splice(i, 1, invoice)
                                                            }
                                                        }
                                                        return projects
                                                    })
                                                }
                                            }
                                        }}
                                    >
                                        <EyeOutlined size={20} />
                                    </Link>
                                    <Link
                                        to={"/invoice"}
                                        state={{ ...props.invoiceInfo, selectedInvoice: row }}
                                        onClick={async () => {
                                            if (!row.read) {
                                                const invoice = await setInvRead(id, accessToken)
                                                if (Object.hasOwnProperty.call(invoice, "id")) {
                                                    setProjects(projects => {
                                                        for (const p of projects) {
                                                            if (p.id.toString() === invoice.projectId.toString()) {
                                                                const i = p.invoices.findIndex(inv => inv.id.toString() === invoice.id.toString())
                                                                p.invoices.splice(i, 1, invoice)
                                                            }
                                                        }
                                                        return projects
                                                    })
                                                }
                                            }
                                        }}
                                    >
                                        <EditOutlined size={20} />
                                    </Link>
                                </Space>
                            );
                        },
                    },
                ]}
            />
        </div >
    )
}

export default EncorsProjects
