import React, { useEffect, useState } from 'react';
import { BankOutlined, FileTextOutlined, HomeOutlined, PlusOutlined, ProfileOutlined, SearchOutlined, SettingOutlined, UserOutlined, UserSwitchOutlined, } from '@ant-design/icons';
import { Avatar, Breadcrumb, Button, Card, Col, Form, Input, Layout, Menu, Row, Typography, theme } from 'antd';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useAdminContext } from '../../../context/admin/AdminContext';
import { ReactComponent as SettingsIcons } from "../../../images/settings.svg";
import { ReactComponent as DashboardIcon } from "../../../images/dashboard.svg";
import { ReactComponent as UsersIcon } from "../../../images/users.svg";
import { ReactComponent as LayersIcon } from "../../../images/layers-three.svg";
import { ReactComponent as LogoutIcon } from "../../../images/logout.svg";
import { useAuthContext } from '../../../context/AuthContext';
import axios from 'axios';
import { useTranslation } from "react-i18next";

const { Header, Content, Footer, Sider } = Layout;
const getItem = (label, key, icon, children) => ({ label, key, icon, children })

export const numberFormatter = (number, format) => {
    const formatter = new Intl.NumberFormat("fr-FR", {
        minimumFractionDigits: format ? format : 0,
        style: "currency",
        currency: "EUR",
        currencyDisplay: "symbol",
    });
    if (number === 0) {
        return 0;
    } else {
        return formatter.format(number);
    }
};

const AdminLayout = props => {
    const { t } = useTranslation();
    const location = useLocation();
    const { user, accessToken, logout } = useAuthContext()
    const navigation = useNavigate();
    const { adminTitle, setAdminTitle, getUsers } = useAdminContext()
    const { token } = theme.useToken();

    const [currentPage, setCurrentPage] = useState("");

    useEffect(() => {
        setCurrentPage(location.pathname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (user) {
            getUsers(accessToken ?? user?.accessToken)
        }
    }, [user])

    const handleTitle = e => {
        const key = e.key
        const keyPath = e.keyPath
        const keys = keyPath.map(k => k.charAt(0).toUpperCase() + k.slice(1))
        const title = keys.join(" ")
        setAdminTitle(title)
    }

    const onLogout = async () => {
        try {
            // Send the data to the backend
            const config = {
                method: "POST",
                url: "/auth/logout",
                data: {
                    userId: user.id
                }
            };
            const response = await axios(config);
            if (response.data.message) {

                logout(() => {
                    navigation("/login", { replace: true });
                    window.location.reload()
                });
            } else {
                console.log(response.data.error);
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    const pathTo = path => `/${path ?? ''}`

    const items = [
        getItem(<Link to={pathTo("dashboard")}>{t("DASHBOARD")}</Link>, "dashboard", <DashboardIcon width={24} height={24} />),
        getItem(<Link to={pathTo("enterprises")}>{t("BUSINESSES")}</Link>, "enterprises", <LayersIcon width={24} height={24} />),
        // getItem(<Link to={pathTo("remboursements")}>temp</Link>, "remboursements", <LayersIcon width={24} height={24} />),
        getItem(<Link to={pathTo("associations")}>{t("ASSOCIATIONS")}</Link>, "associations", <LayersIcon width={24} height={24} />),
        getItem(<Link to={pathTo("distributers")}>{t("DISTRIBUTERS")}</Link>, "distributers", <LayersIcon width={24} height={24} />),
        // getItem(<Link to={pathTo("investments")}>{t("INVESTMENT")}</Link>, "investments", <LayersIcon width={24} height={24} />),
        getItem('Users', 'users', <UsersIcon width={14} height={14} />, [
            getItem(<Link to={pathTo("users")}>All users</Link>, 'all', <UserOutlined style={{ width: 24, height: 24 }} />),
            getItem(<Link to={pathTo("users/new")}>Add user</Link>, 'add', <PlusOutlined style={{ width: 24, height: 24 }} />),
            // getItem(<Link to={pathTo("profile")}>Profile</Link>, 'profile', <ProfileOutlined />),
        ]),
        // getItem(<Link to={pathTo("projects")}>Projects</Link>, "projects", <LayersIcon width={14} height={14} />,),
        // getItem(<Link to={pathTo("invoices")}>Invoices</Link>, "invoices", <FileTextOutlined />),
        // getItem(<Link to={pathTo("associations")}>Associations</Link>, "associations", <BankOutlined />),
        // getItem(<Link to={pathTo("investments")}>Investments</Link>, "investments", <FileTextOutlined />),
        // getItem(<Link to={pathTo("settings")}>Settings</Link>, "settings", <SettingsIcons width={14} height={14} />),
    ];

    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    return (
        <Layout
            style={{
                minHeight: '100vh',
            }}
        >
            <Sider
                theme='light'
                width={253}
                style={{
                    overflow: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    bottom: 0,
                }}
            // collapsible
            // collapsed={collapsed}
            // onCollapse={(value) => setCollapsed(value)}
            >
                <div className="sider-logo">
                    <img width={70} src="/logo.png" alt="" />
                </div>
                {/* <div style={{ padding: "0 10px 16px" }}>
                    <Input
                        size="large"
                        prefix={<SearchOutlined />}
                        placeholder="Recherche"
                    />
                </div> */}
                <Menu style={{ height: "85%" }} theme="light" mode="inline" defaultSelectedKeys={['dashboard']} items={items} onClick={handleTitle} />
                <div
                    style={{
                        // position: "absolute",
                        width: "253px",
                        position: "fixed",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: token.colorBgBase,
                        height: "15%"
                    }}
                >
                    <Menu
                        // defaultSelectedKeys={[currentPage]}
                        mode="vertical"
                        items={[
                            getItem(
                                <Link to={pathTo("settings")}>{t("SETTINGS")}</Link>,
                                "settings",
                                <SettingsIcons width={24} height={24} />
                            ),
                        ]}
                    />
                    <div className="menu-user-area">
                        <Card.Meta
                            className="meta-settings"
                            // avatar={
                            //     <Avatar
                            //         size={40}
                            //         src={user?.avatarUrl ?? "https://xsgames.co/randomusers/avatar.php?g=pixel"}
                            //     />
                            // }
                            title={user?.username ?? ""}
                            description={user?.email ?? ""}
                        />
                        <Button
                            type="link"
                            className="logout-button"
                            onClick={onLogout}
                        >
                            <LogoutIcon width={15} height={15} />
                        </Button>
                    </div>
                </div>
            </Sider>

            {/* <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                <div className="demo-logo-vertical" >Tahir</div>
                <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} />
            </Sider> */}
            <Layout
                // className="site-layout"
                style={{
                    marginLeft: 253,
                }}
            >
                <Content
                    className='main-body'
                    style={{
                        // margin: '24px 16px 0',
                        overflow: 'initial',
                    }}
                >
                    {/* <div
                        style={{
                            padding: 24,
                            minHeight: "100%",
                            // background: colorBgContainer,
                        }}
                    >
                    </div> */}
                    <Outlet />
                </Content>
            </Layout>
        </Layout >
    );
};
export default AdminLayout;