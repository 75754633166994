import React from 'react'
import moment from "moment";
import { DataTable } from '../Components/DataTable';
import { Button, theme } from 'antd';
import { numberFormatter } from '../AdminLayout';
import { DrawTag } from '../Components/DrawTag';
import { EyeOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';

const PassProjects = props => {
    const { token } = theme.useToken()
    return (
        <div>
            <DataTable
                statusList={[
                    {
                        value: "Paid",
                        label: "Remboursé",
                        color: token.colorSuccess,
                    },
                    {
                        value: "In Progress",
                        label: "En cours de traitement",
                        color: token.colorWarning,
                    },
                ]}
                dataSource={props.invoices}
                columns={[
                    {
                        title: "Factures",
                        key: "title",
                        dataIndex: "title",
                    },
                    {
                        key: "submitDate",
                        dataIndex: "submitDate",
                        title: "Date de la facture",
                        render: (value) => {
                            return value ? moment(value).format("DD/MM/YYYY") : null;
                        },
                    },
                    {
                        key: "supplier",
                        dataIndex: "supplier",
                        title: "Fournisseurs",
                    },
                    {
                        key: "estimated",
                        dataIndex: "estimated",
                        title: "Montants estimatifs",
                        render: (estimated) => {
                            return estimated !== null
                                ? numberFormatter(estimated)
                                : null;
                        },
                    },
                    {
                        key: "price",
                        dataIndex: "price",
                        title: "Montant CF affiché",
                        render: (price) => {
                            return price ? numberFormatter(price) : null;
                        },
                    },
                    {
                        key: "matching",
                        dataIndex: "matching",
                        title: "Matching",
                        render: (value, row) => {
                            const isMatching = (row.price !== null && row.refund !== null && row.price === row.refund) ? "Yes" : "No"
                            return <DrawTag dots value={isMatching} />
                        },
                    },
                    {
                        key: "refund",
                        dataIndex: "refund",
                        title: "Montant Remboursé",
                        render: (price) => {
                            return price ? (
                                numberFormatter(price)
                            ) : null
                        },
                    },
                    {
                        key: "id",
                        dataIndex: "id",
                        title: "",
                        width: 124,
                        render: (id, row) => {
                            return (
                                <>
                                    <Link to={"/invoice"}
                                        state={{ ...props.invoiceInfo, viewOnly: true, selectedInvoice: row }}
                                    >
                                        <EyeOutlined size={20} />
                                    </Link>
                                </>
                            );
                        },
                    },
                ]}
            />
        </div>
    )
}

export default PassProjects
