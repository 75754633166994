import React, { createContext, useContext, useEffect, useState } from "react";
import { notification } from 'antd';
import { ExclamationCircleOutlined, CheckCircleOutlined, WarningOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { themeToken } from "../App";

export const NotificationContext = createContext();

export const NotificationProvider = ({ children, navigation }) => {
    const [api, contextHolder] = notification.useNotification();

    const setError = ({ message = "", description = "", placement = "bottom" }) => api.error({
        message, description, placement, duration: 3, icon: (
            <ExclamationCircleOutlined
                style={{
                    color: '#FF4D4F',
                }}
            />
        ),
    });
    const setSuccess = ({ message = "", description = "", placement = "bottom" }) => api.success({
        message, description, placement, duration: 3, icon: (
            <CheckCircleOutlined
                style={{
                    color: '#52C41A',
                }}
            />
        ),
    });
    const setWrning = ({ message = "", description = "", placement = "bottom" }) => api.warning({
        message, description, placement, duration: 3, icon: (
            <WarningOutlined
                style={{
                    color: '#FAAD14',
                }}
            />
        ),
    });
    const setInfo = ({ message = "", description = "", placement = "bottom" }) => api.info({
        message, description, placement, duration: 3, icon: (
            <InfoCircleOutlined
                style={{
                    color: '#1677FF',
                }}
            />
        ),
    });


    return (
        <NotificationContext.Provider
            value={{ setError, setSuccess, setWrning, setInfo }}
        >
            {contextHolder}
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotificationContext = () => useContext(NotificationContext);
