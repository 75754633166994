import React, { useContext, useEffect, useState } from 'react'
import { Avatar, Button, Card, Col, Divider, Form, Input, Result, Row, Select, Table, Typography, Upload, theme, Alert, message, Space, Image, } from "antd";
import { ArrowLeftOutlined, DownOutlined, MailOutlined, PlusOutlined } from "@ant-design/icons";
import { ReactComponent as MapPinIcon } from "../../../../images/map-pin.svg";
import { AuthContext } from '../../../../context/AuthContext';
import { EnterprisesContext } from '../../../../context/admin/EnterprisesContext';



const Profile = () => {
    const { enterprise } = useContext(EnterprisesContext);
    const [userForm] = Form.useForm();
    const [companyForm] = Form.useForm();
    const [adminForm] = Form.useForm();
    // const [data, setData] = useState({})
    const [previewImage, setPreviewImage] = useState(null);

    useEffect(() => {
        if (enterprise) {
            // let address = enterprise?.address + (enterprise?.city ? `, ${enterprise?.city + (enterprise?.postalCode ? ` ${enterprise?.postalCode}` : "")}` : "")
            companyForm.setFieldsValue({ ...enterprise, ...enterprise?.user, ...enterprise?.admin })
            userForm.setFieldsValue({ ...enterprise?.user, })
            adminForm.setFieldsValue({ ...enterprise?.admin })
            if (enterprise?.logo) {
                setPreviewImage(enterprise?.logo);
            }
        }
    }, [enterprise])

    return (
        <Card bordered={false}>
            <Form
                name="user_form"
                form={userForm}
                labelAlign="left"
                colon={true}
                size="large"
                labelCol={{
                    md: 6,
                    xs: 12,
                }}
                // wrapperCol={{
                //     md: 12,
                //     xs: 12,
                // }}
                initialValues={{
                    about_me: "",
                }}
            >
                {/* User Start */}
                <Row>
                    <Col>
                        <Typography.Title level={4} className="heading">
                            Informations de l’utilisateur
                        </Typography.Title>
                    </Col>
                </Row>
                <Divider />
                <Form.Item label="Nom">
                    <Row gutter={24}>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name={"firstName"}
                            >
                                <Input
                                    readOnly={true}
                                    name="firstName"
                                    placeholder="Prenom"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name={"lastName"}
                            >
                                <Input
                                    readOnly={true}
                                    name="lastName"
                                    placeholder="Nom"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>
                <Divider />
                <Form.Item label="Numéro de téléphone">
                    <Space.Compact>
                        <Form.Item name={"countryCode"} readOnly={true} noStyle>
                            <Select
                                disabled
                                readOnly={true}
                                dropdownRender={null}
                                name="countryCode"
                                style={{ width: 120 }}
                                autoClearSearchValue
                            >
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name={"phone"}
                            noStyle
                        >
                            <Input
                                readOnly={true}
                                name="phone"
                                style={{ width: "calc(100% - 120px)" }}
                                placeholder="Enter phone number"
                            />
                        </Form.Item>
                    </Space.Compact>
                </Form.Item>
                <Divider />
                <Form.Item
                    name="email"
                    label="Adresse email"
                >
                    <Input
                        readOnly={true}
                        prefix={<MailOutlined />}
                        name="email"
                        placeholder="Votre email Adresse"
                    />
                </Form.Item>
            </Form>
            {/* User End */}
            <Divider />
            {/* Company Start */}
            <Form
                name="user_form"
                form={companyForm}
                labelAlign="left"
                colon={true}
                size="large"
                labelCol={{
                    md: 6,
                    xs: 12,
                }}
                // wrapperCol={{
                //     md: 12,
                //     xs: 12,
                // }}
                initialValues={{
                    about_me: "",
                }}
            >
                <Row>
                    <Col>
                        <Typography.Title level={4} className="heading">
                            Information de l’entreprise
                        </Typography.Title>
                    </Col>
                </Row>
                <Divider />
                <Form.Item
                    label={"Logo de l’entreprise"}
                >
                    <Form.Item name={"logo"}>
                        <div className="company-logo">
                            <Image src={previewImage} className="logo company-logo" alt="logo" />
                        </div>
                    </Form.Item>
                </Form.Item>
                <Form.Item label="Type de structure">
                    <Row gutter={24} style={{ alignItems: "center" }}>
                        <Col style={{ flex: 1 }}>
                            <Form.Item
                                name={"companyType"}
                                noStyle
                            >
                                <Select
                                    disabled
                                    autoClearSearchValue
                                    placeholder="Type de structure"
                                    name="companyType"
                                // options={[
                                //     {
                                //         value: "Entreprise",
                                //         label: "Entreprise",
                                //     },
                                //     {
                                //         value: "Association",
                                //         label: "Association",
                                //     },
                                //     {
                                //         value: " Administration",
                                //         label: " Administration",
                                //     },
                                // ]}
                                ></Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item
                    label="Nom de l’entreprise"
                    name={"companyName"}
                >
                    <Input readOnly={true} name="companyName" />
                </Form.Item>
                <Form.Item
                    label="Numéro de Siret"
                    name={"siretNumber"}
                >
                    <Input readOnly={true} name="siretNumber" maxLength={14} />
                </Form.Item>
                <Form.Item
                    label="Code APE"
                    name={"codeAPE"}
                >
                    <Input readOnly={true} name="codeAPE" />
                </Form.Item>
                <Form.Item
                    label="Description"
                    name={"description"}

                >
                    <Input.TextArea readOnly={true} name="description" rows={6} />
                </Form.Item>
                <Form.Item
                    label="Adresse de l’entreprise"
                    name={"address"}

                >
                    <Input readOnly={true} id="autocomplete" name="address" prefix={<MapPinIcon width={20} height={20} />} />
                </Form.Item>
            </Form>
            {/* Company End */}
            <Divider />
            {/* Admin Start */}
            <Form
                name="user_form"
                form={adminForm}
                labelAlign="left"
                colon={true}
                size="large"
                labelCol={{
                    md: 6,
                    xs: 12,
                }}
                // wrapperCol={{
                //     md: 12,
                //     xs: 12,
                // }}
                initialValues={{
                    about_me: "",
                }}
            >
                <Row>
                    <Col>
                        <Typography.Title level={4} className="heading">
                            Information du dirigeant
                        </Typography.Title>
                    </Col>
                </Row>
                <Divider />
                <Form.Item label="Nom">
                    <Row gutter={24}>
                        <Col xs={24} sm={12}>
                            <Form.Item name={"firstName"} noStyle>
                                <Input
                                    name="firstName"
                                    placeholder="Prenom"
                                    readOnly={true}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item name={"lastName"} noStyle>
                                <Input
                                    name="lastName"
                                    placeholder="Nom"
                                    readOnly={true}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item
                    name="email"
                    label="Email professionnel"
                >
                    <Input
                        name="email"
                        prefix={<MailOutlined />}
                        placeholder="Votre email Adresse"
                        readOnly={true}
                    />
                </Form.Item>
                <Form.Item label="Numéro de téléphone">
                    <Space.Compact>
                        <Form.Item name={"countryCode"} noStyle>
                            <Select disabled readOnly={true} style={{ width: 120 }} showSearch autoClearSearchValue>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name={"phone"}
                            noStyle
                        >
                            <Input
                                name="p2phone"
                                style={{ width: "calc(100% - 120px)" }}
                                placeholder="Enter phone number"
                                readOnly={true}
                            />
                        </Form.Item>
                    </Space.Compact>
                </Form.Item>
            </Form>
            {/* Admin End */}

        </Card >
    )
}

export default Profile
