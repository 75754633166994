import { useEffect } from "react";
import { useNavigate, } from "react-router-dom";

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const navigation = useNavigate();
    useEffect(() => {
        const storedUser = localStorage.getItem("user");
        if (!storedUser) {
            navigation("/login", { replace: true });
        }
    }, []);
    return <Component />;
};

export default ProtectedRoute;