import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuthContext } from "../AuthContext";
import axios from "axios";

export const EnterprisesContext = createContext();

export const EnterprisesProvider = ({ children, navigation }) => {
    const { user, accessToken } = useAuthContext()

    const [enterprises, setEnterprises] = useState([]);
    const [newERPs, setNewERPs] = useState([]);
    const [enterprise, setEnterprise] = useState(null);

    useEffect(() => {
        if (accessToken && enterprises.length <= 0) {
            (async () => await getEnterprises())();
        }
    }, [accessToken])

    useEffect(() => {
        if (enterprises.length) {
            const n = enterprises.filter(erp => !erp.read)
            setNewERPs([...n])
        }
    }, [enterprises])

    const selectEnterprise = e => {
        setEnterprise(e)
    }

    const getEnterprises = async () => {
        const config = {
            "method": "GET",
            "url": "/enterprises/list",
            "headers": {
                "x-access-token": accessToken,
            }
        }
        const response = await axios.request(config)
        if (response.data?.enterprises) {
            setEnterprises(response.data.enterprises)

        }
    }

    return (
        <EnterprisesContext.Provider value={{ enterprises, newERPs, enterprise, selectEnterprise, setEnterprises }}>
            {children}
        </EnterprisesContext.Provider>
    );
};

export const useEnterprisesContext = () => useContext(EnterprisesContext)