import React, { useEffect, useState } from "react";
import { MainLayout } from "./Layout";
import { Avatar, Button, Card, Col, Row, Typography, theme } from "antd";
import { StatisticsCard } from "./Components/StatisticsCard";
import { DataTable } from "./Components/DataTable";
import { DrawTag } from "./Components/DrawTag";
import { numberFormatter } from "../App";
import { useActionContext } from "../context/ActionContext";
import { useProjectContext } from "../context/ProjectContext";
import { useAuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const { user } = useAuthContext();
  const { associationsToShow } = useActionContext();
  const { invoicesTotal, investTotal } = useProjectContext();
  const [list, setList] = useState([]);


  useEffect(() => {
    if (user) {
      if (!user.isOnboarding) {
        navigate("/profile")
      }
    }
  }, [user]);

  useEffect(() => {
    if (associationsToShow.length) {
      setList(associationsToShow);
    }
  }, [associationsToShow]);

  const statusList = [
    { value: 1, label: "Effectué", color: token.colorSuccess },
    { value: 0, label: "En cours", color: token.colorText }
  ]

  const today = new Date();
  const currentMonth = today.getMonth() + 1; // getMonth() is 0-based, so add 1

  // Determine description based on the date range
  const description =
    currentMonth >= 6 && currentMonth <= 11
      ? "*montant viré sur compte bancaire au 1er décembre si non investi."
      : "*montant viré sur compte bancaire au 1er juin si non investi.";

  if (user) {
    return (
      <MainLayout>
        <Row style={{ marginBottom: 24 }}>
          <Col style={{ flex: 1 }}>
            <Typography.Title style={{ margin: 0, color: "#101828" }} level={2}>
              Tableau de bord
            </Typography.Title>
            <Typography.Title
              level={5}
              style={{ color: "#475467", margin: 0, fontWeight: 400 }}
            >
              Bienvenue sur Sience, {user?.firstName ?? user?.username} !
            </Typography.Title>
          </Col>
          {/* <Col>
          <Button size="large" style={{ marginLeft: 16 }} type="primary">
            Nouvelle demande
          </Button>
        </Col> */}
        </Row>
        {/* Filters */}
        {/* <Row style={{ marginBottom: 24 }}>
        <Col style={{ flex: 1 }}>
          <Radio.Group size="large">
            {["Daily", "Weekly", "Monthly", "Quarterly", "Yearly"].map(
              (val) => (
                <Radio.Button key={val} value={val}>
                  {val}
                </Radio.Button>
              )
            )}
          </Radio.Group>
        </Col>
        <Col>
          <Form.Item noStyle>
            <DatePicker.RangePicker size="large" />
          </Form.Item>
          <Button size="large" icon={<CalendarOutlined />}>
            Date départ - Date fin
          </Button>
          <Button
            size="large"
            style={{ marginLeft: 16 }}
            icon={<FilterOutlined />}
          >
            Filters
          </Button>
        </Col>
      </Row> */}
        <Row gutter={20}>
          <Col xs={24} md={8}>
            <StatisticsCard
              label={"Montant DRCP"}
              value={invoicesTotal}
              description={<>&nbsp;</>}
            />
          </Col>
          <Col xs={24} md={8}>
            <StatisticsCard
              label={"Montant investi"}
              value={investTotal}
              description={<>&nbsp;</>}
            />
          </Col>
          <Col xs={24} md={8}>
            <StatisticsCard
              label={"Montant en banque*"}
              value={invoicesTotal - investTotal}
              description={description}
            />
          </Col>
        </Row>

        {/* <Row gutter={20}>
          <Col xs={24} style={{ marginTop: 30 }}>
            <Typography.Title level={4} style={{ margin: 0 }}>
              Investissements
            </Typography.Title>
            <Typography.Paragraph>
              Causes pour lesquelles vous avez investi
            </Typography.Paragraph>
            <Card bordered={false} bodyStyle={{ padding: 20 }}>
              <DataTable
                name={"name"}
                alterBg
                statusList={statusList}
                dataSource={list}
                columns={[
                  {
                    title: "Causes",
                    key: "name",
                    dataIndex: "name",
                    render: (value, row) => {
                      return (
                        <Card.Meta
                          className="table-meta-cell"
                          avatar={<Avatar size={40} src={row.logo} />}
                          title={<span>{value}</span>}
                          description={row.url}
                        />
                      );
                    },
                  },
                  {
                    key: "status",
                    dataIndex: "status",
                    title: "Statuts",
                    width: 114,
                    render: (status) => {
                      const filtered = statusList.filter(s => s.value === status)
                      return <DrawTag bordered={true} value={filtered[0]?.label} />;
                    },
                  },
                  {
                    key: "investments",
                    dataIndex: "investments",
                    title: "Montants investis",
                    align: "right",
                    render: (investments, obj) => {
                      let price = 0
                      for (const i of investments) {
                        price += i.amount
                      }
                      return numberFormatter(price) + " €"
                      // return <Progress
                      //   percent={value}
                      //   format={() => numberFormatter(price) + " €"}
                      //   strokeColor={token.colorPrimary}
                      // />
                    },
                  },
                ]}
              />
            </Card>
          </Col>
        </Row> */}
      </MainLayout>
    );
  }
};

export { Dashboard };
