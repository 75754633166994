import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, useNavigate, Link } from "react-router-dom";
import LoginPage from "./screens/LoginPage";
import SignupPage from "./screens/SignupPage";
import ForgotPasswordPage from "./screens/ForgotPasswordPage";
import ForgotPasswordSuccess from "./screens/ForgotPasswordSuccess";
import ResetPassword from "./screens/ResetPassword";
import CodeSentSuccess from "./screens/CodeSentSuccess";
import ProfilePage from "./screens/ProfilePage";
import SettingsPage from "./screens/SettingsPage";
import { AuthContext, useAuthContext } from "./context/AuthContext";
import ForgotPasswordCode from "./screens/ForgotPasswordCode";
import ResetPasswordSuccess from "./screens/ResetPasswordSuccess";
import EmailVerified from "./screens/EmailVerified";
import VerifyCode from "./screens/VerifyCode";
import { Dashboard } from "./screens/Dashboard";
import { Remboursements } from "./screens/Remboursements";
import { RemboursementDetail } from "./screens/RemboursementDetail";
import { Investments } from "./screens/Investments";
import { InvestmentsDetail } from "./screens/InvestmentsDetail";
import AdminRouter from "./screens/AdminArea/AdminRouter";
import NullPage from "./screens/Components/NullPage";
import AppLoader from "./screens/Components/Loader";
import { useCompanyContext } from "./context/CompanyContext";
import { Loader } from "@googlemaps/js-api-loader"

// import Information  from "./screens/Components/Onbording/Information/information";
// import Esign from "./screens/Components/Onbording/Esign/eSign";
// import OnBording from "./screens/OnBording";
const AppRouter = () => {
  const navigate = useNavigate();
  const { user, loading, setLoading } = useAuthContext()
  const { companyData, setGoogle } = useCompanyContext()

  useEffect(() => {
    // console.log(user);
    // console.log(companyData);
    if (user && !user?.isAdmin) {
      if ((!companyData || !companyData?.signatureStatus)) {
        if (!user.isOnboarding) {
          navigate("/profile")
        }
      }
      setLoading(false)
    }
  }, [user]);

  const initializeMapLoader = () => {
    try {
      const loader = new Loader({
        apiKey: "AIzaSyC5ZIn6iGIdZ3rn3E3fS7gdZ2GxgUcUEUg",
        version: "weekly",
        libraries: ["places"]
      })
      loader.load().then(google => setGoogle(google))
    } catch (error) {
      console.log(error.message);
    }
  }

  useEffect(initializeMapLoader) // google map loader

  return !loading ? (user?.isAdmin ? <AdminRouter /> : <Routes>
    <Route exact path="/" element={<ProtectedRoute navigate={navigate} component={Dashboard}></ProtectedRoute>} />
    <Route exact path="/login" element={<LoginPage />} />
    <Route exact path="/signup" element={<SignupPage />} />
    <Route exact path="/forgot-password" element={<ForgotPasswordPage />} />
    <Route exact path="/forgot-password-success" element={<ForgotPasswordCode />} />
    <Route exact path="/reset-password-success" element={<ResetPasswordSuccess />} />
    <Route exact path="/forgot-password/success" element={<ForgotPasswordSuccess />} />
    <Route exact path="/verify-code" element={<VerifyCode />} />
    <Route exact path="/code-sent-success" element={<CodeSentSuccess />} />
    <Route exact path="/reset-password" element={<ResetPassword />} />
    <Route exact path="/email-verified" element={<EmailVerified />} />
    <Route exact path="/dashboard" element={<ProtectedRoute navigate={navigate} component={Dashboard}></ProtectedRoute>} />
    <Route exact path="/profile" element={<ProtectedRoute navigate={navigate} component={ProfilePage}></ProtectedRoute>} />
    <Route exact path="/remboursements" element={<ProtectedRoute navigate={navigate} component={Remboursements}></ProtectedRoute>} />
    <Route exact path="/remboursement/:id" element={<ProtectedRoute navigate={navigate} component={RemboursementDetail}></ProtectedRoute>} />
    <Route exact path="/investments" element={<ProtectedRoute navigate={navigate} component={Investments}></ProtectedRoute>} />
    <Route exact path="/investments/:id" element={<ProtectedRoute navigate={navigate} component={InvestmentsDetail}></ProtectedRoute>} />
    {/* <Route exact path="/chat-list" element={<ProtectedRoute navigate={navigate} component={Inbox}></ProtectedRoute>} /> */}
    <Route exact path="/settings" element={<ProtectedRoute navigate={navigate} component={SettingsPage}></ProtectedRoute>} />
    <Route exact path="*" element={<NullPage />} />
  </Routes>) : <AppLoader />
};
export const ProtectedRoute = ({ component: Component, navigate, ...rest }) => {
  const { user, updateUser } = useContext(AuthContext);
  // const navigation = useNavigate();
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (!storedUser) {
      navigate("/login", { replace: true });
    } else {
      updateUser({ ...storedUser })
    }
  }, []);
  return <Component />;
};

export default AppRouter;
