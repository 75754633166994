import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNotificationContext } from "./NotificationContext";
// import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // const navigate = useNavigate();

  const { setError } = useNotificationContext()

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [accessToken, setAccessToken] = useState(null);

  const login = (userData) => {
    updateUser(userData);
    setAccessToken(userData.accessToken);
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      setAccessToken(accessToken);
    }

    const _user = JSON.parse(localStorage.getItem("user"));
    if (_user) {
      (async () => {
        setLoading(true)
        const u = await getUser(_user, accessToken)
        if (u) {
          updateUser(u)
        }
        setLoading(false)
      })();
    }
  }, []);

  const getUser = async (user, accessToken) => {
    const config = {
      method: "GET",
      url: `/user/${user.id}`,
      headers: {
        "x-access-token": accessToken ?? user?.accessToken,
      },
    };
    const response = await axios.request(config);
    if (response.data.success) {
      return response.data.user
    } else {
      onLogout()
    }
    // setError({ message: "Unsuccessful!", description: response.data.message })
    return false
  };
  const addStaff = async (data) => {
    const config = {
      method: "POST",
      url: "/auth/register",
      data: { user: data },
      headers: {
        "x-access-token": accessToken ?? user?.accessToken,
      },
    };

    const response = await axios.request(config);
    return response.data;
  };

  const onLogout = async () => {
    try {
      // Send the data to the backend
      const config = {
        method: "POST",
        url: "/auth/logout",
        data: {
          userId: user?.id
        }
      };
      const response = await axios(config);
      if (response.data.message) {

        logout(() => {
          // navigate("/login", { replace: true });
          window.location = "/login"
          window.location.reload()
        });
      } else {
        console.log(response.data.error);
      }
    } catch (e) {
      console.log(e.message);
      setError({ message: "Ofline", description: "Check Your Internet Connection." })
    }
  };

  const logout = (cb) => {
    setUser(null);
    // Remove user data from local storage
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");
    cb && cb();
  };

  const updateUser = (userData) => {
    setUser(userData);
    // Save user data to local storage

    localStorage.setItem("accessToken", userData.accessToken);
    localStorage.setItem("user", JSON.stringify(userData));
  };

  const signup = (userData) => {
    // Perform signup logic here
    setUser(userData);
  };

  return (
    <AuthContext.Provider
      value={{ user, loading, login, signup, logout, updateUser, addStaff, setLoading, accessToken }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
