import React, { useEffect, useContext, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Typography,
  Upload,
  Select,
  Image,
  theme,
} from "antd";
import { EditOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { DataTable } from "../Components/DataTable";

import { t } from "i18next";
import axios from "axios";
import { AuthContext } from "../../../context/AuthContext";
import { getAssociations } from "../../../functions";
import { DrawTag } from "../../Components/DrawTag";
import { useSearch } from "../../../context/admin/SearchContext";
import { searchInDataTable } from "../../../functions";

const Associations = (props) => {
  const { token } = theme.useToken();
  const { user, accessToken } = useContext(AuthContext);
  const [open, openModal] = useState(false);
  const [form] = Form.useForm();
  const [filterForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [associationsData, setAssociationsData] = useState([]);
  const [viewMode, setViewMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [charterImageUrl, setCharterImageUrl] = useState(null);
  const [ribImageUrl, setRibImageUrl] = useState(null);
  const [logoImageUrl, setLogoImageUrl] = useState(null);
  const [title, setTitle] = useState("");
  const [selectedCharterImage, setSelectedCharterImage] = useState(null);
  const [selectedRibImage, setSelectedRibImage] = useState(null);
  const [selectedLogoImage, setSelectedLogoImage] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [filteredAssociationsData, setFilteredAssociationsData] =
    useState(associationsData);

  const handleSearchInputChange = (event) => {
    const keyword = event.target.value;
    setSearchKeyword(keyword);
    const filteredData = searchInDataTable(keyword, associationsData, "name") ;
    setFilteredAssociationsData(filteredData);
  };

  const options = [
    {
      value: 1,
      label: "Oui",
    },
    {
      value: 0,
      label: "Non",
    },
  ];

  const statusList = [
    { value: 1, label: "Effectué", color: token.colorSuccess },
    { value: 0, label: "En cours", color: token.colorText },
  ];

  // const valueToLabelMap = {
  //   0: "Non",
  //   1: "Oui",
  // };


  // const statusValueToLabelColorMap = [
  //   { value : 0, label: "En cours", color: token.colorText },
  //   { value : 1,label: "Effectué", color: token.colorSuccess },
  // ];

  const [charterData, setCharterData] = useState({
    charter: null,
  });
  const [ribData, setRibData] = useState({
    rib: null,
  });
  const [logoData, setLogoData] = useState({
    logo: null,
  });

  const handleCharterChange = (file) => {
    setCharterData({
      charter: file,
    });
    setSelectedCharterImage(file);
  };
  const handleRibChange = (file) => {
    setRibData({
      rib: file,
    });
    setSelectedRibImage(file);
  };
  const handleLogoChange = (file) => {
    setLogoData({
      logo: file,
    });
    setSelectedLogoImage(file);
  };

  const onClose = () => {
    openModal(false);
  };

  const handleAssociations = async (values) => {
    setLoading(true);
    try {
      const payload = new FormData();
      payload.append("charter", charterData.charter);
      payload.append("rib", ribData.rib);
      payload.append("logo", logoData.logo);

      if (values.id) {
        payload.append("id", values.id);
      }
      for (const key in values) {
        if (
          key !== "charter" &&
          key !== "rib" &&
          key !== "logo" &&
          values[key] !== undefined
        ) {
          payload.append(key, values[key]);
        }
      }
      payload.append(`userId`, user.id);

      const config = {
        method: values.id ? "PUT" : "POST",
        url: "/actions/association",
        headers: {
          "content-type": "multipart/form-data",
          "x-access-token": accessToken ?? user.accessToken,
        },
        data: payload,
      };
      setLoading(true);
      const response = await axios(config);
      const data = response.data;
      if (data.success) {
        form.resetFields(["charter", "rib", "logo"]);
        setCharterData({ charter: null });
        setRibData({ rib: null });
        setLogoData({ logo: null });
        setAssociationsData((prevData) => {
          const updatedAssociations = [...prevData];
          const updatedAssociationIndex = updatedAssociations.findIndex(
            (association) => association.id === response.data.association.id
          );

          if (updatedAssociationIndex !== -1) {
            // If the association already exists, update it
            updatedAssociations[updatedAssociationIndex] = {
              ...response.data.association,
              status : values.status,
              patronage : values.patronage,
              access  : values.access ,

            }
          } else {
            // If it's a new association, add it to the beginning of the array
            updatedAssociations.unshift({
              ...response.data.association,
              status : values.status,
              patronage : values.patronage,
              access  : values.access ,
            });
          }
          setCharterImageUrl(data.association.charter);
          setRibImageUrl(data.association.rib);
          setLogoImageUrl(data.association.logo);

          return updatedAssociations;
        });
      }

      onClose();
      form.resetFields();
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch data from the backend when the component mounts
    if (associationsData.length === 0 && user) {
      (async () => {
        const received = await getAssociations(
          accessToken ?? user.accessToken
        );
        if (received.asociations?.length) {
          setAssociationsData([...received.asociations]);
          if (received.asociations[0]) {
            setCharterImageUrl(received.asociations[0].charter);
            setRibImageUrl(received.asociations[0].rib);
            setLogoImageUrl(received.asociations[0].logo);
          }
        }
      })();
    }
  }, [user]);

  // useEffect(() => {
  //   setFilteredAssociationsData(associationsData);
  // }, [associationsData]);

  const charterSrc = selectedCharterImage
    ? URL.createObjectURL(selectedCharterImage)
    : charterImageUrl;
  const ribSrc = selectedRibImage
    ? URL.createObjectURL(selectedRibImage)
    : ribImageUrl;
  const logoSrc = selectedLogoImage
    ? URL.createObjectURL(selectedLogoImage)
    : logoImageUrl;

  return (
    <>
      <Modal
        className="uploadImage"
        width={680}
        open={open}
        onCancel={() => {
          openModal(false);
          form.resetFields();
        }}
        cancelText={"Annuler"}
        cancelButtonProps={{ style: { display: "none" } }}
        title={title}
        bodyStyle={{
          paddingInline: 20,
          paddingBlock: 5,
        }}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          size="large"
          requiredMark={false}
          onFinish={() => {
            const values = form.getFieldsValue(true);
            handleAssociations(values);
          }}
        >
          {/* {(values) => ( */}

          <>
            <Form.Item
              name={"name"}
              label="Nom de la strucure"
              rules={[
                {
                  required: true,
                  message: "veuillez entrer le nom ",
                },
              ]}
            >
              <Input
                name="name"
                placeholder="Nouvelle association"
                readOnly={viewMode}
              />
            </Form.Item>
            <Form.Item
              name={"siret"}
              label="N° de Siret"
              rules={[
                {
                  required: true,
                  message: "veuillez entrer siret ",
                },
              ]}
            >
              <Input
                name="siret"
                placeholder="00000000 000"
                readOnly={viewMode}
              />
            </Form.Item>
            <Form.Item
              name={"url"}
              label="Website"
              rules={[
                {
                  required: true,
                  message: "veuillez entrer l'URL ",
                },
              ]}
            >
              <Input
                name="url"
                placeholder="www.example.com"
                readOnly={viewMode}
              />
            </Form.Item>
            <div style={{ marginTop: 0 }}>
              <Row gutter={16}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name={"status"}
                    label="Statut"
                    rules={[
                      {
                        required: true,
                        message: "veuillez sélectionner le statut ",
                      },
                    ]}
                  >
                    <Select
                      autoClearSearchValue
                      placeholder="Statut"
                      name="status"
                      disabled={viewMode}
                      options={statusList}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <div>
                    {viewMode && (
                      <>
                        <Button
                          style={{
                            width: 285,
                            height: 45,
                            marginLeft: 3,
                            marginTop: 30,
                            marginBottom: 10,
                          }}
                          block
                          disabled={viewMode}
                        >
                          {t("LOGO")}
                        </Button>
                        <Image src={logoImageUrl} className="logo" alt="logo" />
                      </>
                    )}
                    {!viewMode && (
                      <Form.Item
                        name={"logo"}
                        rules={[
                          {
                            required: true,
                            message:
                              "veuillez sélectionner le fichier de charte",
                          },
                        ]}
                      >
                        <Upload
                          name="logo"
                          disabled={viewMode}
                          onRemove={(file) => {
                            handleLogoChange(null);
                          }}
                          beforeUpload={(file) => {
                            handleLogoChange(file);
                            return false;
                          }}
                        >
                          <Button
                            style={{
                              width: 285,
                              height: 45,
                              marginLeft: 3,
                              marginTop: 30,
                            }}
                            block
                          >
                            {t("LOGO")}
                          </Button>
                        </Upload>
                      </Form.Item>
                    )}
                    {editMode && logoSrc && (
                      <Image src={logoSrc} alt="logo" className="logo" />
                    )}
                  </div>
                </Col>
              </Row>
            </div>
            <div
              className="card-payment"
              layout="vertical"
              size="large"
              style={{ marginTop: 0 }}
            >
              <Row gutter={16}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name={"refName"}
                    label="Nom du référent"
                    rules={[
                      {
                        required: true,
                        message: "veuillez entrer le nom du référent",
                      },
                    ]}
                  >
                    <Input
                      name="refName"
                      placeholder="Pierre Dupont"
                      readOnly={viewMode}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name={"role"}
                    label="Rôle"
                    rules={[
                      {
                        required: true,
                        message: "veuillez entrer le rôle",
                      },
                    ]}
                  >
                    <Input
                      name="role"
                      placeholder="Président"
                      readOnly={viewMode}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name={"phone"}
                    label="N° de téléphone"
                    rules={[
                      {
                        required: true,
                        message: "veuillez entrer le numéro de téléphone",
                      },
                    ]}
                  >
                    <Input
                      name="phone"
                      placeholder="06 78 90 12 34"
                      readOnly={viewMode}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name={"mail"}
                    label="Adresse email"
                    rules={[
                      {
                        required: true,
                        message: "Veuillez entrer une adresse email valide",
                      },
                      {
                        type: "email",
                        message: "Veuillez entrer une adresse email valide",
                      },
                    ]}
                  >
                    <Input
                      name="mail"
                      placeholder="p.dupont@asso.com"
                      readOnly={viewMode}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name={"patronage"}
                    label="Mécénat"
                    rules={[
                      {
                        required: true,
                        message: "veuillez sélectionner le mécénat",
                      },
                    ]}
                  >
                    <Select
                      autoClearSearchValue
                      placeholder="Oui"
                      name="patronage"
                      disabled={viewMode}
                      options={options}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name={"access"}
                    label="Accès"
                    rules={[
                      {
                        required: true,
                        message: "veuillez sélectionner l'accès",
                      },
                    ]}
                  >
                    <Select
                      autoClearSearchValue
                      placeholder="Oui"
                      name="access"
                      disabled={viewMode}
                      options={options}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <div>
                    {viewMode && (
                      <>
                        <Button
                          style={{
                            width: 270,
                            marginLeft: 2,
                            marginBottom: 10,
                          }}
                          disabled={viewMode}
                          block
                        >
                          {t("CHARTER")}
                        </Button>
                        <Image
                          src={charterImageUrl}
                          className="charter"
                          alt="charter"
                        />
                      </>
                    )}
                    {!viewMode && (
                      <Form.Item
                        name={"charter"}
                        rules={[
                          {
                            required: true,
                            message:
                              "veuillez sélectionner le fichier de charte",
                          },
                        ]}
                      >
                        <Upload
                          name="charter"
                          disabled={viewMode}
                          onRemove={(file) => {
                            handleCharterChange(null);
                          }}
                          beforeUpload={(file) => {
                            handleCharterChange(file);
                            return false;
                          }}
                        >
                          <Button
                            style={{
                              width: 270,
                              marginLeft: 2,
                            }}
                            block
                          >
                            {t("CHARTER")}
                          </Button>
                        </Upload>
                      </Form.Item>
                    )}
                    {editMode && charterSrc && (
                      <Image
                        src={charterSrc}
                        alt="charter"
                        className="charter"
                      />
                    )}
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <div>
                    {viewMode && (
                      <>
                        <Button
                          style={{
                            width: 270,
                            marginLeft: 2,
                            marginBottom: 10,
                          }}
                          block
                          disabled={viewMode}
                        >
                          {t("RIB")}
                        </Button>
                        <Image src={ribImageUrl} className="rib" alt="rib" />
                      </>
                    )}
                    {!viewMode && (
                      <Form.Item
                        name={"rib"}
                        rules={[
                          {
                            required: true,
                            message:
                              "veuillez sélectionner le fichier de charte",
                          },
                        ]}
                      >
                        <Upload
                          name="rib"
                          disabled={viewMode}
                          onRemove={(file) => {
                            handleRibChange(null);
                          }}
                          beforeUpload={(file) => {
                            handleRibChange(file);
                            return false;
                          }}
                        >
                          <Button
                            style={{
                              width: 270,
                              marginLeft: 2,
                            }}
                            block
                          >
                            {t("RIB")}
                          </Button>
                        </Upload>
                      </Form.Item>
                    )}
                    {editMode && ribSrc && (
                      <Image src={ribSrc} alt="RIB" className="rib" />
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            block={true}
            loading={loading}
            disabled={viewMode}
          >
            Ajouter
          </Button>
        </Form>
      </Modal>
      <Row style={{ marginBottom: 24 }}>
        <Col style={{ flex: 1 }}>
          <Typography.Title style={{ margin: 0, color: "#101828" }} level={2}>
            Associations
          </Typography.Title>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col xs={24}>
          <Form
            layout="vertical"
            form={filterForm}
            name="searchForm"
            size="large"
          >
            <Row gutter={12}>
              <Col xs={12} md={5}>
                <Form.Item name="search" label="Rechercher par nom">
                  <Input
                    prefix={<SearchOutlined size={15} />}
                    placeholder="Rechercher"
                    value={searchKeyword}
                    onChange={handleSearchInputChange}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={12}
                md
                style={{
                  marginLeft: "auto",
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 12,
                }}
              >
                <Form.Item label="&nbsp;">
                  <Button
                    onClick={() => {
                      setTitle("Nouvelle association");
                      setViewMode(false);
                      setEditMode(false);
                      openModal(true);
                      form.resetFields();
                    }}
                    style={{ marginLeft: 12 }}
                    type="primary"
                  >
                    {t("NEW_ASSOCIATION")}
                  </Button>
                </Form.Item>
              </Col>
              <Col xs={12} md></Col>
            </Row>
          </Form>
          <DataTable
          
            dataSource={searchKeyword ? filteredAssociationsData : associationsData }
            columns={[
              {
                title: "Structures",
                key: "name",
                dataIndex: "name",
              },
              {
                key: "siret",
                dataIndex: "siret",
                title: "N°Siret",
              },
              {
                key: "refName",
                dataIndex: "refName",
                title: "Référent",
              },
              {
                key: "phone",
                dataIndex: "phone",
                title: "N° Tél",
              },
              {
                key: "mail",
                dataIndex: "mail",
                title: "Mails",
              },
              {
                key: "status",
                dataIndex: "status",
                title: "Statut",
                render: (status) => {
                  
                

                  const filtered = statusList.filter(
                    (s) => s.value === status
                  )[0];
                  return (
                    <DrawTag
                      bordered={true}
                      color={filtered?.label}
                      value={filtered?.label}
                    />
                  );
                }
              },
              {
                key: "patronage",
                dataIndex: "patronage",
                title: "Mécénat",
                render: (patronage) => {
                  return options.filter((o) => o.value === patronage)[0]?.label;
                  
                },
              },
              {
                key: "access",
                dataIndex: "access",
                title: "Accès",
                render: (access) => {
                  return options.filter((o) => o.value === access)[0]?.label;
                
                },
              },
              {
                key: "download",
                dataIndex: "id",
                title: "",
                width: 140,
                render: (id, row) => {
                  return (
                    <>
                      <Button
                        type="link"
                        onClick={() => {
                          form.setFieldsValue(row);
                          setCharterImageUrl(row.charter);
                          setRibImageUrl(row.rib);
                          setLogoImageUrl(row.logo);
                          setViewMode(true);
                          setTitle("Voir l'association");
                          setEditMode(false);
                          openModal(true);
                        }}
                      >
                        <EyeOutlined />
                      </Button>
                      <Button
                        type="link"
                        onClick={() => {
                          form.setFieldsValue({ ...row });
                          setCharterImageUrl(row.charter);
                          setRibImageUrl(row.rib);
                          setLogoImageUrl(row.logo);
                          setEditMode(true);
                          setTitle("Mettre à jour l'association");
                          setViewMode(false);
                          // form.resetFields([ 'charter', 'rib'])

                          openModal(true);
                        }}
                      >
                        <EditOutlined />
                      </Button>
                    </>
                  );
                },
              },
            ]}
          />
        </Col>
      </Row>
    </>
  );
};

export default Associations;
