import React from 'react'
import { Button, Card, Col, Row, theme, Typography } from 'antd';
import { CheckOutlined, InfoCircleFilled, PaperClipOutlined, PhoneFilled, SendOutlined, VideoCameraFilled, } from "@ant-design/icons";
import { ChatItem, ChatList, MessageList, Input as InputForm, } from "react-chat-elements";

const Echanges = props => {
    const { token } = theme.useToken()
    return (
        <Row
            gutter={20}
            style={{ marginBottom: 20, marginTop: 20 }}
        >
            <Col xs={24} sm={6}>
                <Card bordered={false} bodyStyle={{ padding: 10 }}>
                    <ChatList
                        className="chat-list"
                        dataSource={[
                            {
                                avatar:
                                    "https://avatars.githubusercontent.com/u/80540635?v=4",
                                alt: "kursat_avatar",
                                title: (
                                    <Typography.Title
                                        level={5}
                                        style={{ margin: 0, lineHeight: 1.3 }}
                                    >
                                        Kursat
                                    </Typography.Title>
                                ),
                                subtitle: (
                                    <label
                                        style={{
                                            color: "#0006",
                                            fontSize: 12,
                                            margin: 0,
                                        }}
                                    >
                                        Projet semestre #1, 2023
                                    </label>
                                ),
                                date: new Date(),
                                unread: 3,
                            },
                            {
                                avatar:
                                    "https://avatars.githubusercontent.com/u/80540635?v=4",
                                alt: "kursat_avatar",
                                title: (
                                    <Typography.Title
                                        level={5}
                                        style={{ margin: 0, lineHeight: 1.3 }}
                                    >
                                        Kursat
                                    </Typography.Title>
                                ),
                                subtitle: (
                                    <label
                                        style={{
                                            color: "#0006",
                                            fontSize: 12,
                                            margin: 0,
                                        }}
                                    >
                                        Projet semestre #1, 2023
                                    </label>
                                ),
                                date: new Date(),
                                unread: 3,
                            },
                            {
                                avatar:
                                    "https://avatars.githubusercontent.com/u/80540635?v=4",
                                alt: "kursat_avatar",
                                title: (
                                    <Typography.Title
                                        level={5}
                                        style={{ margin: 0, lineHeight: 1.3 }}
                                    >
                                        Kursat
                                    </Typography.Title>
                                ),
                                subtitle: (
                                    <label
                                        style={{
                                            color: "#0006",
                                            fontSize: 12,
                                            margin: 0,
                                        }}
                                    >
                                        Projet semestre #1, 2023
                                    </label>
                                ),
                                date: new Date(),
                                unread: 3,
                            },
                            {
                                avatar:
                                    "https://avatars.githubusercontent.com/u/80540635?v=4",
                                alt: "kursat_avatar",
                                title: (
                                    <Typography.Title
                                        level={5}
                                        style={{ margin: 0, lineHeight: 1.3 }}
                                    >
                                        Kursat
                                    </Typography.Title>
                                ),
                                subtitle: (
                                    <label
                                        style={{
                                            color: "#0006",
                                            fontSize: 12,
                                            margin: 0,
                                        }}
                                    >
                                        Projet semestre #1, 2023
                                    </label>
                                ),
                                date: new Date(),
                                unread: 3,
                            },
                        ]}
                    />
                </Card>
            </Col>
            <Col xs={24} sm={18}>
                <Card
                    bordered={false}
                    bodyStyle={{
                        padding: 0,
                        borderRadius: 10,
                        border: "1px solid " + token.colorPrimary,
                    }}
                >
                    <ChatItem
                        avatar="https://avatars.githubusercontent.com/u/80540635?v=4"
                        date={null}
                        className="default-chat-item"
                        title={
                            <Row>
                                <Col style={{ flex: 1 }}>
                                    <Typography.Title
                                        level={5}
                                        style={{ margin: 0, lineHeight: 1.3 }}
                                    >
                                        Kursat
                                    </Typography.Title>
                                    <label
                                        style={{
                                            color: "#000",
                                            fontSize: 12,
                                            margin: 0,
                                        }}
                                    >
                                        Projet semestre #1, 2023
                                    </label>
                                </Col>
                                <Col>
                                    <Button type="link" size="large">
                                        <PhoneFilled />
                                    </Button>
                                    <Button type="link" size="large">
                                        <VideoCameraFilled />
                                    </Button>
                                    <Button type="link" size="large">
                                        <InfoCircleFilled />
                                    </Button>
                                </Col>
                            </Row>
                        }
                    />
                    <div
                        style={{
                            height: 500,
                            overflow: "auto",
                            marginBottom: 20,
                        }}
                    >
                        <MessageList
                            className="message-list"
                            lockable={true}
                            toBottomHeight={"100%"}
                            notchStyle={{ display: "none" }}
                            dataColor={token.colorPrimary}
                            dataSource={[
                                {
                                    avatar:
                                        "https://avatars.githubusercontent.com/u/80540635?v=4",
                                    position: "left",
                                    type: "text",
                                    date: new Date(),
                                    title: "Kursat",
                                    text: "Give me a message list example !",
                                },
                                {
                                    avatar:
                                        "https://avatars.githubusercontent.com/u/80540635?v=4",
                                    position: "right",
                                    type: "text",
                                    date: new Date(),
                                    title: "Emre",
                                    text: "That's all.",
                                },
                                {
                                    avatar:
                                        "https://avatars.githubusercontent.com/u/80540635?v=4",
                                    position: "right",
                                    type: "text",
                                    date: new Date(),
                                    title: "Emre",
                                    text: "That's all.",
                                },
                                {
                                    avatar:
                                        "https://avatars.githubusercontent.com/u/80540635?v=4",
                                    position: "right",
                                    type: "text",
                                    date: new Date(),
                                    title: "Emre",
                                    text: "That's all.",
                                },
                                {
                                    avatar:
                                        "https://avatars.githubusercontent.com/u/80540635?v=4",
                                    position: "right",
                                    type: "text",
                                    date: new Date(),
                                    title: "Emre",
                                    text: "That's all.",
                                },
                                {
                                    avatar:
                                        "https://avatars.githubusercontent.com/u/80540635?v=4",
                                    position: "right",
                                    type: "text",
                                    date: new Date(),
                                    title: "Emre",
                                    text: "That's all.",
                                },
                                {
                                    avatar:
                                        "https://avatars.githubusercontent.com/u/80540635?v=4",
                                    position: "right",
                                    type: "text",
                                    date: new Date(),
                                    title: "Emre",
                                    text: "That's all.",
                                },
                            ]}
                        />
                    </div>
                    <InputForm
                        placeholder="Tapez votre message"
                        rightButtons={
                            <div>
                                <Button htmlType="submit" type="primary">
                                    <SendOutlined />
                                </Button>
                                <Button type="link">
                                    <PaperClipOutlined />
                                </Button>
                                <Button type="link">
                                    <CheckOutlined />
                                </Button>
                            </div>
                        }
                    />
                </Card>
            </Col>
        </Row>
    )
}

export default Echanges
