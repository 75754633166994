import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuthContext } from "../AuthContext";
import axios from "axios";
import { useNotificationContext } from "../NotificationContext";
import { useAdminContext } from "./AdminContext";

export const DocumentContext = createContext();


export const DocumentProvider = ({ children, navigation }) => {
    const { users } = useAdminContext()
    const { accessToken } = useAuthContext()
    const { setSuccess, setError } = useNotificationContext()

    const [documents, setDocuments] = useState({});
    const [loading, setLoading] = useState(false);
    const [pendingDocs, setPendingDocs] = useState({});

    useEffect(() => {
        if (accessToken && Object.keys(documents).length < 1) {
            (async () => await getDocs())();
        }
    }, [accessToken, users])

    const getDocs = async (userId) => {
        setLoading("F")
        const config = {
            "method": "GET",
            "url": `/document`,
            "headers": {
                "x-access-token": accessToken
            }
        }
        config.url += userId ? `?userId=${userId}` : ``
        const response = await axios.request(config)
        const notif = { message: response.data.message, description: "" }
        if (response.data.success) {
            setDocuments({ ...response.data.documents })
            setPendingDocs({ ...response.data.pendings })
        } else {
            setError(notif)
        }
        setLoading(false)
    }

    const uploadDoc = async (formData, userId, index, docId) => {
        setLoading("U")
        const config = {
            "method": docId ? "PUT" : "POST",
            "url": "/document",
            "data": formData,
            "headers": {
                "x-access-token": accessToken
            }
        }
        const response = await axios.request(config)
        const notif = { message: response.data.message, description: "Document ajouté" }
        if (response.data.success) {
            let data = { ...documents }
            let docs = [...data[userId]]
            docs.splice(index, 1, { ...response.data.document })
            setDocuments({ ...data, [userId]: [...docs] })
            setPendingDocs(prev => ({ ...prev, [userId]: response.data.pending }))
            setSuccess(notif)
            setLoading(false)
        } else {
            setError(notif)
            setLoading(false)
        }
        return response.data.success
    }

    return (
        <DocumentContext.Provider value={{ documents, loading, pendingDocs, uploadDoc, getDocs }}>
            {children}
        </DocumentContext.Provider>
    );
};

export const useDocumentContext = () => useContext(DocumentContext)