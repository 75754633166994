import { React, useContext, useEffect, useState } from "react";
import { MainLayout } from "./Layout";
import { AuthContext } from "../context/AuthContext";
import { Avatar, Button, Card, Col, DatePicker, Form, Row, Typography, theme, } from "antd";
import { DownloadOutlined, FileOutlined, } from "@ant-design/icons";
import { DataTable } from "./Components/DataTable";
import { DrawTag } from "./Components/DrawTag";
import { numberFormatter } from "../App";
import { useNavigate, Link } from "react-router-dom";
import { ProjectContext } from "../context/ProjectContext";
import { formatDate, getProjects } from "../functions";
import CreateProjectModal from "./Components/CreateProjectModal";

const Remboursements = () => {
    const { projects, storeProjects } = useContext(ProjectContext);
    const { user, accessToken } = useContext(AuthContext);
    const [isModalVisible, setModalVisible] = useState(false);
   

    const { token } = theme.useToken();
    const navigation = useNavigate();

    const statusList = [
        {
            value: "0",
            label: "En cours de traitement",
            color: token.colorWarning,
        },
        {
            value: "1",
            label: "Remboursé",
            color: token.colorSuccess,
        },
    ]
    const openModal = () => {
        setModalVisible(true);
    };
    const closeModal = () => {
        setModalVisible(false);
    };
    useEffect(() => {
        if (projects.length === 0 && user) {
            (async () => {
                const recievedProjects = await getProjects(accessToken ?? user.accessToken);
             
                if (recievedProjects?.length) {
                    storeProjects([...recievedProjects]);
                }
            })();
        }
    }, [user]);

    return (
        <MainLayout>
            <Row style={{ marginBottom: 24 }}>
                <Col style={{ flex: 1 }}>
                    <Typography.Title
                        style={{ margin: 0, color: "#101828" }}
                        level={2}
                    >
                        Mes projets
                    </Typography.Title>
                    <Typography.Title
                        level={5}
                        style={{ color: "#475467", margin: 0, fontWeight: 400 }}
                    >
                        Suivez vos demandes de remboursement.
                    </Typography.Title>
                </Col>
                <Col>
                    {/* <Button
                        size="large"
                        style={{ marginLeft: 16 }}
                        type="primary"

                    >
                        Nouvelle demande
                    </Button> */}
                    <Button
                        type="primary"
                        style={{ marginLeft: 16 }}
                        size="large"
                        // disabled={data.status === "1"}
                        onClick={() => {
                            // if (data.status === "0") {
                            openModal()
                            // }
                        }}
                    >
                        Ajouter des factures au projet en cours
                    </Button>
                    <CreateProjectModal
                        isOpen={isModalVisible}
                        onClose={closeModal}
                        isOnBoard={false}
                        projectId={projects[0]?.id}
                    />
                </Col>

            </Row>
            {/* <Row style={{ marginBottom: 24 }}>
                <Col style={{ flex: 1 }}>
                    <Button
                        type="link"
                        style={{ backgroundColor: "#F3ECF4" }}
                        size="large"
                    >
                        Ajouter des factures au projet en cours
                    </Button>
                </Col>
                <Col>
                    <Form.Item noStyle>
                        <DatePicker.RangePicker size="large" />
                    </Form.Item>
                </Col>
            </Row> */}
            <Row gutter={20}>
                <Col xs={24} style={{ marginTop: 30 }}>
                    <Row>
                        <Col style={{ flex: 1 }}>
                            <Typography.Title level={4} style={{ margin: 0 }}>
                                Statuts de mes projets
                            </Typography.Title>
                            <Typography.Paragraph>
                                Retrouvez le suivi de vos projets en temps réel
                                ici
                            </Typography.Paragraph>
                        </Col>
                        {/* <Col>
                            <Button
                                type="default"
                                size="large"
                                icon={<DownloadOutlined />}
                            >
                                Tout télécharger
                            </Button>
                        </Col> */}
                    </Row>
                    <Card bordered={false} bodyStyle={{ padding: 20 }}>
                        <DataTable
                            name={"title"}
                            statusList={statusList}
                            dataSource={projects}
                            columns={[
                                {
                                    title: "Projet",
                                    key: "title",
                                    dataIndex: "title",
                                    render: (value, row) => {
                                        return (
                                            <Card.Meta
                                                className="table-meta-cell-2"
                                                avatar={
                                                    <Avatar
                                                        style={{
                                                            background:
                                                                "#D8C3DD",
                                                        }}
                                                        size={40}
                                                        icon={
                                                            <FileOutlined
                                                                style={{
                                                                    color: token.colorPrimary,
                                                                }}
                                                            />
                                                        }
                                                    />
                                                }
                                                title={<span>{value}</span>}
                                            />
                                        );
                                    },
                                },
                                {
                                    key: "createdAt",
                                    dataIndex: "createdAt",
                                    title: "Date soumission",
                                    render: (date) => {
                                        return <span>{formatDate(date)}</span>;
                                    },
                                },
                                {
                                    key: "status",
                                    dataIndex: "status",
                                    title: "Statut",
                                    render: (status) => {
                                        const i = statusList.findIndex(s => s.value === status)
                                        return (
                                            <DrawTag
                                                bordered={false}
                                                value={statusList[i]?.label}
                                            />
                                        );
                                    },
                                },
                                {
                                    key: "price",
                                    dataIndex: "price",
                                    title: "Montant DRCP remboursé",
                                    render: (price, row) => {
                                        const invs = row.invoices
                                        let p = 0
                                        invs.forEach(inv => {
                                            if (inv.status === 1) {
                                                p += inv.refund
                                            }
                                        });
                                        return p > 0 ? numberFormatter(p) + " €" : null;
                                    },
                                },
                                {
                                    key: "download",
                                    dataIndex: "id",
                                    title: "",
                                    render: (id) => {
                                        return (
                                            <Button
                                                type="link"
                                                onClick={() =>
                                                    navigation(
                                                        "/remboursement/" + id
                                                    )
                                                }
                                            >
                                                Détails
                                            </Button>
                                        );
                                    },
                                    // render: (id, row) => {
                                    //     return (
                                    //         <Link
                                    //             to={{
                                    //                 pathname: `/remboursement/${id}`,
                                    //                 state: {
                                    //                     projectDetails: row,
                                    //                 },
                                    //             }}
                                    //         >
                                    //             <Button type="link">
                                    //                 Détails
                                    //             </Button>
                                    //         </Link>
                                    //     );
                                    // },
                                },
                            ]}
                        />
                    </Card>
                </Col>
            </Row>
        </MainLayout>
    );
};

export { Remboursements };
