import React, { createContext, useContext, useEffect, useState } from "react";
import { getCompany } from "../functions";
import { useAuthContext } from "./AuthContext";

const CompanyContext = createContext();

export const useCompanyContext = () => {
    return useContext(CompanyContext);
};

export const CompanyProvider = ({ children }) => {

    const { accessToken } = useAuthContext()

    const [companyData, setCompanyData] = useState(null);
    const [google, setGoogle] = useState(null);

    useEffect(() => {
        if (accessToken) {
            (async () => await get())();
        }
    }, [accessToken])

    const get = async () => {
        const response = await getCompany(accessToken)
        setCompanyData(response.company ?? null)
    }

    return (
        <CompanyContext.Provider value={{ companyData, setCompanyData, google, setGoogle }}>
            {children}
        </CompanyContext.Provider>
    );
};
