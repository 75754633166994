import React, { useEffect, useState } from "react";
import { Result, Form, Alert, Button } from "antd";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as KeyIcon } from "../images/success-check.svg";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "../css/style.css";
import axios from "axios";

const EmailVerified = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [isverified, setIsverified] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        (async () => {
            const options = {
                method: "GET",
                url: `email-verify/${searchParams.get("t")}?type=register`,
                headers: {
                    "content-type": "application/json",
                },
            };

            axios
                .request(options)
                .then((response) => {
                    setIsverified(response.data.success);
                })
                .catch((e) => {
                    setError(e.response.data.message);
                });
        })();
    }, []);

    if (isverified) {
        return (
            <div className="authpage-wrapper">
                <Result
                    style={{
                        width: 380,
                        paddingInline: 0,
                        fontSize: 14,
                    }}
                    icon={<KeyIcon width={66} height={66} />}
                    status="success"
                    title="Email verifié"
                    subTitle={`Cliquez ci-dessous pour vous connecter`}
                    extra={[
                        <Button
                            type="primary"
                            size="large"
                            block
                            onClick={() => navigate("/login")}
                            key="resend-link"
                        >
                            <strong>Continuer</strong>
                        </Button>,
                        <br />,
                        <br />,
                        <br />,
                        <div style={{ textAlign: "center" }}>
                            Vous n'avez pas reçu l'e-mail ?{" "}
                            <Link to="/login">
                                <strong>Cliquez pour renvoyer</strong>
                            </Link>
                        </div>,
                        <br />,
                        <Button
                            size="large"
                            block
                            icon={<ArrowLeftOutlined />}
                            htmlType="button"
                            type="link"
                            onClick={() => navigate("/login")}
                        >
                            Retour
                        </Button>,
                    ]}
                />
            </div>
        );
    } else {
        return error ? (
            <Form.Item>
                <Alert type="error" message={error} showIcon />
            </Form.Item>
        ) : null;
    }
};

export default EmailVerified;
