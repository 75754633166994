import { CheckOutlined } from "@ant-design/icons";
import { Tag } from "antd";

const DrawTag = ({ value, dots, icon, color, bordered }) => {
  const colors = {
    green: "#027A48",
    black: "#344054",
    purple: "#5C2D67",
    warning: "#B54708",
    success: "#027A48",
    error: "#B42318",
    blue: "#175CD3",
  };
  const bgcolors = {
    green: "#fff",
    black: "#F2F4F7",
    purple: "#F3ECF4",
    warning: "#FFFAEB",
    success: "#ECFDF3",
    error: "#FEF3F2",
    blue: "#EFF8FF",
  };

  const icons = {
    check: <CheckOutlined />,
  };

  const getStatus = (value) => {
    switch (value) {
      case "success":
      case "Accepté":
      case "Effectué":
      case "Remboursé":
      case "Terminée":
      case "Disponible":
      case "En cours":
      case "Yes":
        return "success";
      case "green":
      case "Customer":
        return "green";
      case "black":
      case "Churned":
      case "Terminé":
        return "black";
      case "Nouveau":
      case "purple":
      case "En cours de vérification":
        return "purple";
      case "En cours de traitement":
      case "warning":
      case "Bientôt disponible":
        return "warning";
      case "No":
      case "error":
      case "Refusé":
      case "Annulée":
        return "error";
      default:
        break;
    }
  };

  const getIcon = (value) => {
    switch (value) {
      case "Remboursé":
        return icons.check;
      default:
        return null;
    }
  };

  return (
    <Tag
      color={bgcolors[color ? getStatus(color) : getStatus(value)]}
      bordered={bordered}
      icon={dots ? <span className="small-dot" /> : getIcon(value) || null}
      style={{
        borderColor: bordered
          ? colors[color ? getStatus(color) : getStatus(value)]
          : "transparent",
        color: colors[color ? getStatus(color) : getStatus(value)],
        borderRadius: 60,
        // borderWidth: 2,
        fontWeight: 500,
      }}
    >
      {value}
    </Tag>
  );
};

export { DrawTag };
