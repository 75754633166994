import { Card, Col, Row, Statistic, Typography } from "antd";
import { numberFormatter } from "../AdminLayout";

const StatisticsCard = ({ label, value, description, format = false }) => {
  return (
    <Card
      bordered={false}
      bodyStyle={{ padding: 20 }}
      rootClassName="stats-card"
    >
      <Statistic
        title={
          <Row>
            <Col style={{ flex: 1 }}>
              <Typography.Title
                level={5}
                style={{ margin: 0, fontWeight: 500 }}
              >
                {label}
              </Typography.Title>
            </Col>
            <Col></Col>
          </Row>
        }
        formatter={format ? numberFormatter : null}
        value={value}
      />
      <p className="stats-card-description">{description}</p>
    </Card>
  );
};

export { StatisticsCard };
