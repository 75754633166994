import React, { useEffect, useState } from "react";
import { Button, Col, DatePicker, Form, Input, Row, Select, theme } from "antd";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { Link } from "react-router-dom";

const Filters = ({ dataSource, invoiceInfo, setFilterData }) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const [search, setSearch] = useState({
    title: "",
    supplier: "",
    status: "",
    date: [],
  });


  useEffect(() => {
    filterDataSource();
  }, [search]);

  useEffect(() => {
    setFilterData(dataSource);
  }, [dataSource]);


  const handleChange = (name, value) => {
    setSearch({ ...search, [name]: value });
    if (name === "date" && value === null) {
      setSearch({ ...search, date: [] })
    }
  };


  const filterDataSource = () => {
    const { title, supplier, status, date } = search;

    let filteredData = [...dataSource];

    if (title !== "") {
      filteredData = filteredData?.filter((item) =>
        item.title.toLowerCase().includes(title.toLowerCase())
      );
    }

    if (supplier !== "") {
      filteredData = filteredData?.filter(
        (item) =>
          item.supplier &&
          item.supplier.toLowerCase().includes(supplier.toLowerCase())
      );
    }

    if (status !== "") {
      const statusValue = status === true ? "Yes" : "No"
      filteredData = filteredData?.filter(
        (item) => {
          const isMatching = (item.price && item.refund && item.price === item.refund) ? "Yes" : "No"
          return statusValue === isMatching
        }
      );
    }

    if (date.length !== 0) {
      if (date[0] !== "" && date[1] !== "") {
        const start = new Date(date[0]);
        const end = new Date(date[1]);
        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);
        filteredData = filteredData?.filter((item) => {
          const check = new Date(item.submitDate);
          check.setHours(0, 0, 0, 0);
          return check >= start && check <= end;
        });
      }
    }
    setFilterData(filteredData);
  };

  const handleReset = () => {
    setSearch({
      title: "",
      supplier: "",
      status: "",
      date: [],
    });
  };
  return (
    <>
      <Form layout="vertical" form={form} name="searchForm" size="large">
        <Row>
          <Col align="right" offset={18} xs={6}>
            <Link to={"/invoice"} state={{ ...invoiceInfo }}>
              <Button
                type="primary"
              >
                {t("NEW_INVOICE")}
              </Button>
            </Link>
          </Col>
        </Row>
        <Row gutter={60}>
          <Col xs={12}>
            <Row gutter={16}>
              <Col xs={10}>
                <Form.Item name="title" label="Rechercher par nom">
                  <Input
                    prefix={<SearchOutlined size={15} />}
                    placeholder="Rechercher"
                    name="title"
                    // onChange={({ target }) =>
                    //   handleChange({ [target.name]: target.value })
                    // }
                    onChange={({ target }) =>
                      handleChange("title", target.value)
                    }
                  />
                </Form.Item>
              </Col>

              <Col xs={7}>
                <Form.Item name="supplier" label="Fournisseurs">
                  <Input
                    prefix={<SearchOutlined size={15} />}
                    placeholder="Rechercher"
                    name="supplier"
                    // onChange={({ target }) =>
                    //   handleChange({ [target.name]: target.value })
                    // }
                    onChange={({ target }) =>
                      handleChange("supplier", target.value)
                    }
                  />
                  {/* <Select>
                                            {[
                                                {
                                                    value: "Paid",
                                                    label: "Effectué",
                                                },
                                                {
                                                    value: "In Progress",
                                                    label: "En cours",
                                                },
                                            ].map((row) => (
                                                <Select.Option key={row.value} value={row.value}>
                                                    {row.label}
                                                </Select.Option>
                                            ))}
                                        </Select> */}
                </Form.Item>
              </Col>

              <Col xs={7}>
                <Form.Item name="status" label="Matching">
                  <Select
                    // onChange={(value) => handleChange({ status: value })}
                    onChange={(value) => handleChange("status", value)}
                  >
                    {[
                      {
                        value: true,
                        label: "Yes",
                        color: token.colorSuccess,
                      },
                      {
                        value: false,
                        label: "No",
                        color: token.colorText,
                      },
                    ].map((row) => (
                      <Select.Option key={row.value} value={row.value}>
                        <span
                          className="small-dot"
                          style={{ backgroundColor: row.color }}
                        />
                        {row.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <Row gutter={16}>
              <Col xs={16}>
                <Form.Item name={"date"} label={<>&nbsp;</>}>
                  <DatePicker.RangePicker
                    size="large"
                    name="date"
                    // onChange={(value) => handleChange({ date: value })}
                    onChange={(value) => handleChange("date", value)}
                    allowClear={() => handleChange("date", null)}
                  />
                </Form.Item>
              </Col>
              <Col xs={8}>
                <Form.Item label=" ">
                  <Button
                    htmlType="reset"
                    icon={<FilterOutlined />}
                    onClick={handleReset}
                  >
                    Réinitialiser
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Filters;
